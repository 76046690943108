<template>
  <div class="min-h-screen bg-gradient-to-br from-gray-50 to-gray-100 py-8">
    <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <!-- Welcome Header -->
      <div class="flex items-center justify-between mb-8">
        <h1 class="text-4xl font-extrabold text-gray-900">
          <span class="bg-clip-text text-transparent bg-gradient-to-r from-purple-600 to-indigo-600">
            Welcome, {{ displayUsername }}!
          </span>
        </h1>
      </div>
      
      <div class="grid grid-cols-1 lg:grid-cols-3 gap-8">
        <!-- Main Content Column -->
        <div class="lg:col-span-2 space-y-8">
          <!-- Featured Stories Carousel -->
  <div class="bg-white backdrop-blur-lg bg-opacity-90 rounded-2xl shadow-xl p-6 transition-all duration-300 hover:shadow-2xl">
    <h2 class="text-2xl font-bold text-gray-900 mb-6 flex items-center">
      <span class="inline-block w-2 h-8 bg-purple-600 rounded-lg mr-3"></span>
      Featured Stories
    </h2>
    <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-6">
      <div v-for="story in featuredStories" :key="story.id" 
        class="group relative bg-gray-50 rounded-xl overflow-hidden shadow-md hover:shadow-xl transition-all duration-300 transform hover:-translate-y-1">
        <router-link :to="{ name: 'StoryPage', params: { id: story.id.toString() } }">
          <div class="relative aspect-[2/3]">
            <img :src="story.coverImage" :alt="story.title" 
              class="w-full h-full object-cover transition-transform duration-300 group-hover:scale-105">
            <div class="absolute inset-0 bg-gradient-to-t from-black/60 to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-300"></div>
          </div>
          <div class="p-4">
            <h3 class="font-semibold text-lg mb-2 line-clamp-1 group-hover:text-purple-600 transition-colors duration-300">
              {{ story.title }}
            </h3>
            <p class="text-sm text-gray-600">
              <router-link :to="{ name: 'VisitedUserProfile', params: { username: story.authorUsername } }"
                class="hover:text-purple-600 transition-colors duration-300">
                by {{ story.authorUsername }}
              </router-link>
            </p>
          </div>
        </router-link>
      </div>
    </div>
  </div>
          
          <!-- Current Challenge -->
          <div class="relative overflow-hidden rounded-2xl shadow-xl transition-all duration-300 hover:shadow-2xl">
            <div class="absolute inset-0 bg-gradient-to-br from-purple-600 via-indigo-600 to-blue-700"></div>
            <div class="relative p-6">
              <h2 @click="toggleChallenge" 
                class="text-2xl font-bold mb-4 text-white cursor-pointer flex items-center justify-between">
                <span>Current Writing Challenge</span>
                <svg :class="{'rotate-180': challengeExpanded}" 
                  class="ml-2 h-5 w-5 transform transition-transform duration-300" 
                  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                  <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd" />
                </svg>
              </h2>
              <div v-if="currentChallenge && challengeExpanded" 
                class="bg-white/10 backdrop-blur-md p-6 rounded-xl mt-4">
                <h3 class="font-bold text-xl text-white">{{ currentChallenge.title }}</h3>
                <p class="mt-2 text-white/90">{{ currentChallenge.description }}</p>
                <p class="mt-2 font-semibold text-white/90">Deadline: {{ currentChallenge.deadline }}</p>
                <div class="mt-6 flex space-x-4">
                  <router-link :to="{ name: 'Challenges' }" 
                    class="inline-flex items-center px-6 py-2 rounded-full bg-white text-purple-600 font-semibold hover:bg-gray-50 transition-colors duration-300">
                    Write
                  </router-link>
                  <router-link :to="{ name: 'Challenges' }" 
                    class="inline-flex items-center px-6 py-2 rounded-full bg-purple-700 text-white font-semibold hover:bg-purple-800 transition-colors duration-300">
                    Vote
                  </router-link>
                </div>
              </div>
              <p v-else-if="!currentChallenge" class="text-white/90">
                No active challenges at the moment. Check back soon!
              </p>
            </div>
          </div>
          
          <!-- Personalized Recommendations -->
          <div class="bg-white backdrop-blur-lg bg-opacity-90 rounded-2xl shadow-xl p-6 transition-all duration-300 hover:shadow-2xl">
            <h2 class="text-2xl font-bold text-gray-900 mb-6 flex items-center">
              <span class="inline-block w-2 h-8 bg-purple-600 rounded-lg mr-3"></span>
              Recommended for You
            </h2>
            <div class="grid grid-cols-1 sm:grid-cols-2 gap-6">
              <div v-for="book in recommendations" :key="book.id" 
                class="group bg-gray-50 rounded-xl p-4 flex items-center space-x-4 transition-all duration-300 hover:shadow-lg">
                <router-link :to="{ name: 'StoryPage', params: { id: book.id.toString() } }" 
                  class="flex-shrink-0">
                  <img :src="book.coverImage" :alt="book.title" 
                    class="w-20 h-28 object-cover rounded-lg shadow-md group-hover:shadow-lg transition-all duration-300">
                </router-link>
                <div class="flex-1">
                  <h3 class="font-semibold group-hover:text-purple-600 transition-colors duration-300">
                    {{ book.title }}
                  </h3>
                  <p class="text-sm text-gray-600">
                    <router-link :to="{ name: 'VisitedUserProfile', params: { username: book.authorUsername } }"
                      class="hover:text-purple-600 transition-colors duration-300">
                      by {{ book.authorUsername }}
                    </router-link>
                  </p>
                  <button 
                    @click="addToBookshelf(book.id)" 
                    :disabled="bookshelfStatus[book.id] === 'added'"
                    class="mt-3 px-4 py-2 rounded-full text-sm font-medium transition-all duration-300"
                    :class="{
                      'bg-purple-600 text-white hover:bg-purple-700': !bookshelfStatus[book.id],
                      'bg-green-600 text-white': bookshelfStatus[book.id] === 'added',
                      'bg-red-600 text-white': bookshelfStatus[book.id] === 'error'
                    }"
                  >
                    {{ bookshelfStatus[book.id] === 'added' ? 'Added to Bookshelf' : 
                       bookshelfStatus[book.id] === 'error' ? 'Error' : 'Add to Bookshelf' }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        
        <!-- Sidebar Column -->
        <div class="space-y-8">
          <!-- Reading Progress -->
  <div class="bg-white backdrop-blur-lg bg-opacity-90 rounded-2xl shadow-xl p-6 transition-all duration-300 hover:shadow-2xl">
    <h2 class="text-xl font-bold text-gray-900 mb-6 flex items-center">
      <span class="inline-block w-2 h-6 bg-purple-600 rounded-lg mr-3"></span>
      Continue Reading
    </h2>
    <div v-if="currentlyReading.length > 0" 
      class="space-y-6 max-h-96 overflow-y-auto pr-2 scrollbar-thin scrollbar-thumb-purple-600 scrollbar-track-gray-100">
      <div v-for="book in currentlyReading" :key="book.id" 
        class="group bg-gray-50 p-4 rounded-xl flex items-start transition-all duration-300 hover:shadow-lg relative">
        <!-- Remove Button -->
        <button 
          @click.prevent="removeFromReading(book.id)"
          class="absolute top-2 right-2 p-1 text-gray-400 hover:text-red-500 transition-colors duration-300"
          title="Remove from reading list">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
            <path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd" />
          </svg>
        </button>
        
        <router-link :to="{ name: 'StoryPage', params: { id: book.id.toString() } }" 
          class="flex-shrink-0 mr-4">
          <img :src="book.coverImage" :alt="book.title" 
            class="w-24 h-36 object-cover rounded-lg shadow-md group-hover:shadow-lg transition-all duration-300">
        </router-link>
        <div class="flex-grow">
          <h3 class="font-semibold group-hover:text-purple-600 transition-colors duration-300">
            {{ book.title }}
          </h3>
          <p class="text-sm text-gray-600">
            <router-link :to="{ name: 'VisitedUserProfile', params: { username: book.authorUsername } }"
              class="hover:text-purple-600 transition-colors duration-300">
              by {{ book.authorUsername }}
            </router-link>
          </p>
        </div>
      </div>
    </div>
    <p v-else class="text-gray-600 text-center py-4">
      You're not currently reading any books. Why not start one?
    </p>
  </div>
          
          <!-- User Groups -->
          <div class="bg-white backdrop-blur-lg bg-opacity-90 rounded-2xl shadow-xl p-6 transition-all duration-300 hover:shadow-2xl">
            <h2 class="text-xl font-bold text-gray-900 mb-6 flex items-center">
              <span class="inline-block w-2 h-6 bg-purple-600 rounded-lg mr-3"></span>
              Your Groups
            </h2>
            <div class="space-y-4">
              <div v-for="group in userGroups" :key="group.id" 
                class="group bg-gray-50 p-4 rounded-xl transition-all duration-300 hover:shadow-lg">
                <h3 class="font-semibold group-hover:text-purple-600 transition-colors duration-300">
                  {{ group.name }}
                </h3>
                <p class="text-sm text-gray-600 mt-2">{{ group.description }}</p>
                <router-link 
                  :to="{ name: 'GroupDetails', params: { id: group.id.toString() } }" 
                  class="mt-3 inline-flex items-center px-4 py-2 rounded-full bg-purple-600 text-white text-sm font-medium hover:bg-purple-700 transition-colors duration-300"
                >
                  View Group
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, computed } from 'vue'
import { useStore } from 'vuex'
import { supabase } from '@/supabase'

export default {
  name: 'PersonalizedDashboard',
  setup() {
    const store = useStore()
    const user = computed(() => store.getters['auth/currentUser'])
    const isLoggedIn = computed(() => !!user.value && Object.keys(user.value).length > 0)
    const displayUsername = ref('Guest')
    const featuredStories = ref([])
    const currentChallenge = ref(null)
    const userGroups = ref([])
    const currentlyReading = ref([])
    const recommendations = ref([])
    const challengeExpanded = ref(true)

    onMounted(async () => {
      await Promise.all([
        fetchFeaturedStories(),
        fetchCurrentChallenge(),
        fetchUserGroups(),
        fetchCurrentlyReading(),
        fetchRecommendations(),
        fetchBookshelfStatus()
      ])
    })

    onMounted(() => {
      console.log('Current user:', user.value)
      console.log('Is logged in:', isLoggedIn.value)
      fetchCurrentUsername()
    })

    const fetchCurrentUsername = async () => {
      if (user.value && user.value.id) {
        try {
          const { data, error } = await supabase
            .from('users')
            .select('username')
            .eq('id', user.value.id)
            .single()

          if (error) throw error

          if (data && data.username) {
            displayUsername.value = data.username
          } else {
            console.warn('Username not found in database, falling back to auth data')
            displayUsername.value = user.value.user_metadata?.username || user.value.email?.split('@')[0] || 'Guest'
          }
        } catch (error) {
          console.error('Error fetching current username:', error)
          displayUsername.value = user.value.user_metadata?.username || user.value.email?.split('@')[0] || 'Guest'
        }
      }
    }

    async function fetchFeaturedStories() {
  const { data, error } = await supabase
    .from('featured_stories')
    .select(`
      id,
      story_id,
      stories:story_id (
        id,
        title,
        cover_image,
        users:author_id (username)
      )
    `)
    .order('featured_order', { ascending: true })
    .limit(4)

  if (error) {
    console.error('Error fetching featured stories:', error)
    return
  }

  featuredStories.value = data.map(item => ({
    id: item.stories.id,
    title: item.stories.title,
    authorUsername: item.stories.users.username,
    coverImage: item.stories.cover_image
  }))
}
    async function fetchCurrentChallenge() {
  const { data, error } = await supabase
    .from('challenges')
    .select('*')
    .eq('status', 'active')
    .order('created_at', { ascending: false })
    .limit(1)
    .single()

  if (error) {
    console.error('Error fetching current challenge:', error)
    return
  }

  if (data) {
    currentChallenge.value = {
      title: data.title,
      description: data.description,
      deadline: new Date(data.end_date).toLocaleDateString()
    }
  }
}
    async function fetchUserGroups() {
  const { data, error } = await supabase
    .from('group_members')
    .select(`
      groups (
        id,
        name,
        description
      )
    `)
    .eq('user_id', user.value.id)
    .limit(3)

  if (error) {
    console.error('Error fetching user groups:', error)
    return
  }

  userGroups.value = data.map(item => ({
    id: item.groups.id,
    name: item.groups.name,
    description: item.groups.description
  }))
}
    async function fetchCurrentlyReading() {
  const { data, error } = await supabase
    .from('user_reading_progress')
    .select(`
      id,
      progress,
      last_read_chapter,
      stories:story_id (
        id,
        title,
        cover_image,
        users:author_id (username),
        chapters (count)
      )
    `)
    .eq('user_id', user.value.id)
    .order('last_read_at', { ascending: false })
    .limit(5)

  if (error) {
    console.error('Error fetching currently reading:', error)
    return
  }

  currentlyReading.value = data.map(item => ({
    id: item.stories.id,
    title: item.stories.title,
    authorUsername: item.stories.users.username,
    coverImage: item.stories.cover_image,
    progress: Math.round((item.last_read_chapter / item.stories.chapters.count) * 100),
    chaptersRead: item.last_read_chapter,
    totalChapters: item.stories.chapters.count
  }))
}
    async function fetchRecommendations() {
  // This is a simplified recommendation system
  // In a real-world scenario, you'd use more sophisticated algorithms
  const { data, error } = await supabase
    .from('stories')
    .select(`
      id,
      title,
      cover_image,
      users:author_id (username)
    `)
    .order('reads', { ascending: false })
    .limit(4)

  if (error) {
    console.error('Error fetching recommendations:', error)
    return
  }

  recommendations.value = data.map(item => ({
    id: item.id,
    title: item.title,
    authorUsername: item.users.username,
    coverImage: item.cover_image
  }))
}

  function toggleChallenge() {
      challengeExpanded.value = !challengeExpanded.value
    }

  const bookshelfStatus = ref({});

const fetchBookshelfStatus = async () => {
  const { data, error } = await supabase
    .from('bookshelf')
    .select('story_id, status')
    .eq('user_id', user.value.id);

  if (error) {
    console.error('Error fetching bookshelf status:', error);
  } else {
    data.forEach(item => {
      bookshelfStatus.value[item.story_id] = 'added';
    });
  }
};

const removeFromReading = async (storyId) => {
      try {
        const { error } = await supabase
          .from('user_reading_progress')
          .delete()
          .eq('user_id', user.value.id)
          .eq('story_id', storyId)

        if (error) throw error

        // Update the local state by removing the story
        currentlyReading.value = currentlyReading.value.filter(book => book.id !== storyId)
      } catch (error) {
        console.error('Error removing story from reading list:', error)
      }
    }

const addToBookshelf = async (storyId, status = 'Want to Read') => {
  const { error } = await supabase
    .from('bookshelf')
    .upsert({
      user_id: user.value.id,
      story_id: storyId,
      status: status
    }, {
      onConflict: 'user_id,story_id',
      returning: 'minimal'
    });

  if (error) {
    console.error('Error adding to bookshelf:', error);
    bookshelfStatus.value[storyId] = 'error';
  } else {
    console.log('Successfully added/updated bookshelf entry');
    bookshelfStatus.value[storyId] = 'added';
    // Reset status after 3 seconds
    setTimeout(() => {
      bookshelfStatus.value[storyId] = null;
    }, 3000);
  }
};

    return {
      user,
      isLoggedIn,
      displayUsername,
      featuredStories,
      currentChallenge,
      userGroups,
      currentlyReading,
      recommendations,
      challengeExpanded,
      toggleChallenge,
      addToBookshelf,
      bookshelfStatus,
      removeFromReading
    }
  }
}
</script>