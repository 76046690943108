<template>
  <div class="min-h-screen bg-gradient-to-b from-gray-50 to-gray-100 text-gray-900">
    <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
      <!-- Hero Section with adjusted padding -->
      <div class="relative rounded-2xl bg-white shadow-lg mb-12 py-10 px-8 overflow-hidden">
        <div class="absolute inset-0 bg-gradient-to-r from-blue-500/10 to-purple-500/10"></div>
        <div class="relative">
          <div class="flex justify-between items-center">
            <h1 class="text-4xl font-bold bg-gradient-to-r from-blue-600 to-purple-600 bg-clip-text text-transparent leading-normal">
              My Works
            </h1>
            <button 
              @click="createNewStory" 
              class="bg-gradient-to-r from-blue-600 to-purple-600 text-white px-6 py-3 rounded-xl hover:from-blue-700 hover:to-purple-700 transition-all duration-300 shadow-md hover:shadow-lg transform hover:-translate-y-0.5"
            >
              Create New Story
            </button>
          </div>
        </div>
      </div>

      <!-- Regular Stories -->
      <div class="mb-12">
        <h2 class="text-2xl font-bold mb-6 pl-1">My Stories</h2>
        <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          <div 
            v-for="story in stories" 
            :key="story.id" 
            class="group bg-white rounded-2xl shadow-md hover:shadow-xl transition-all duration-300 overflow-hidden transform hover:-translate-y-1"
          >
            <a :href="`/story/${story.id}/details`" class="block relative">
              <div class="relative aspect-[2/3] w-full">
                <img 
                  :src="story.cover_image" 
                  :alt="story.title" 
                  class="absolute inset-0 w-full h-full object-cover transition-transform duration-300 group-hover:scale-105"
                  @error="handleImageError"
                />
                <div class="absolute inset-0 bg-gradient-to-t from-black/80 via-black/20 to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                  <div class="absolute bottom-0 left-0 right-0 p-6">
                    <h2 class="text-xl font-semibold mb-3 text-white line-clamp-2">{{ story.title }}</h2>
                    <div class="flex justify-between items-center mb-3">
                      <span 
                        :class="[
                          'px-3 py-1 rounded-full text-sm font-medium', 
                          story.status === 'published' 
                            ? 'bg-green-100 text-green-700 border border-green-200' 
                            : 'bg-yellow-100 text-yellow-700 border border-yellow-200'
                        ]"
                      >
                        {{ story.status }}
                      </span>
                      <div class="flex space-x-2">
                        <button 
                          @click.prevent="goToWritingInterface(story.id)" 
                          class="text-white hover:text-blue-200 transition-colors duration-200 p-2 rounded-lg hover:bg-blue-500/20"
                        >
                          Write
                        </button>
                        <button 
                          @click.prevent="editStory(story.id)" 
                          class="text-white hover:text-blue-200 transition-colors duration-200 p-2 rounded-lg hover:bg-blue-500/20"
                        >
                          Edit
                        </button>
                        <button 
                          @click.prevent="viewStory(story.id)" 
                          class="text-white hover:text-green-200 transition-colors duration-200 p-2 rounded-lg hover:bg-green-500/20"
                        >
                          View
                        </button>
                        <button 
                          @click.prevent="deleteStory(story.id)" 
                          class="text-white hover:text-red-200 transition-colors duration-200 p-2 rounded-lg hover:bg-red-500/20"
                        >
                          Delete
                        </button>
                      </div>
                    </div>
                    <div class="flex justify-start items-center space-x-6 text-white">
                      <div class="flex items-center space-x-1">
                        <EyeIcon class="w-5 h-5" />
                        <span class="text-sm">{{ story.reads || 0 }}</span>
                      </div>
                      <div class="flex items-center space-x-1">
                        <HeartIcon class="w-5 h-5" />
                        <span class="text-sm">{{ story.votes || 0 }}</span>
                      </div>
                      <div class="flex items-center space-x-1">
                        <MessageSquareIcon class="w-5 h-5" />
                        <span class="text-sm">{{ story.comments || 0 }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>

      <!-- Challenge Entries -->
      <div>
        <h2 class="text-2xl font-bold mb-6 pl-1">Challenge Entries</h2>
        <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          <div 
            v-for="entry in challengeEntries" 
            :key="entry.id" 
            class="group bg-white rounded-2xl shadow-md hover:shadow-xl transition-all duration-300 overflow-hidden transform hover:-translate-y-1"
          >
            <a :href="`/challenge-entry/${entry.id}/details`" class="block relative">
              <div class="relative aspect-[2/3] w-full">
                <img 
                  :src="entry.cover_image" 
                  :alt="entry.title" 
                  class="absolute inset-0 w-full h-full object-cover transition-transform duration-300 group-hover:scale-105"
                  @error="handleImageError"
                />
                <div class="absolute inset-0 bg-gradient-to-t from-black/80 via-black/20 to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                  <div class="absolute bottom-0 left-0 right-0 p-6">
                    <h2 class="text-xl font-semibold mb-3 text-white line-clamp-2">{{ entry.title }}</h2>
                    <div class="flex justify-between items-center mb-3">
                      <span 
                        :class="[
                          'px-3 py-1 rounded-full text-sm font-medium', 
                          entry.status === 'published' 
                            ? 'bg-green-100 text-green-700 border border-green-200' 
                            : 'bg-yellow-100 text-yellow-700 border border-yellow-200'
                        ]"
                      >
                        {{ entry.status }}
                      </span>
                      <div class="flex space-x-2">
                        <button 
                          @click.prevent="goToWritingInterface(entry.id)" 
                          class="text-white hover:text-blue-200 transition-colors duration-200 p-2 rounded-lg hover:bg-blue-500/20"
                        >
                          Write
                        </button>
                        <button 
                          @click.prevent="editChallengeEntry(entry.id)" 
                          class="text-white hover:text-blue-200 transition-colors duration-200 p-2 rounded-lg hover:bg-blue-500/20"
                        >
                          Edit
                        </button>
                        <button 
                          @click.prevent="viewChallengeEntry(entry.id)" 
                          class="text-white hover:text-green-200 transition-colors duration-200 p-2 rounded-lg hover:bg-green-500/20"
                        >
                          View
                        </button>
                        <button 
                          @click.prevent="deleteChallengeEntry(entry.id)" 
                          class="text-white hover:text-red-200 transition-colors duration-200 p-2 rounded-lg hover:bg-red-500/20"
                        >
                          Delete
                        </button>
                      </div>
                    </div>
                    <div class="flex justify-start items-center space-x-6 text-white">
                      <div class="flex items-center space-x-1">
                        <EyeIcon class="w-5 h-5" />
                        <span class="text-sm">{{ entry.reads || 0 }}</span>
                      </div>
                      <div class="flex items-center space-x-1">
                        <HeartIcon class="w-5 h-5" />
                        <span class="text-sm">{{ entry.votes || 0 }}</span>
                      </div>
                      <div class="flex items-center space-x-1">
                        <MessageSquareIcon class="w-5 h-5" />
                        <span class="text-sm">{{ entry.comments || 0 }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
</div>
</template>

<script>
import { ref, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { supabase } from '@/supabase';
import { Eye as EyeIcon, Heart as HeartIcon, MessageSquare as MessageSquareIcon } from 'lucide-vue-next';

import { useStore } from 'vuex';

export default {
  name: 'MyWorksPage',
  components: {
    EyeIcon,
    HeartIcon,
    MessageSquareIcon,
  },
  setup() {
    const router = useRouter();
    const store = useStore();
    const stories = ref([]);
    const challengeEntries = ref([]);

    const handleImageError = (e) => {
      e.target.src = '/fallback-image.jpg';
    };

    const fetchUserContent = async () => {
      const user = store.getters['auth/currentUser'];
      if (!user) return;

      const { data: storiesData, error: storiesError } = await supabase
        .from('stories')
        .select(`
          *,
          story_reads(count),
          story_votes(count),
          story_comments(count)
        `)
        .eq('author_id', user.id)
        .order('created_at', { ascending: false });

      if (storiesError) {
        console.error('Error fetching stories:', storiesError);
      } else {
        stories.value = storiesData.map(story => ({
          ...story,
          reads: story.story_reads?.[0]?.count || 0,
          votes: story.story_votes?.[0]?.count || 0,
          comments: story.story_comments?.[0]?.count || 0,
        }));
      }

      const { data: entriesData, error: entriesError } = await supabase
        .from('challenge_entries')
        .select('*')
        .eq('user_id', user.id)
        .order('submitted_at', { ascending: false });

      if (entriesError) {
        console.error('Error fetching challenge entries:', entriesError);
      } else {
        challengeEntries.value = entriesData || [];
      }
    };

    onMounted(fetchUserContent);

    const createNewStory = () => {
      router.push('/create-story');
    };

    const goToWritingInterface = (id) => {
      router.push(`/write/${id}/new-chapter`);
    };

    const editStory = (id) => {
      router.push(`/story/${id}/details`);
    };

    const viewStory = (id) => {
      router.push(`/story/${id}`);
    };

    const deleteStory = async (id) => {
  if (confirm('Are you sure you want to delete this story? This action cannot be undone.')) {
    const { error } = await supabase
      .from('stories')
      .delete()
      .eq('id', id);

    if (error) {
      console.error('Error deleting story:', error);
      store.dispatch('notifications/add', {
        type: 'error',
        message: 'Failed to delete story. Please try again.'
      });
    } else {
      stories.value = stories.value.filter(story => story.id !== id);
      store.dispatch('notifications/add', {
        type: 'success',
        message: 'Story deleted successfully'
      });
    }
  }
};

    const editChallengeEntry = (id) => {
      router.push(`/challenge-entry/${id}/edit`);
    };

    const viewChallengeEntry = (id) => {
      router.push(`/challenge-entry/${id}`);
    };

    const deleteChallengeEntry = async (id) => {
      if (confirm('Are you sure you want to delete this challenge entry?')) {
        const { error } = await supabase
          .from('challenge_entries')
          .delete()
          .eq('id', id);

        if (error) {
          console.error('Error deleting challenge entry:', error);
        } else {
          await fetchUserContent();
        }
      }
    };

    return {
      stories,
      challengeEntries,
      createNewStory,
      goToWritingInterface,
      editStory,
      viewStory,
      deleteStory,
      editChallengeEntry,
      viewChallengeEntry,
      deleteChallengeEntry,
      handleImageError,
    };
  }
};
</script>