import { createRouter, createWebHistory } from 'vue-router';
import store from '../store';
import { supabase } from '@/supabase';

import UnloggedHomepage from '@/components/UnloggedHomepage.vue';
import PersonalizedDashboard from '@/components/PersonalizedDashboard.vue';
import UserAuth from '@/components/UserAuth.vue';
import ExplorePage from '@/components/ExplorePage.vue';
import MyWorksPage from '@/components/MyWorksPage.vue';
import GroupsPage from '@/components/GroupsPage.vue';
import GroupDetailsPage from '@/components/GroupDetailsPage.vue';
import GroupPostPage from '@/components/GroupPostPage.vue';
import CreatePostPage from '@/components/CreatePostPage.vue';
import ChallengesPage from '@/components/ChallengesPage.vue';
import Bookshelf from '@/components/Bookshelf.vue';
import ProfileWrapper from '@/components/ProfileWrapper.vue';
import WritingInterface from '@/components/WritingInterface.vue';
import PreviewStoryPage from '@/components/PreviewStoryPage.vue';
import PreviewChapterPage from '@/components/PreviewChapterPage.vue';
import CreateStory from '@/components/CreateStory.vue';
import ChapterPage from '@/components/ChapterPage.vue';
import StoryPage from '@/components/StoryPage.vue';
import StoryDetails from '@/components/StoryDetails.vue';
import EditStoryDetails from '@/components/EditStoryDetails.vue';
import MessagesPage from '@/components/MessagesPage.vue';
import UserSettings from '@/components/UserSettings.vue';
import AuthCallback from '../components/AuthCallback.vue';
import RecommendationListView from '@/components/RecommendationListView.vue';
import AdminDashboard from '@/views/AdminDashboard.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: UnloggedHomepage,
    meta: { requiresAuth: false }
  },
  {
    path: '/story/:id',
    name: 'StoryPage',
    component: StoryPage,
    meta: { requiresAuth: false }
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: PersonalizedDashboard,
    meta: { requiresAuth: true }
  },
  {
    path: '/auth',
    name: 'Auth',
    component: UserAuth,
    meta: { requiresAuth: false }
  },
  {
    path: '/explore',
    name: 'ExplorePage',
    component: ExplorePage,
    meta: { requiresAuth: true }
  },
  {
    path: '/my-works',
    name: 'MyWorks',
    component: MyWorksPage,
    meta: { requiresAuth: true }
  },
  {
    path: '/groups',
    name: 'Groups',
    component: GroupsPage,
    meta: { requiresAuth: true }
  },
  {
    path: '/group/:id',
    name: 'GroupDetails',
    component: GroupDetailsPage,
    meta: { requiresAuth: true }
  },
  {
    path: '/group/:groupId/post/:postId',
    name: 'GroupPost',
    component: GroupPostPage,
    meta: { requiresAuth: true }
  },
  {
    path: '/group/:groupId/create-post',
    name: 'CreatePost',
    component: CreatePostPage,
    meta: { requiresAuth: true }
  },
  {
    path: '/challenges',
    name: 'Challenges',
    component: ChallengesPage,
    meta: { requiresAuth: true }
  },
  {
    path: '/recommendation-list/:id',
    name: 'RecommendationList',
    component: RecommendationListView,       
    props: true
  },
  {
    path: '/bookshelf',
    name: 'Bookshelf',
    component: Bookshelf,
    meta: { requiresAuth: true }
  },
  {
  path: '/preview/:id',
  name: 'PreviewStory',
  component: PreviewStoryPage,
  meta: { requiresAuth: true }
},
{
  path: '/preview/:storyId/chapter/:chapterId',
  name: 'PreviewChapter',
  component: PreviewChapterPage,
  meta: { requiresAuth: true }
},
 {
  path: '/profile',
  name: 'CurrentUserProfile',
  component: ProfileWrapper,
  meta: { requiresAuth: true }
},
{
  path: '/profile/:username?',
  name: 'VisitedUserProfile',
  component: ProfileWrapper,
  meta: { requiresAuth: true }
},
  {
    path: '/create-story',
    name: 'CreateStory',
    component: CreateStory,
    meta: { requiresAuth: true }
  },
  {
    path: '/story/:id/edit',
    name: 'EditStoryDetails',
    component: EditStoryDetails,
    meta: { requiresAuth: true }
  },
  {
    path: '/story/:id/details',
    name: 'StoryDetails',
    component: StoryDetails,
    meta: { requiresAuth: true }
  },
  {
    path: '/write/:storyId/chapter/:chapterId',
    name: 'WriteChapter',
    component: WritingInterface,
    meta: { requiresAuth: true }
  },
  {
    path: '/write/:storyId/new-chapter',
    name: 'NewChapter',
    component: WritingInterface,
    meta: { requiresAuth: true }
  },
  {
    path: '/story/:storyId/chapter/:chapterId',
    name: 'ChapterPage',
    component: ChapterPage,
    meta: { requiresAuth: true }
  },
  {
    path: '/messages',
    name: 'Messages',
    component: MessagesPage,
    meta: { requiresAuth: true }
  },
  {
    path: '/settings',
    name: 'Settings',
    component: UserSettings,
    meta: { requiresAuth: true }
  },
  {
    path: '/auth/callback',
    name: 'AuthCallback',
    component: AuthCallback
  },
  {
  path: '/admin',
  name: 'AdminDashboard',
  component: AdminDashboard,
  meta: { requiresAdmin: true },
  beforeEnter: (to, from, next) => {
    if (store.getters['auth/isAdmin']) {
      next();
    } else {
      next('/'); // Redirect to home or show an error
    }
  }
}
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

supabase.auth.onAuthStateChange((event, session) => {
  if (event === 'SIGNED_IN') {
    store.dispatch('auth/setUser', session.user);
  } else if (event === 'SIGNED_OUT') {
    store.commit('auth/clearUser');
  }
});

router.beforeEach(async (to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  const requiresAdmin = to.matched.some(record => record.meta.requiresAdmin);
  
  // Ensure auth state is current
  await store.dispatch('auth/checkAuth');
  
  const isLoggedIn = store.getters['auth/isLoggedIn'];
  const isAdmin = store.getters['auth/isAdmin'];
  console.log('Route:', to.path);
  console.log('Requires Auth:', requiresAuth);
  console.log('Requires Admin:', requiresAdmin);
  console.log('Is Logged In:', isLoggedIn);
  console.log('Is Admin:', isAdmin);

  if (requiresAuth && !isLoggedIn) {
    console.log('Redirecting to auth page');
    next('/auth');
  } else if (requiresAdmin && !isAdmin) {
    console.log('Redirecting to home page (not admin)');
    next('/');
  } else if (isLoggedIn && to.path === '/') {
    console.log('Redirecting to dashboard');
    next('/dashboard');
  } else if (to.path === '/auth' && isLoggedIn) {
    console.log('Already logged in, redirecting to dashboard');
    next('/dashboard');
  } else {
    console.log('Proceeding to requested route');
    next();
  }
});

export default router;