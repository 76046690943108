<template>
  <div v-if="error" class="fixed top-4 right-4 bg-red-100 border-l-4 border-red-600 text-red-700 px-6 py-4 rounded-lg shadow-lg z-50" role="alert">
    <strong class="font-bold">Error!</strong>
    <span class="block sm:inline ml-2">{{ error }}</span>
  </div>

  <div v-if="story" class="min-h-screen bg-gradient-to-br from-purple-50 to-fuchsia-50 py-12">
    <div class="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
      <div class="bg-white rounded-2xl shadow-xl overflow-hidden">
        <div class="md:flex gap-8 p-8">
          <div class="md:w-1/3 flex-shrink-0">
            <img :src="story.cover_image" :alt="story.title" class="w-full aspect-[2/3] object-cover rounded-xl shadow-lg" />
            <div class="mt-6 flex flex-wrap gap-2">
              <span v-for="tag in story.tags" :key="tag" class="inline-block bg-purple-100 text-purple-800 rounded-full px-4 py-1 text-sm font-medium">#{{ tag }}</span>
            </div>
          </div>

          <div class="md:w-2/3 mt-6 md:mt-0">
            <div class="flex items-center gap-3 text-sm font-medium">
              <span class="px-3 py-1 bg-purple-100 text-purple-800 rounded-full">{{ story.genre }}</span>
              <span class="px-3 py-1 bg-gray-100 text-gray-800 rounded-full">{{ story.target_audience }}</span>
              <span v-if="story.is_adult" class="px-3 py-1 bg-red-100 text-red-800 rounded-full">18+</span>
            </div>

            <h1 class="mt-4 text-4xl font-bold text-gray-900">{{ story.title }}</h1>

            <div class="mt-6">
              <p :class="{'line-clamp-3': !showFullBlurb}" class="text-gray-600 text-lg leading-relaxed">{{ story.blurb }}</p>
              <button @click="toggleBlurb" class="mt-2 text-purple-600 hover:text-purple-800 font-medium">
                {{ showFullBlurb ? 'Show Less' : 'Show More' }}
              </button>
            </div>

            <div class="mt-8 flex flex-wrap gap-3">
              <button 
  @click="confirmPublish" 
  v-if="!story.published" 
  class="bg-green-500 text-white px-6 py-2.5 rounded-lg hover:bg-green-600 transition duration-300 shadow-sm" 
  :disabled="!hasChapters"
  :title="!hasChapters ? 'Add at least one chapter before publishing' : ''"
>
  Publish Story
</button>
              <button @click="confirmUnpublish" v-else class="bg-yellow-500 text-white px-6 py-2.5 rounded-lg hover:bg-yellow-600 transition duration-300 shadow-sm">
  Unpublish Story
</button>
              <button @click="editStory" class="bg-purple-600 text-white px-6 py-2.5 rounded-lg hover:bg-purple-700 transition duration-300 shadow-sm">
                Edit Story
              </button>
              <button @click="previewStory" class="bg-gray-800 text-white px-6 py-2.5 rounded-lg hover:bg-gray-900 transition duration-300 shadow-sm">
                Preview Story
              </button>
            </div>
          </div>
        </div>

        <div class="px-8 py-6 bg-gray-50 grid grid-cols-1 md:grid-cols-3 gap-4">
          <div class="bg-white p-6 rounded-xl shadow-sm">
            <div class="text-center">
              <p class="text-3xl font-bold text-purple-600 mb-1">{{ story.reads || 0 }}</p>
              <p class="text-gray-600">Total Reads</p>
            </div>
          </div>
          <div class="bg-white p-6 rounded-xl shadow-sm">
            <div class="text-center">
              <p class="text-3xl font-bold text-purple-600 mb-1">{{ story.applause || 0 }}</p>
              <p class="text-gray-600">Applause</p>
            </div>
          </div>
          <div class="bg-white p-6 rounded-xl shadow-sm">
            <div class="text-center">
              <p class="text-3xl font-bold text-purple-600 mb-1">{{ story.comments || 0 }}</p>
              <p class="text-gray-600">Comments</p>
            </div>
          </div>
        </div>

        <div class="p-8">
          <div class="flex items-center justify-between mb-6">
            <h2 class="text-2xl font-bold text-gray-900">Table of Contents</h2>
            <button @click="addNewChapter" class="bg-purple-600 text-white px-6 py-2.5 rounded-lg hover:bg-purple-700 transition duration-300 shadow-sm flex items-center gap-2">
              <svg class="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 4v16m8-8H4" />
              </svg>
              Add Chapter
            </button>
          </div>

          <div class="bg-gray-50 rounded-xl p-6">
            <ul v-if="story.chapters && story.chapters.length > 0" class="divide-y divide-gray-200">
              <li v-for="chapter in story.chapters" :key="chapter.id" class="py-4 flex justify-between items-center">
                <a :href="`/story/${story.id}/chapter/${chapter.id}`" class="text-lg text-gray-900 hover:text-purple-600 font-medium">
                  {{ chapter.title }}
                </a>
                <div class="flex items-center gap-2">
                  <button @click="editChapter(chapter.id)" class="text-gray-600 hover:text-purple-600 p-2 rounded-lg hover:bg-purple-50 transition-colors">
                    <svg class="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
                    </svg>
                  </button>
                  <button @click="unpublishChapter(chapter.id)" class="text-gray-600 hover:text-yellow-600 p-2 rounded-lg hover:bg-yellow-50 transition-colors">
                    <svg class="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 7H5a2 2 0 00-2 2v9a2 2 0 002 2h14a2 2 0 002-2V9a2 2 0 00-2-2h-3m-1 4l-3 3m0 0l-3-3m3 3V4" />
                    </svg>
                  </button>
                  <button @click="deleteChapter(chapter.id)" class="text-gray-600 hover:text-red-600 p-2 rounded-lg hover:bg-red-50 transition-colors">
                    <svg class="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                    </svg>
                  </button>
                </div>
              </li>
            </ul>
            <p v-else class="text-gray-600 text-center py-8">No chapters available yet.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
<!-- Publish Confirmation Modal -->
<div v-if="showPublishModal" class="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
  <div class="bg-white rounded-lg p-6 max-w-md mx-4">
    <h2 class="text-2xl font-bold mb-4">Publish Story?</h2>
    <p class="text-gray-600 mb-4">
      Publishing will make your story visible to all readers. Make sure:
      <ul class="list-disc ml-5 mt-2 space-y-1">
        <li>Your chapters are ready for readers</li>
        <li>Your story details are complete</li>
        <li>Your cover image is set</li>
      </ul>
    </p>
    <div class="flex justify-end space-x-3">
      <button @click="showPublishModal = false" class="px-4 py-2 bg-gray-200 text-gray-800 rounded-lg hover:bg-gray-300">Cancel</button>
      <button @click="publishStory" class="px-4 py-2 bg-green-500 text-white rounded-lg hover:bg-green-600">Publish</button>
    </div>
  </div>
</div>

<!-- Unpublish Confirmation Modal -->
<div v-if="showUnpublishModal" class="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
  <div class="bg-white rounded-lg p-6 max-w-md mx-4">
    <h2 class="text-2xl font-bold mb-4">Unpublish Story?</h2>
    <p class="text-gray-600 mb-4">
      Unpublishing will hide your story from readers. Existing readers will not be able to access your story until you publish it again.
    </p>
    <div class="flex justify-end space-x-3">
      <button @click="showUnpublishModal = false" class="px-4 py-2 bg-gray-200 text-gray-800 rounded-lg hover:bg-gray-300">Cancel</button>
      <button @click="unpublishStory" class="px-4 py-2 bg-yellow-500 text-white rounded-lg hover:bg-yellow-600">Unpublish</button>
    </div>
  </div>
</div>
</template>

<script>
import { ref, onMounted, computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { supabase } from '@/supabase';

export default {
  name: 'StoryDetails',
  setup() {
    const router = useRouter();
    const route = useRoute();
    const showFullBlurb = ref(false);
    const story = ref(null);
    const chapters = ref([]);
    const error = ref(null);
    const showPublishModal = ref(false);
    const showUnpublishModal = ref(false);
    const hasChapters = computed(() => story.value?.chapters?.length > 0);

    const confirmPublish = () => {
  if (!hasChapters.value) {
    error.value = 'Add at least one chapter before publishing';
    return;
  }
  showPublishModal.value = true;
};

    const confirmUnpublish = () => {
  showUnpublishModal.value = true;
};

    const fetchStory = async () => {
  const { data, error } = await supabase
    .from('stories')
    .select(`
      *,
      author:users(name, avatar, bio)
    `)
    .eq('id', route.params.id)
    .single();

  if (error) {
    console.error('Error fetching story:', error);
    error.value = 'Failed to load story. Please try again.';
    return null;
  }

  // Fetch chapters separately
  const { data: chaptersData, error: chaptersError } = await supabase
    .from('chapters')
    .select('id, title, created_at, chapter_number')
    .eq('story_id', route.params.id)
    .order('chapter_number', { ascending: true });

  if (chaptersError) {
    console.error('Error fetching chapters:', chaptersError);
  }

  // Fetch reads, votes, and comments counts separately
  const [readsData, votesData, commentsData] = await Promise.all([
    supabase.from('story_reads').select('count').eq('story_id', route.params.id),
    supabase.from('story_votes').select('count').eq('story_id', route.params.id),
    supabase.from('story_comments').select('count').eq('story_id', route.params.id)
  ]);

  return {
    ...data,
    chapters: chaptersData || [],
    reads: readsData.data?.[0]?.count || 0,
    votes: votesData.data?.[0]?.count || 0,
    comments: commentsData.data?.[0]?.count || 0
  };
};

    const fetchChapters = async () => {
      try {
        const { data, error: chaptersError } = await supabase
          .from('chapters')
          .select('*')
          .eq('story_id', route.params.id)
          .order('chapter_number', { ascending: true });

        if (chaptersError) throw chaptersError;
        chapters.value = data;
      } catch (fetchError) {
        console.error('Error fetching chapters:', fetchError);
        error.value = 'Failed to load chapters. Please try again.';
      }
    };

    const publishStory = async () => {
  try {
    const { error: publishError } = await supabase
      .from('stories')
      .update({ 
        published: true,
        published_at: new Date().toISOString()
      })
      .eq('id', story.value.id);

    if (publishError) throw publishError;
    
    story.value.published = true;
    showPublishModal.value = false;
  } catch (err) {
    console.error('Error publishing story:', err);
    error.value = 'Failed to publish story';
  }
};

const unpublishStory = async () => {
  try {
    const { error: unpublishError } = await supabase
      .from('stories')
      .update({ published: false })
      .eq('id', story.value.id);

    if (unpublishError) throw unpublishError;
    
    story.value.published = false;
    showUnpublishModal.value = false;
  } catch (err) {
    console.error('Error unpublishing story:', err);
    error.value = 'Failed to unpublish story';
  }
};

const editStory = () => {
  router.push(`/story/${story.value.id}/edit`);
};

    onMounted(async () => {
      const storyData = await fetchStory();
      if (storyData) {
        story.value = storyData;
        await fetchChapters();
      }
    });

    const toggleBlurb = () => {
      showFullBlurb.value = !showFullBlurb.value;
    };

    const editStoryDetails = () => {
      router.push(`/story/${story.value.id}/edit`);
    };

    const previewStory = () => {
      router.push(`/preview/${story.value.id}`);
    };

    const editChapter = (chapterId) => {
      router.push(`/write/${story.value.id}/chapter/${chapterId}`);
    };

    const unpublishChapter = async (chapterId) => {
      try {
        const { error: unpublishError } = await supabase
          .from('chapters')
          .update({ status: 'draft' })
          .eq('id', chapterId);

        if (unpublishError) throw unpublishError;
        await fetchChapters();
      } catch (unpublishError) {
        console.error('Error unpublishing chapter:', unpublishError);
        error.value = 'Failed to unpublish chapter. Please try again.';
      }
    };

    const deleteChapter = async (chapterId) => {
      if (confirm('Are you sure you want to delete this chapter?')) {
        try {
          const { error: deleteError } = await supabase
            .from('chapters')
            .delete()
            .eq('id', chapterId);

          if (deleteError) throw deleteError;
          await fetchChapters();
        } catch (deleteError) {
          console.error('Error deleting chapter:', deleteError);
          error.value = 'Failed to delete chapter. Please try again.';
        }
      }
    };

    const addNewChapter = () => {
      router.push(`/write/${story.value.id}/new-chapter`);
    };

    const formatDate = (dateString) => {
      return new Date(dateString).toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
      });
    };

    return {
      story,
      chapters,
      showFullBlurb,
      toggleBlurb,
      editStoryDetails,
      previewStory,
      editChapter,
      unpublishChapter,
      showPublishModal,
      showUnpublishModal,
      hasChapters,
      confirmPublish,
      confirmUnpublish,
      deleteChapter,
      addNewChapter,
      publishStory,
      unpublishStory,
      editStory,
      formatDate,
      error
    };
  }
};
</script>
<style scoped>
.story-cover {
  height: 400px;
  width: 100%;
  object-fit: cover;
}
</style>