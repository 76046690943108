<template>
  <div v-if="loading" class="text-center py-8">
    <p class="text-2xl text-gray-600">Loading preview...</p>
  </div>

  <div v-else-if="error" class="text-center py-8">
    <p class="text-2xl text-red-600">{{ error }}</p>
    <button 
      @click="$router.back()" 
      class="mt-4 px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600"
    >
      Go Back
    </button>
  </div>

  <div v-else-if="story" class="story-page bg-gray-100 min-h-screen p-8">
    <!-- Preview Banner -->
    <div class="max-w-4xl mx-auto mb-4 bg-yellow-100 border-l-4 border-yellow-500 p-4 rounded-r-lg">
      <div class="flex justify-between items-center">
        <p class="text-yellow-700">
          <span class="font-bold">Preview Mode</span> - This is how your story will appear to readers
        </p>
        <button 
          @click="$router.push(`/story/${story.id}/details`)" 
          class="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600"
        >
          Back to Details
        </button>
      </div>
    </div>

    <div class="max-w-4xl mx-auto bg-white rounded-lg shadow-md overflow-hidden">
      <div class="bg-gradient-to-r from-blue-500 to-purple-600 p-6 text-white">
        <h1 class="text-3xl font-bold">{{ story.title }}</h1>
        <p>by {{ story.author?.username }}</p>
      </div>

      <div class="p-6">
        <!-- Main content section -->
        <div class="flex mb-6">
          <!-- Left side with image -->
          <img :src="story.cover_image" :alt="story.title" class="w-1/3 h-auto rounded-lg shadow-md mr-6" />
          
          <!-- Right side with stats -->
          <div class="flex-1">
            <!-- Status Badge -->
            <div class="mb-4">
              <span class="px-3 py-1 rounded-full text-sm font-medium bg-yellow-100 text-yellow-800 border border-yellow-200">
                Unpublished
              </span>
            </div>

            <!-- Stats Grid -->
            <div class="grid grid-cols-2 gap-4 mb-4">
              <div class="text-center p-2 bg-gray-100 rounded">
                <p class="text-2xl font-bold text-blue-600">{{ story.chapters?.length || 0 }}</p>
                <p class="text-sm text-gray-600">Chapters</p>
              </div>
              <div class="text-center p-2 bg-gray-100 rounded">
                <p class="text-2xl font-bold text-blue-600">{{ story.word_count || 0 }}</p>
                <p class="text-sm text-gray-600">Words</p>
              </div>
            </div>

            <!-- Tags -->
            <div class="flex flex-wrap gap-2 mb-4">
              <span 
                v-for="tag in story.tags" 
                :key="tag" 
                class="bg-blue-100 text-blue-800 px-2 py-1 rounded-full text-sm"
              >
                {{ tag }}
              </span>
            </div>
          </div>
        </div>

        <!-- Blurb -->
        <div class="mb-6">
          <div class="flex items-center justify-between mb-2">
            <h2 class="text-2xl font-bold">Story Blurb</h2>
            <button 
              @click="isBlurbExpanded = !isBlurbExpanded" 
              class="text-blue-600 hover:text-blue-700"
            >
              {{ isBlurbExpanded ? 'Show Less' : 'Show More' }}
            </button>
          </div>
          <p :class="{'line-clamp-3': !isBlurbExpanded}" class="text-gray-700">
            {{ story.blurb }}
          </p>
        </div>

        <!-- Chapters List -->
        <div class="mb-8">
          <h2 class="text-2xl font-bold mb-2">Chapters</h2>
          <ul v-if="story.chapters && story.chapters.length" class="space-y-2 mb-6 bg-gray-50 rounded-lg divide-y divide-gray-200">
            <li 
              v-for="chapter in story.chapters" 
              :key="chapter.id" 
              class="flex justify-between items-center p-3 hover:bg-gray-100"
            >
              <div class="flex items-center">
                <span 
                  :class="[
                    'mr-3 px-2 py-0.5 rounded-full text-xs font-medium',
                    chapter.published 
                      ? 'bg-green-100 text-green-800' 
                      : 'bg-yellow-100 text-yellow-800'
                  ]"
                >
                  {{ chapter.published ? 'Published' : 'Draft' }}
                </span>
                <a 
                  @click="previewChapter(chapter.id)" 
                  class="text-blue-600 hover:underline cursor-pointer"
                >
                  Chapter {{ chapter.chapter_number }} - {{ chapter.title }}
                </a>
              </div>
              <span class="text-gray-500 text-sm">{{ formatDate(chapter.created_at) }}</span>
            </li>
          </ul>
          <p v-else class="text-gray-600">No chapters available yet.</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { supabase } from '@/supabase';

export default {
  name: 'PreviewStoryPage',
  
  setup() {
    const route = useRoute();
    const router = useRouter();
    const story = ref(null);
    const loading = ref(true);
    const error = ref(null);
    const isBlurbExpanded = ref(false);

    const fetchStory = async () => {
      try {
        const { data: { user } } = await supabase.auth.getUser();
        if (!user) {
          error.value = 'You must be logged in to preview stories';
          return null;
        }

        const { data, error: fetchError } = await supabase
          .from('stories')
          .select(`
            *,
            author:users!author_id(username, avatar_url),
            chapters(
              id, 
              title, 
              created_at, 
              chapter_number,
              published,
              updated_at
            )
          `)
          .eq('id', route.params.id)
          .eq('author_id', user.id) // Ensure user is author
          .single();

        if (fetchError) throw fetchError;
        if (!data) throw new Error('Story not found');

        return data;
      } catch (err) {
        console.error('Error in fetchStory:', err);
        error.value = err.message === 'Story not found' 
          ? 'Story not found or you don\'t have permission to view it'
          : 'Error loading story preview';
        return null;
      }
    };

    const previewChapter = (chapterId) => {
      router.push(`/preview/${story.value.id}/chapter/${chapterId}`);
    };

    const formatDate = (dateString) => {
      if (!dateString) return '';
      const options = { year: 'numeric', month: 'long', day: 'numeric' };
      return new Date(dateString).toLocaleDateString(undefined, options);
    };

    onMounted(async () => {
      const data = await fetchStory();
      if (data) {
        story.value = data;
      }
      loading.value = false;
    });

    return {
      story,
      loading,
      error,
      isBlurbExpanded,
      previewChapter,
      formatDate
    };
  }
};
</script>