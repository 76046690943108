<template>
  <div class="min-h-screen bg-gradient-to-br from-gray-50 to-gray-100 py-12">
    <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <!-- Search Bar -->
      <div class="mb-12 max-w-2xl mx-auto">
        <div class="relative group">
          <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            <svg class="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
              <path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd" />
            </svg>
          </div>
          <input
            v-model="searchQuery"
            type="text"
            placeholder="Search groups..."
            class="w-full pl-10 pr-4 py-3 rounded-xl border-2 border-gray-200 focus:border-blue-500 focus:ring-2 focus:ring-blue-200 transition-all duration-200 bg-white shadow-sm focus:shadow-md"
            @input="handleSearch"
          >
        </div>
      </div>

      <div v-if="loading" class="flex justify-center items-center py-12">
        <div class="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
      </div>
      
      <div v-else-if="error" class="bg-red-50 border-l-4 border-red-500 p-4 rounded-lg">
        <div class="flex">
          <div class="flex-shrink-0">
            <svg class="h-5 w-5 text-red-400" viewBox="0 0 20 20" fill="currentColor">
              <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clip-rule="evenodd" />
            </svg>
          </div>
          <div class="ml-3">
            <p class="text-red-700">{{ error }}</p>
          </div>
        </div>
      </div>
      
      <div v-else>
        <!-- Joined Groups Section -->
        <div class="mb-16">
          <h2 class="text-3xl font-bold mb-8 text-gray-800 flex items-center">
            <span class="mr-3">My Groups</span>
            <span class="text-sm font-normal px-3 py-1 bg-blue-100 text-blue-700 rounded-full">
              {{ joinedGroups.length }} joined
            </span>
          </h2>
          
          <div v-if="joinedGroups.length === 0" class="bg-white rounded-xl p-8 text-center shadow-sm">
            <svg class="w-16 h-16 mx-auto text-gray-400 mb-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z" />
            </svg>
            <p class="text-gray-600 text-lg">You haven't joined any groups yet.</p>
            <p class="text-gray-500 mt-2">Discover and join groups below to connect with others!</p>
          </div>
          
          <div v-else class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            <div v-for="group in joinedGroups" :key="group.id" 
                 class="bg-white rounded-xl shadow-sm hover:shadow-md transition-all duration-300 overflow-hidden group">
              <div class="p-6">
                <router-link 
                  :to="{ name: 'GroupDetails', params: { id: group.id } }"
                  class="text-xl font-semibold mb-3 block text-gray-800 hover:text-purple-600 transition-colors duration-200"
                >
                  {{ group.name }}
                </router-link>
                <p class="text-gray-600 mb-6 line-clamp-2">{{ group.description }}</p>
                <div class="flex justify-between items-center">
                  <span class="flex items-center text-sm text-gray-500">
                    <svg class="w-4 h-4 mr-1" viewBox="0 0 20 20" fill="currentColor">
                      <path d="M13 6a3 3 0 11-6 0 3 3 0 016 0zM18 8a2 2 0 11-4 0 2 2 0 014 0zM14 15a4 4 0 00-8 0v3h8v-3zM6 8a2 2 0 11-4 0 2 2 0 014 0zM16 18v-3a5.972 5.972 0 00-.75-2.906A3.005 3.005 0 0119 15v3h-3zM4.75 12.094A5.973 5.973 0 004 15v3H1v-3a3 3 0 013.75-2.906z" />
                    </svg>
                    {{ group.memberCount }} {{ group.memberCount === 1 ? 'member' : 'members' }}
                  </span>
                </div>
              </div>
              <div class="px-6 py-4 bg-gray-50 flex justify-end space-x-3">
                <button @click="leaveGroup(group.id)" 
                        class="px-4 py-2 text-red-600 hover:bg-red-50 rounded-lg transition-colors duration-200">
                  Leave
                </button>
                <router-link 
                  :to="{ name: 'GroupDetails', params: { id: group.id } }" 
                  class="px-4 py-2 bg-purple-600 text-white rounded-lg hover:bg-blue-700 transition-colors duration-200"
                >
                  View Group
                </router-link>
              </div>
            </div>
          </div>
        </div>

        <!-- Available Groups Section -->
        <div>
          <h2 class="text-3xl font-bold mb-8 text-gray-800">Available Groups</h2>
          
          <!-- Group Creation CTA -->
          <div class="mb-12 bg-gradient-to-r from-purple-600 to-blue-700 rounded-xl p-8 text-white relative overflow-hidden">
            <div class="relative z-10">
              <h3 class="text-2xl font-bold mb-2">Have an idea for a new group?</h3>
              <p class="text-blue-100 mb-6 max-w-2xl">Share your passion and connect with like-minded readers and writers!</p>
              <button @click="showGroupCreationModal = true" 
                      class="bg-white text-purple-600 px-6 py-3 rounded-lg font-semibold hover:bg-blue-50 transition-colors duration-200">
                Propose a New Group
              </button>
            </div>
            <div class="absolute right-0 top-0 h-full w-1/3 opacity-10">
              <svg viewBox="0 0 100 100" fill="currentColor">
                <path d="M95 50c0 24.85-20.15 45-45 45S5 74.85 5 50 25.15 5 50 5s45 20.15 45 45zm-84.5 0c0 21.815 17.685 39.5 39.5 39.5S89.5 71.815 89.5 50 71.815 10.5 50 10.5 10.5 28.185 10.5 50z"/>
              </svg>
            </div>
          </div>

          <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            <div v-for="group in availableGroups" :key="group.id" 
                 class="bg-white rounded-xl shadow-sm hover:shadow-md transition-all duration-300 overflow-hidden group">
              <div class="p-6">
                <router-link 
                  :to="{ name: 'GroupDetails', params: { id: group.id } }"
                  class="text-xl font-semibold mb-3 block text-gray-800 hover:text-purple-600 transition-colors duration-200"
                >
                  {{ group.name }}
                </router-link>
                <p class="text-gray-600 mb-6 line-clamp-2">{{ group.description }}</p>
                <div class="flex justify-between items-center">
                  <span class="flex items-center text-sm text-gray-500">
                    <svg class="w-4 h-4 mr-1" viewBox="0 0 20 20" fill="currentColor">
                      <path d="M13 6a3 3 0 11-6 0 3 3 0 016 0zM18 8a2 2 0 11-4 0 2 2 0 014 0zM14 15a4 4 0 00-8 0v3h8v-3zM6 8a2 2 0 11-4 0 2 2 0 014 0zM16 18v-3a5.972 5.972 0 00-.75-2.906A3.005 3.005 0 0119 15v3h-3zM4.75 12.094A5.973 5.973 0 004 15v3H1v-3a3 3 0 013.75-2.906z" />
                    </svg>
                    {{ group.memberCount }} {{ group.memberCount === 1 ? 'member' : 'members' }}
                  </span>
                </div>
              </div>
              <div class="px-6 py-4 bg-gray-50 flex justify-end space-x-3">
                <button @click="joinGroup(group.id)" 
                        class="px-4 py-2 bg-purple-600 text-white rounded-lg hover:bg-blue-700 transition-colors duration-200">
                  Join Group
                </button>
                <router-link 
                  :to="{ name: 'GroupDetails', params: { id: group.id } }" 
                  class="px-4 py-2 text-gray-700 hover:bg-gray-100 rounded-lg transition-colors duration-200"
                >
                  View
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Group Creation Modal -->
    <div v-if="showGroupCreationModal" 
         class="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-sm flex items-center justify-center p-4 z-50">
      <div class="bg-white rounded-xl shadow-xl w-full max-w-md transform transition-all duration-300 scale-100">
        <div class="p-6">
          <h2 class="text-2xl font-bold mb-6 text-gray-800">Propose a New Group</h2>
          <form @submit.prevent="submitGroupProposal">
            <div class="space-y-6">
              <div>
                <label class="block text-sm font-medium text-gray-700 mb-2" for="groupName">
                  Group Name
                </label>
                <input v-model="newGroup.name" 
                       class="w-full px-4 py-2 rounded-lg border border-gray-300 focus:ring-2 focus:ring-blue-500 focus:border-blue-500" 
                       id="groupName" 
                       type="text" 
                       placeholder="Enter group name">
              </div>
              
              <div>
                <label class="block text-sm font-medium text-gray-700 mb-2" for="groupDescription">
                  Description
                </label>
                <textarea v-model="newGroup.description" 
                          class="w-full px-4 py-2 rounded-lg border border-gray-300 focus:ring-2 focus:ring-blue-500 focus:border-blue-500" 
                          id="groupDescription" 
                          rows="3" 
                          placeholder="Describe your group"></textarea>
              </div>
              
              <div>
                <label class="block text-sm font-medium text-gray-700 mb-2" for="targetUsers">
                  Target Users
                </label>
                <input v-model="newGroup.targetUsers" 
                       class="w-full px-4 py-2 rounded-lg border border-gray-300 focus:ring-2 focus:ring-blue-500 focus:border-blue-500" 
                       id="targetUsers" 
                       type="text" 
                       placeholder="Who is this group for?">
              </div>
              
              <div>
                <label class="block text-sm font-medium text-gray-700 mb-4">
                  Are you willing to moderate this group?
                </label>
                <div class="flex space-x-6">
                  <label class="inline-flex items-center cursor-pointer group">
                    <input type="radio" 
                           class="form-radio h-5 w-5 text-purple-600 transition duration-150 ease-in-out" 
                           name="moderation" 
                           value="yes" 
                           v-model="newGroup.willModerate">
                    <span class="ml-2 text-gray-700 group-hover:text-gray-900">Yes</span>
                  </label>
                  <label class="inline-flex items-center cursor-pointer group">
                    <input type="radio" 
                           class="form-radio h-5 w-5 text-purple-600 transition duration-150 ease-in-out" 
                           name="moderation" 
                           value="no" 
                           v-model="newGroup.willModerate">
                    <span class="ml-2 text-gray-700 group-hover:text-gray-900">No</span>
                  </label>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div class="px-6 py-4 bg-gray-50 rounded-b-xl flex justify-end space-x-3">
          <button @click="showGroupCreationModal = false" 
                  class="px-4 py-2 text-gray-700 hover:bg-gray-100 rounded-lg transition-colors duration-200">
            Cancel
          </button>
          <button @click="submitGroupProposal" 
                  class="px-4 py-2 bg-purple-600 text-white rounded-lg hover:bg-blue-700 transition-colors duration-200">
            Submit Proposal
          </button>
        </div>
      </div>
    </div>

    <!-- Proposal Submitted Confirmation -->
    <div v-if="showProposalConfirmation" 
         class="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-sm flex items-center justify-center p-4 z-50">
      <div class="bg-white rounded-xl shadow-xl p-8 max-w-md w-full transform transition-all duration-300 scale-100">
        <div class="flex items-center justify-center w-12 h-12 rounded-full bg-green-100 text-green-600 mb-4 mx-auto">
          <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
          </svg>
        </div>
        <h2 class="text-2xl font-bold mb-4 text-gray-800 text-center">Proposal Submitted!</h2>
        <p class="text-gray-600 text-center mb-6">
          Thank you for your group proposal. Our moderation team will review it shortly and get back to you.
        </p>
        <div class="flex justify-center">
          <button @click="showProposalConfirmation = false" 
                  class="px-6 py-2 bg-green-600 text-white rounded-lg hover:bg-green-700 transition-colors duration-200">
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, onUnmounted } from 'vue';
import { useStore } from 'vuex';
import { groupService } from '@/services/groupService';

export default {
  name: 'GroupsPage',
  setup() {
    const store = useStore();
    const searchQuery = ref('');
    const showGroupCreationModal = ref(false);
    const showProposalConfirmation = ref(false);
    const newGroup = ref({ 
      name: '', 
      description: '', 
      targetUsers: '',
      willModerate: false
    });

    const joinedGroups = ref([]);
    const availableGroups = ref([]);
    const loading = ref(true);
    const error = ref(null);

    const fetchGroups = async () => {
  try {
    loading.value = true;
    error.value = null;
    console.log('Fetching groups...');
    const allGroups = await groupService.getGroups(searchQuery.value);
    console.log('All groups:', allGroups);

    const userId = store.getters['auth/getCurrentUserId'];
    console.log('Current user ID:', userId);

    if (!Array.isArray(allGroups)) {
      console.error('Fetched groups is not an array:', allGroups);
      error.value = 'Failed to load groups: Invalid data received';
      return;
    }

    joinedGroups.value = allGroups.filter(group => 
      group.members && group.members.some(member => member.user_id === userId)
    );
    console.log('Joined groups:', joinedGroups.value);

    availableGroups.value = allGroups.filter(group => 
      !group.members || !group.members.some(member => member.user_id === userId)
    );
    console.log('Available groups:', availableGroups.value);

    // Log the member counts for each group
    allGroups.forEach(group => {
      console.log(`Group ${group.id} - Member count: ${group.memberCount}`);
    });

  } catch (err) {
    console.error('Error in fetchGroups:', err);
    error.value = 'Failed to load groups: ' + (err.message || 'Unknown error');
  } finally {
    loading.value = false;
  }
};

    onMounted(() => {
      console.log('GroupsPage mounted, calling fetchGroups');
      fetchGroups();
    });

    const joinGroup = async (groupId) => {
  try {
    const userId = store.getters['auth/getCurrentUserId'];
    console.log(`Attempting to join group ${groupId} for user ${userId}`);
    await groupService.addGroupMember(groupId, userId);
    console.log(`Successfully joined group ${groupId}. Refetching groups...`);
    
    // Refetch groups to ensure local state matches backend
    await fetchGroups();
    console.log('Groups refetched after joining.');
  } catch (error) {
    console.error('Error joining group:', error);
    // Handle error (e.g., show error message to user)
  }
};

    const submitGroupProposal = async () => {
      try {
        const userId = store.getters['auth/getCurrentUserId'];
        await groupService.submitGroupProposal(
          newGroup.value.name, 
          newGroup.value.description, 
          userId, 
          newGroup.value.targetUsers, 
          newGroup.value.willModerate
        );
        
        // Reset form and show confirmation
        newGroup.value = { name: '', description: '', targetUsers: '', willModerate: false };
        showGroupCreationModal.value = false;
        showProposalConfirmation.value = true;
        
        // Simulate sending a notification to the admin
        store.dispatch('sendAdminNotification', {
          type: 'groupProposal',
          message: `New group proposal: ${newGroup.value.name}`,
          data: newGroup.value
        });
      } catch (error) {
        console.error('Error submitting group proposal:', error);
        // Handle error (e.g., show error message to user)
      }
    };

let debounceTimer = null;

const handleSearch = () => {
      clearTimeout(debounceTimer);
      debounceTimer = setTimeout(() => {
        fetchGroups();
      }, 300); // Wait for 300ms after the last keystroke before fetching
    };

    onUnmounted(() => {
      clearTimeout(debounceTimer);
    });

    const leaveGroup = async (groupId) => {
  try {
    const userId = store.getters['auth/getCurrentUserId'];
    console.log(`Attempting to leave group ${groupId} for user ${userId}`);
    await groupService.removeGroupMember(groupId, userId);
    console.log(`Successfully left group ${groupId}. Updating local state...`);
    
    // Update local state immediately
    joinedGroups.value = joinedGroups.value.filter(group => group.id !== groupId);
    const leavedGroup = availableGroups.value.find(group => group.id === groupId);
    if (leavedGroup) {
      leavedGroup.memberCount -= 1;
      availableGroups.value.push(leavedGroup);
    }
    
    // Refetch groups to ensure local state matches backend
    await fetchGroups();
    console.log('Groups refetched after leaving.');
  } catch (error) {
    console.error('Error leaving group:', error);
    // Handle error (e.g., show error message to user)
  }
};

    return {
      searchQuery,
      showGroupCreationModal,
      showProposalConfirmation,
      newGroup,
      joinedGroups,
      availableGroups,
      joinGroup,
      submitGroupProposal,
      loading,
      leaveGroup,
      handleSearch,
      error
    };
  }
};
</script>