<template>
  <div class="group-post-page min-h-screen py-12 bg-gradient-to-br from-indigo-50 via-purple-50 to-pink-50">
    <div class="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8 space-y-8">
      <div v-if="post" class="bg-white/80 backdrop-blur-xl rounded-2xl overflow-hidden mb-8 shadow-xl ring-1 ring-black/5 transition-all duration-300 hover:shadow-2xl hover:scale-[1.01]">
        <!-- Post Header -->
        <div class="bg-gradient-to-br from-indigo-600 via-purple-600 to-fuchsia-600 p-8">
          <h1 class="text-3xl md:text-4xl font-bold mb-6 leading-tight text-white tracking-tight">{{ post.title }}</h1>
          <div class="flex flex-col sm:flex-row sm:items-center sm:justify-between gap-4">
            <div class="flex items-center">
              <img :src="post.author.avatar_url || '/default-avatar.png'" :alt="post.author.username" 
                class="w-12 h-12 rounded-full ring-4 ring-white/20 shadow-lg">
              <div class="ml-4">
                <p class="text-white/90 font-medium">
                  Posted by 
                  <router-link :to="{ name: 'VisitedUserProfile', params: { username: post.author.username } }" 
                    class="font-bold hover:underline decoration-2 underline-offset-2">
                    {{ post.author.username }}
                  </router-link> 
                  in 
                  <router-link :to="{ name: 'GroupDetails', params: { id: post.group_id } }" 
                    class="font-bold hover:underline decoration-2 underline-offset-2">
                    {{ groupName }}
                  </router-link>
                </p>
                <p class="text-sm text-white/70">{{ formatDate(post.created_at) }}</p>
              </div>
            </div>
            <div class="flex items-center gap-4">
              <span class="flex items-center bg-white/10 backdrop-blur px-4 py-2 rounded-full text-sm text-white/90">
                <EyeIcon class="w-5 h-5 mr-2" />
                {{ post.views || 0 }}
              </span>
              <span class="flex items-center bg-white/10 backdrop-blur px-4 py-2 rounded-full text-sm text-white/90">
                <MessageCircleIcon class="w-5 h-5 mr-2" />
                {{ commentsCount }}
              </span>
            </div>
          </div>
        </div>

        <!-- Post Content -->
        <div class="p-8 bg-white">
          <div class="prose prose-lg prose-indigo max-w-none" v-html="post.content"></div>
          <div class="mt-8 pt-6 border-t border-gray-100">
            <div class="flex flex-wrap items-center justify-between gap-4">
              <div class="flex flex-wrap items-center gap-6">
                <button @click="likePost" 
                  class="flex items-center gap-2 px-4 py-2 rounded-full transition-all duration-300"
                  :class="isLiked ? 'bg-indigo-50 text-indigo-600' : 'hover:bg-gray-50'">
                  <ThumbsUpIcon :class="{ 'text-indigo-600': isLiked }" class="w-5 h-5" />
                  <span class="font-medium">{{ likesCount }} Likes</span>
                </button>
                <button @click="bookmarkPost" 
                  class="flex items-center gap-2 px-4 py-2 rounded-full transition-all duration-300"
                  :class="isBookmarked ? 'bg-indigo-50 text-indigo-600' : 'hover:bg-gray-50'">
                  <BookmarkIcon :class="{ 'text-indigo-600': isBookmarked }" class="w-5 h-5" />
                  <span class="font-medium">{{ isBookmarked ? 'Bookmarked' : 'Bookmark' }}</span>
                </button>
                <button @click="followPost" 
                  class="flex items-center gap-2 px-4 py-2 rounded-full transition-all duration-300"
                  :class="isFollowing ? 'bg-indigo-50 text-indigo-600' : 'hover:bg-gray-50'">
                  <BellIcon :class="{ 'text-indigo-600': isFollowing }" class="w-5 h-5" />
                  <span class="font-medium">{{ isFollowing ? 'Following' : 'Follow' }}</span>
                </button>
              </div>
              <button @click="sharePost" 
                class="p-2 rounded-full hover:bg-gray-50 transition-all duration-300">
                <ShareIcon class="w-6 h-6 text-gray-600" />
              </button>
            </div>
          </div>
        </div>
      </div>

      <!-- Quick Reply Form -->
      <div class="bg-white/80 backdrop-blur-xl rounded-2xl shadow-xl ring-1 ring-black/5 p-8 transition-all duration-300 hover:shadow-2xl">
        <h2 class="text-2xl font-bold mb-6 text-gray-900">Quick Reply</h2>
        <form @submit.prevent="addComment">
          <div id="editor" class="mb-6 comment-editor rounded-xl overflow-hidden ring-1 ring-gray-200"></div>
          <div class="flex justify-end">
            <button type="submit"
              class="px-8 py-3 bg-gradient-to-r from-indigo-600 to-purple-600 text-white rounded-full font-medium shadow-lg shadow-indigo-500/25 hover:shadow-xl hover:shadow-indigo-500/35 hover:-translate-y-0.5 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 transition-all duration-300">
              Post Reply
            </button>
          </div>
        </form>
      </div>

      <!-- Comments Section -->
      <div class="bg-white/80 backdrop-blur-xl rounded-2xl shadow-xl ring-1 ring-black/5 overflow-hidden">
        <div class="p-8 border-b border-gray-100 bg-gradient-to-br from-indigo-50/50 to-purple-50/50 flex flex-col sm:flex-row justify-between gap-4">
          <h2 class="text-2xl font-bold text-gray-900">Replies</h2>
          <select v-model="commentSortOrder" 
            class="px-4 py-2 bg-white rounded-full text-gray-700 border border-gray-200 shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500">
            <option value="chronological">Chronological</option>
            <option value="newest">Newest First</option>
            <option value="popular">Most Popular</option>
          </select>
        </div>

        <div class="divide-y divide-gray-100">
          <template v-for="comment in sortedComments" :key="comment.id">
            <div v-if="comment" class="p-8 hover:bg-gray-50/50 transition-all duration-300">
              <div class="flex gap-6">
                <img :src="comment.author?.avatar_url" :alt="comment.author?.username" 
                  class="w-12 h-12 rounded-full ring-2 ring-gray-100">
                <div class="flex-grow">
                  <div class="flex items-center justify-between mb-2">
                    <h3 class="font-semibold text-gray-900">
                      <span @click="navigateToUserProfile(comment.author?.username)" 
                        class="cursor-pointer hover:text-indigo-600 transition-colors duration-300">
                        {{ comment.author?.username }}
                      </span>
                    </h3>
                    <span class="text-sm text-gray-500">{{ formatDate(comment.created_at) }}</span>
                  </div>
                  <div class="prose prose-sm prose-indigo max-w-none mb-4" v-html="comment.content"></div>
                  <div class="flex flex-wrap items-center gap-4 text-sm">
                    <button @click="likeComment(comment)" 
                      class="flex items-center gap-2 px-3 py-1.5 rounded-full transition-all duration-300"
                      :class="comment.isLiked ? 'bg-indigo-50 text-indigo-600' : 'hover:bg-gray-50'">
                      <ThumbsUpIcon :class="{ 'text-indigo-600': comment.isLiked }" class="w-4 h-4" />
                      <span>Like ({{ comment.likes || 0 }})</span>
                    </button>
                    <button @click="replyToComment(comment)" 
                      class="flex items-center gap-2 px-3 py-1.5 rounded-full hover:bg-gray-50 transition-all duration-300">
                      <ReplyIcon class="w-4 h-4" />
                      <span>Reply</span>
                    </button>
                    <button @click="quoteComment(comment)" 
                      class="flex items-center gap-2 px-3 py-1.5 rounded-full hover:bg-gray-50 transition-all duration-300">
                      <QuoteIcon class="w-4 h-4" />
                      <span>Quote</span>
                    </button>
                  </div>
                </div>
              </div>

              <!-- Reply Editor -->
              <div v-if="replyingTo && replyingTo.id === comment.id" class="mt-6 ml-16">
                <div :id="`reply-editor-${comment.id}`" class="mb-4 comment-editor rounded-xl overflow-hidden ring-1 ring-gray-200"></div>
                <button @click="submitReply(comment)" 
                  class="px-6 py-2 bg-gradient-to-r from-indigo-600 to-purple-600 text-white rounded-full font-medium shadow-lg shadow-indigo-500/25 hover:shadow-xl hover:-translate-y-0.5 transition-all duration-300">
                  Submit Reply
                </button>
              </div>

              <!-- Nested Replies -->
              <template v-if="comment.replies && comment.replies.length > 0">
                <div v-for="reply in comment.replies" :key="reply.id" 
                  class="mt-6 ml-16 p-6 bg-gray-50/80 rounded-xl">
                  <div class="flex items-center justify-between mb-3">
                    <h4 class="font-semibold text-gray-900">
                      <router-link :to="{ name: 'VisitedUserProfile', params: { username: reply.author?.username } }" 
                        class="hover:text-indigo-600 transition-colors duration-300">
                        {{ reply.author?.username }}
                      </router-link>
                    </h4>
                    <span class="text-sm text-gray-500">{{ formatDate(reply.created_at) }}</span>
                  </div>
                  <div class="prose prose-sm prose-indigo max-w-none" v-html="reply.content"></div>
                </div>
              </template>
            </div>
          </template>
        </div>

        <div v-if="hasMoreComments" class="p-8 bg-gradient-to-br from-indigo-50/50 to-purple-50/50">
          <button @click="loadMoreComments"
            class="w-full py-3 bg-white text-indigo-600 rounded-full font-medium shadow-md hover:shadow-lg hover:-translate-y-0.5 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 transition-all duration-300">
            Load More Replies
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, onMounted, nextTick } from 'vue'
import { useRouter, useRoute } from 'vue-router';
import { ThumbsUpIcon, MessageCircleIcon, ShareIcon, BookmarkIcon, EyeIcon, ReplyIcon, BellIcon, QuoteIcon } from 'lucide-vue-next'
import Quill from 'quill'
import 'quill/dist/quill.snow.css'
import { groupService } from '@/services/groupService'
import { useStore } from 'vuex'
import { supabase } from '../supabase';

export default {
  name: 'GroupPostPage',
  components: {
    ThumbsUpIcon,
    MessageCircleIcon,
    ShareIcon,
    BookmarkIcon,
    EyeIcon,
    ReplyIcon,
    BellIcon,
    QuoteIcon
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const store = useStore();
    const postId = route.params.postId;

    const post = ref(null);
    const groupName = ref('');
    const comments = ref([]);
    const newComment = ref('');
    const isLiked = ref(false);
    const isBookmarked = ref(false);
    const isFollowing = ref(false);
    const commentSortOrder = ref('chronological');
    const replyingTo = ref(null);
    const mainQuill = ref(null);
    const likesCount = ref(0);
    const commentsCount = ref(0);
    const currentPage = ref(1);
    const hasMoreComments = ref(true);

    const fetchPost = async () => {
  try {
    const userId = store.getters['auth/getCurrentUserId'];
    const postData = await groupService.getPostDetails(postId);
    post.value = postData;
    
    const groupData = await groupService.getGroupDetails(postData.group_id);
    groupName.value = groupData.name;
    
    isLiked.value = await groupService.isPostLiked(postId, userId);
    isBookmarked.value = await groupService.isPostBookmarked(postId, userId);
    isFollowing.value = await groupService.isPostFollowed(postId, userId);

    likesCount.value = postData.likes?.count || 0;
    commentsCount.value = postData.comments?.count || 0;
    
    // Reset comments and currentPage before fetching new comments
    comments.value = [];
    currentPage.value = 1;
    await fetchComments();
    
    console.log('Fetched post:', postData);
    console.log('Is liked:', isLiked.value);
    console.log('Is bookmarked:', isBookmarked.value);
    console.log('Is following:', isFollowing.value);
  } catch (error) {
    console.error('Error fetching post details:', error);
  }
};

    const fetchComments = async () => {
  try {
    const userId = store.getters['auth/getCurrentUserId'];
    const fetchedComments = await groupService.getComments(postId, currentPage.value);
    if (fetchedComments.length < 20) {
      hasMoreComments.value = false;
    }
    
    // Fetch avatar URLs and like status for all comments and replies
    const processComments = async (commentsArray) => {
      for (let comment of commentsArray) {
        if (comment.author && !comment.author.avatar_url) {
          const { data: userData } = await supabase
            .from('users')
            .select('avatar_url')
            .eq('id', comment.author.id)
            .single();
          comment.author.avatar_url = userData?.avatar_url || '/default-avatar.png';
        }
        
        comment.isLiked = await groupService.isCommentLiked(comment.id, userId);
        
        if (comment.replies && comment.replies.length > 0) {
          await processComments(comment.replies);
        }
      }
      return commentsArray;
    };
    
    const processedComments = await processComments(fetchedComments);
    
    // Append new comments to existing ones
    comments.value = [...comments.value, ...processedComments];
    
    // Force a re-render of the comments
    comments.value = [...comments.value];
  } catch (error) {
    console.error('Error fetching comments:', error);
  }
};

    const initQuill = (elementId) => {
      const element = document.querySelector(elementId);
      if (element) {
        return new Quill(element, {
          theme: 'snow',
          modules: {
            toolbar: [
              ['bold', 'italic', 'underline'],
              ['link', 'blockquote'],
              [{ list: 'ordered' }, { list: 'bullet' }],
              ['image']
            ]
          },
          placeholder: 'Write your comment here...',
        });
      }
      return null;
    };

    onMounted(async () => {
      await fetchPost();
      nextTick(() => {
        mainQuill.value = initQuill('#editor');
        if (mainQuill.value) {
          mainQuill.value.on('text-change', () => {
            newComment.value = mainQuill.value.root.innerHTML;
          });
        }
      });
    });

    const formatDate = (date) => {
      return new Date(date).toLocaleString('en-US', { 
        year: 'numeric', 
        month: 'long', 
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit'
      });
};

    const sortedComments = computed(() => {
      let sortedComments = comments.value.filter(comment => comment != null);
      switch (commentSortOrder.value) {
        case 'newest':
          return sortedComments.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
        case 'popular':
          return sortedComments.sort((a, b) => (b.likes || 0) - (a.likes || 0));
        default: // chronological
          return sortedComments.sort((a, b) => new Date(a.created_at) - new Date(b.created_at));
      }
    });

    const likePost = async () => {
  try {
    const userId = store.getters['auth/getCurrentUserId'];
    if (isLiked.value) {
      await groupService.unlikePost(postId, userId);
      likesCount.value = Math.max(0, likesCount.value - 1);
    } else {
      await groupService.likePost(postId, userId);
      likesCount.value++;
    }
    isLiked.value = !isLiked.value;
    
    // Update the post object to trigger a re-render
    post.value = { 
      ...post.value, 
      likes: { count: likesCount.value },
      isLiked: isLiked.value 
    };
    
    console.log('Like status updated:', isLiked.value, 'Count:', likesCount.value);
  } catch (error) {
    console.error('Error liking/unliking post:', error);
  }
};

    const bookmarkPost = async () => {
      try {
        const userId = store.getters['auth/getCurrentUserId'];
        if (isBookmarked.value) {
          await groupService.unbookmarkPost(postId, userId);
        } else {
          await groupService.bookmarkPost(postId, userId);
        }
        isBookmarked.value = !isBookmarked.value;
        console.log('Bookmark status updated:', isBookmarked.value);
      } catch (error) {
        console.error('Error bookmarking/unbookmarking post:', error);
      }
    };

    const followPost = async () => {
  try {
    const userId = store.getters['auth/getCurrentUserId'];
    if (isFollowing.value) {
      await groupService.unfollowPost(postId, userId);
    } else {
      await groupService.followPost(postId, userId);
    }
    isFollowing.value = !isFollowing.value;
    // Update the post object to trigger a re-render
    post.value = { ...post.value, isFollowing: isFollowing.value };
    console.log('Follow status updated:', isFollowing.value);
  } catch (error) {
    console.error('Error following/unfollowing post:', error);
  }
};

    const sharePost = () => {
      const postUrl = `${window.location.origin}/group-post/${postId}`;
      if (navigator.share) {
        navigator.share({
          title: post.value.title,
          text: 'Check out this post!',
          url: postUrl,
        }).catch((error) => console.error('Error sharing:', error));
      } else {
        navigator.clipboard.writeText(postUrl).then(() => {
          alert('Post link copied to clipboard!');
        }, (err) => {
          console.error('Could not copy text: ', err);
        });
      }
    };

    const addComment = async () => {
  if (newComment.value.trim()) {
    try {
      const userId = store.getters['auth/getCurrentUserId'];
      const currentUser = store.getters['auth/getCurrentUser'];
      const newCommentObj = await groupService.createComment(postId, userId, newComment.value);
      
      newCommentObj.author = {
        id: userId,
        username: currentUser.username,
        avatar_url: currentUser.avatar_url || '/default-avatar.png'
      };
      
      comments.value = [newCommentObj, ...comments.value];
      if (mainQuill.value) {
        mainQuill.value.setContents([{ insert: '\n' }]);
      }
      newComment.value = '';
      commentsCount.value++;
      // Update the post object to trigger a re-render
      post.value = { ...post.value, comments: { count: commentsCount.value } };
      console.log('Comment added, new count:', commentsCount.value);
    } catch (error) {
      console.error('Error adding comment:', error);
    }
  }
};

    const likeComment = async (commentToLike) => {
  if (!commentToLike) return;
  try {
    const userId = store.getters['auth/getCurrentUserId'];
    await groupService.likeComment(commentToLike.id, userId);
    
    const updateCommentLikes = (commentsArray) => {
      return commentsArray.map(c => {
        if (c && c.id === commentToLike.id) {
          return { ...c, likes: (c.likes || 0) + 1, isLiked: true };
        }
        if (c && c.replies) {
          return { ...c, replies: updateCommentLikes(c.replies) };
        }
        return c;
      });
    };
    
    comments.value = updateCommentLikes(comments.value);
    
    // Force a re-render of the comments
    comments.value = [...comments.value];
  } catch (error) {
    console.error('Error liking comment:', error);
  }
};

    const replyToComment = (comment) => {
      if (!comment) return;
      if (replyingTo.value === comment) {
        replyingTo.value = null;
      } else {
        replyingTo.value = comment;
        nextTick(() => {
          const replyQuill = initQuill(`#reply-editor-${comment.id}`);
          if (replyQuill) replyQuill.focus();
        });
      }
    };

    const quoteComment = (comment) => {
      if (!comment) return;
      if (replyingTo.value === comment) {
        replyingTo.value = null;
      } else {
        replyingTo.value = comment;
        nextTick(() => {
          const replyQuill = initQuill(`#reply-editor-${comment.id}`);
          if (replyQuill) {
            const quoteText = `<blockquote><p>${comment.author?.username || 'Unknown'} said:</p>${comment.content}</blockquote><p></p>`;
            replyQuill.clipboard.dangerouslyPasteHTML(0, quoteText);
            replyQuill.focus();
          }
        });
      }
    };

    const submitReply = async (comment) => {
  const replyQuill = Quill.find(`#reply-editor-${comment.id}`);
  if (replyQuill) {
    const replyContent = replyQuill.root.innerHTML;
    if (replyContent.trim()) {
      try {
        const userId = store.getters['auth/getCurrentUserId'];
        const currentUser = store.getters['auth/getCurrentUser'];
        const newReply = await groupService.createComment(postId, userId, replyContent, comment.id);
        
        newReply.author = {
          id: userId,
          username: currentUser.username,
          avatar_url: currentUser.avatar_url || '/default-avatar.png'
        };
        
        if (!comment.replies) {
          comment.replies = [];
        }
        comment.replies.push(newReply);
        // Force a re-render of the comments
        comments.value = [...comments.value];
        replyingTo.value = null;
        replyQuill.setContents([{ insert: '\n' }]);
        commentsCount.value++;
        // Update the post object to trigger a re-render
        post.value = { ...post.value, comments: { count: commentsCount.value } };
      } catch (error) {
        console.error('Error submitting reply:', error);
      }
    }
  }
};

    const loadMoreComments = async () => {
      if (hasMoreComments.value) {
        currentPage.value++;
        await fetchComments();
      }
    };

    const navigateToUserProfile = (username) => {
  router.push({ name: 'VisitedUserProfile', params: { username } });
};

    return {
      post,
      groupName,
      comments,
      newComment,
      isLiked,
      isBookmarked,
      isFollowing,
      commentSortOrder,
      replyingTo,
      sortedComments,
      formatDate,
      likePost,
      bookmarkPost,
      followPost,
      sharePost,
      addComment,
      likeComment,
      replyToComment,
      quoteComment,
      submitReply,
      loadMoreComments,
      likesCount,
      commentsCount,
      navigateToUserProfile,
      hasMoreComments,
    };
  }
};
</script>

<style scoped>
.prose {
  max-width: none;
}

.prose p {
  margin-bottom: 1em;
}

.prose ul {
  list-style-type: disc;
  padding-left: 1.5em;
  margin-bottom: 1em;
}

.prose li {
  margin-bottom: 0.5em;
}

.comment-editor {
  height: 150px;
  background: white;
}

:deep(.ql-container) {
  font-size: 15px;
  font-family: 'Inter', sans-serif;
}

:deep(.ql-editor) {
  min-height: 100px;
  max-height: 150px;
  overflow-y: auto;
}

:deep(.ql-toolbar) {
  border-bottom: 1px solid #e5e7eb;
  padding: 8px;
  background: #f9fafb;
}

:deep(.ql-toolbar .ql-picker-label) {
  padding: 2px 4px;
}

:deep(.ql-toolbar button) {
  height: 24px;
  width: 24px;
  padding: 2px;
}

:deep(.ql-toolbar button svg) {
  height: 18px;
  width: 18px;
}

/* Modern scrollbar */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 3px;
}

::-webkit-scrollbar-thumb {
  background: #c7c7c7;
  border-radius: 3px;
}

::-webkit-scrollbar-thumb:hover {
  background: #a0a0a0;
}
</style>