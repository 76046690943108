<template>
  <div class="group-details-page bg-gradient-to-br from-gray-50 to-gray-100 min-h-screen p-4 md:p-8">
    <div v-if="loading">Loading group details...</div>
    <div v-else-if="error">{{ error }}</div>
    <div v-else-if="group" class="max-w-7xl mx-auto space-y-8">
      <!-- Group Header -->
      <div class="relative bg-white rounded-2xl shadow-xl overflow-hidden">
  <div class="absolute inset-0 bg-gradient-to-r from-violet-600 to-indigo-600 opacity-90"></div>
  <div class="relative p-8 md:p-12">
    <div class="flex flex-col md:flex-row justify-between items-start md:items-center gap-6">
      <div class="space-y-4">
        <h1 class="text-4xl md:text-5xl font-bold text-white tracking-tight">{{ group.name }}</h1>
        <p class="text-lg text-indigo-100 max-w-2xl">{{ group.description }}</p>
        <div class="flex items-center gap-4 text-indigo-100">
          <p class="text-indigo-100">Created by <span class="font-semibold">{{ group.creator?.username }}</span></p>
          <p class="flex items-center gap-2">
            <span>{{ group.members[0].count }} members</span>
            <span class="text-indigo-200">•</span>
            <span>{{ group.posts.length }} posts</span>
          </p>
        </div>
      </div>
      <div class="flex flex-col sm:flex-row gap-4">
        <router-link 
          :to="{ name: 'CreatePost', params: { groupId: group.id } }" 
          class="bg-white text-indigo-600 px-6 py-3 rounded-xl font-medium shadow-lg hover:shadow-xl transition-all duration-300 hover:scale-105 whitespace-nowrap"
        >
          Create New Post
        </router-link>
        <button 
          @click="showInviteModal = true" 
          class="bg-emerald-500 text-white px-6 py-3 rounded-xl font-medium shadow-lg hover:shadow-xl transition-all duration-300 hover:scale-105 whitespace-nowrap"
        >
          Invite Users
        </button>
        <button 
          v-if="!isMember" 
          @click="joinGroup" 
          class="bg-white text-indigo-600 px-6 py-3 rounded-xl font-medium shadow-lg hover:shadow-xl transition-all duration-300 hover:scale-105 whitespace-nowrap"
        >
          Join Group
        </button>
        <span 
          v-else 
          class="bg-emerald-500 text-white px-6 py-3 rounded-xl font-medium shadow-lg whitespace-nowrap"
        >
          Member
        </span>
      </div>
    </div>
  </div>
</div>

      <div class="flex flex-col lg:flex-row gap-8">
        <!-- Main Content -->
        <div class="lg:w-2/3 space-y-6">
          <!-- Search Bar -->
          <div class="bg-white rounded-xl shadow-lg p-4">
            <input
              v-model="searchQuery"
              type="text"
              placeholder="Search posts or members..."
              class="w-full px-5 py-3 bg-gray-50 border-0 rounded-lg focus:ring-2 focus:ring-indigo-500 transition-all duration-300"
            />
          </div>

          <!-- Tabs and Sort -->
          <div class="bg-white rounded-xl shadow-lg p-4">
            <div class="flex justify-between items-center">
              <nav class="flex gap-2" aria-label="Tabs">
                <button 
                  v-for="tab in tabs" 
                  :key="tab"
                  @click="currentTab = tab"
                  :class="[
                    'px-4 py-2 rounded-lg font-medium text-sm transition-all duration-300',
                    currentTab === tab
                      ? 'bg-indigo-100 text-indigo-700 shadow-sm'
                      : 'text-gray-600 hover:bg-gray-50'
                  ]"
                >
                  {{ tab }}
                </button>
              </nav>
              <div v-if="currentTab === 'Posts'" class="flex items-center gap-3">
                <select 
                  v-model="postSortOption" 
                  class="px-4 py-2 rounded-lg bg-gray-50 border-0 text-sm font-medium text-gray-600 focus:ring-2 focus:ring-indigo-500"
                >
                  <option value="newest">Newest</option>
                  <option value="mostLiked">Most Liked</option>
                  <option value="mostCommented">Most Commented</option>
                </select>
              </div>
            </div>
          </div>

          <!-- Posts Tab -->
          <div v-if="currentTab === 'Posts'" class="space-y-6">
            <div 
              v-for="post in filteredPosts" 
              :key="post.id" 
              class="bg-white rounded-xl shadow-lg hover:shadow-xl transition-all duration-300 p-6"
            >
              <div class="flex items-center gap-4 mb-4">
                <img 
                  :src="post.author.avatar_url || '/default-avatar.png'" 
                  :alt="post.author.username" 
                  class="w-12 h-12 rounded-full ring-2 ring-indigo-100"
                >
                <div>
                  <h3 class="font-semibold hover:text-indigo-600 transition-colors">
                    <router-link :to="{ name: 'VisitedUserProfile', params: { username: post.author.username } }">
                      {{ post.author.username }}
                    </router-link>
                  </h3>
                  <p class="text-sm text-gray-500">{{ formatDate(post.created_at) }}</p>
                </div>
              </div>
              <router-link :to="{ name: 'GroupPost', params: { groupId: group.id, postId: post.id } }">
                <h2 class="text-xl font-bold mb-3 hover:text-indigo-600 transition-colors">{{ post.title }}</h2>
              </router-link>
              <div class="text-gray-600 mb-6" v-html="truncateContent(post.content)"></div>
              <div class="flex items-center gap-6 text-gray-500">
                <button @click="likePost(post)" class="flex items-center gap-2 hover:text-indigo-600 transition-colors">
                  <ThumbsUpIcon :class="{ 'text-indigo-600': post.isLiked }" size="20" />
                  <span>{{ post.likes || 0 }}</span>
                </button>
                <button class="flex items-center gap-2 hover:text-indigo-600 transition-colors">
                  <MessageCircleIcon size="20" />
                  <span>{{ post.comments && post.comments[0] ? post.comments[0].count : 0 }}</span>
                </button>
                <button class="flex items-center gap-2 hover:text-indigo-600 transition-colors">
                  <ShareIcon size="20" />
                  <span>Share</span>
                </button>
              </div>
            </div>
          </div>

          <!-- Members Tab -->
          <div 
            v-if="currentTab === 'Members'" 
            class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6"
          >
            <div 
              v-for="member in filteredMembers" 
              :key="member.id" 
              class="bg-white rounded-xl shadow-lg p-6 hover:shadow-xl transition-all duration-300"
            >
              <div class="flex items-center gap-4">
                <img 
                  :src="member.user.avatar_url || '/default-avatar.png'" 
                  :alt="member.user.username" 
                  class="w-16 h-16 rounded-full ring-2 ring-indigo-100"
                >
                <div>
                  <h3 class="font-semibold hover:text-indigo-600 transition-colors">
                    <router-link :to="{ name: 'VisitedUserProfile', params: { username: member.user.username } }">
                      {{ member.user.username }}
                    </router-link>
                  </h3>
                  <span class="inline-block px-3 py-1 bg-indigo-100 text-indigo-700 rounded-full text-sm font-medium">
                    {{ member.role }}
                  </span>
                </div>
              </div>
            </div>
          </div>

          <!-- Bookmarked Posts Tab -->
          <div v-if="currentTab === 'Bookmarks'" class="space-y-6">
            <div v-if="bookmarkedPosts.length === 0" class="text-center py-8">
              <p class="text-lg text-gray-600">You haven't bookmarked any posts in this group yet.</p>
            </div>
            <div 
              v-else 
              v-for="post in bookmarkedPosts" 
              :key="post.id" 
              class="bg-white rounded-xl shadow-lg hover:shadow-xl transition-all duration-300 p-6"
            >
              <!-- Same structure as Posts tab but with bookmark removal -->
              <div class="flex items-center gap-4 mb-4">
                <img 
                  :src="post.author.avatar_url || '/default-avatar.png'" 
                  :alt="post.author.username" 
                  class="w-12 h-12 rounded-full ring-2 ring-indigo-100"
                >
                <div>
                  <h3 class="font-semibold hover:text-indigo-600 transition-colors">
                    <router-link :to="{ name: 'VisitedUserProfile', params: { username: post.author.username } }">
                      {{ post.author.username }}
                    </router-link>
                  </h3>
                  <p class="text-sm text-gray-500">{{ formatDate(post.created_at) }}</p>
                </div>
              </div>
              <h2 class="text-xl font-bold mb-3 hover:text-indigo-600 transition-colors">{{ post.title }}</h2>
              <div class="text-gray-600 mb-6" v-html="truncateContent(post.content)"></div>
              <div class="flex items-center gap-6 text-gray-500">
                <button class="flex items-center gap-2 hover:text-indigo-600 transition-colors">
                  <ThumbsUpIcon size="20" />
                  <span>{{ post.likes || 0 }}</span>
                </button>
                <button class="flex items-center gap-2 hover:text-indigo-600 transition-colors">
                  <MessageCircleIcon size="20" />
                  <span>{{ post.comments || 0 }}</span>
                </button>
                <button 
                  @click="removeBookmark(post.id)" 
                  class="flex items-center gap-2 hover:text-indigo-600 transition-colors"
                >
                  <BookmarkIcon size="20" />
                  <span>Remove Bookmark</span>
                </button>
              </div>
            </div>
          </div>
        </div>

        <!-- Sidebar -->
        <div class="lg:w-1/3 space-y-6">
          <!-- About Section -->
          <div class="bg-white rounded-xl shadow-lg hover:shadow-xl transition-all duration-300">
            <div class="border-b bg-gray-50 p-4">
              <div class="flex gap-4">
                <button 
                  @click="aboutTab = 'about'"
                  :class="[
                    'px-4 py-2 rounded-lg font-medium text-sm transition-all duration-300',
                    aboutTab === 'about'
                      ? 'bg-indigo-100 text-indigo-700'
                      : 'text-gray-600 hover:bg-gray-100'
                  ]"
                >
                  About
                </button>
                <button 
                  @click="aboutTab = 'rules'"
                  :class="[
                    'px-4 py-2 rounded-lg font-medium text-sm transition-all duration-300',
                    aboutTab === 'rules'
                      ? 'bg-indigo-100 text-indigo-700'
                      : 'text-gray-600 hover:bg-gray-100'
                  ]"
                >
                  Rules
                </button>
              </div>
            </div>
            <div class="p-6">
              <div v-if="aboutTab === 'about'">
                <h2 class="text-xl font-bold mb-4">About This Group</h2>
                <p class="text-gray-600 mb-4">{{ group.description }}</p>
                <div class="flex flex-wrap gap-2">
                  <span 
                    v-for="tag in group.tags" 
                    :key="tag" 
                    class="bg-indigo-100 text-indigo-700 px-3 py-1 rounded-full text-sm font-medium"
                  >
                    {{ tag }}
                  </span>
                </div>
              </div>
              <div v-else-if="aboutTab === 'rules'">
                <h2 class="text-xl font-bold mb-4">Group Rules</h2>
                <ul v-if="group.rules && group.rules.length > 0" class="space-y-3">
                  <li 
                    v-for="(rule, index) in group.rules" 
                    :key="index" 
                    class="flex items-start gap-3 text-gray-700"
                  >
                    <span class="inline-block px-2 py-1 bg-indigo-100 text-indigo-700 rounded-md text-sm font-medium">
                      {{ index + 1 }}
                    </span>
                    {{ rule }}
                  </li>
                </ul>
                <p v-else class="text-gray-600">No specific rules have been set for this group.</p>
              </div>
            </div>
          </div>

          <!-- Moderators Section -->
          <div class="bg-white rounded-xl shadow-lg hover:shadow-xl transition-all duration-300">
            <div class="border-b bg-gray-50 p-4">
              <h2 class="text-xl font-bold">Moderators</h2>
            </div>
            <div class="p-6">
              <ul class="space-y-4">
                <li v-for="mod in moderators" :key="mod.id" class="flex items-center gap-4">
                  <img 
                    :src="mod.user.avatar_url || '/default-avatar.png'" 
                    :alt="mod.user.username" 
                    class="w-12 h-12 rounded-full ring-2 ring-indigo-100"
                  >
                  <div>
                    <p class="font-semibold hover:text-indigo-600 transition-colors">
                      <router-link :to="{ name: 'VisitedUserProfile', params: { username: mod.user.username } }">
                        {{ mod.user.username }}
                      </router-link>
                    </p>
<span class="inline-block px-3 py-1 bg-indigo-100 text-indigo-700 rounded-full text-sm font-medium">
                      {{ mod.role }}
                    </span>
                  </div>
                </li>
              </ul>
            </div>
          </div>

          <!-- Recent Activity -->
          <div class="bg-white rounded-xl shadow-lg hover:shadow-xl transition-all duration-300">
            <div class="border-b bg-gray-50 p-4">
              <h2 class="text-xl font-bold">Recent Activity</h2>
            </div>
            <div class="h-[400px] overflow-y-auto">
              <ul class="divide-y">
                <li 
                  v-for="activity in recentActivities" 
                  :key="activity.id" 
                  class="p-4 hover:bg-gray-50 transition-colors"
                >
                  <div class="flex gap-3">
                    <div class="mt-1">
                      <ActivityIcon 
                        v-if="activity.type === 'join'" 
                        :size="18" 
                        class="text-emerald-500" 
                      />
                      <PenToolIcon 
                        v-else-if="activity.type === 'post'" 
                        :size="18" 
                        class="text-indigo-500" 
                      />
                      <MessageCircleIcon 
                        v-else-if="activity.type === 'comment'" 
                        :size="18" 
                        class="text-violet-500" 
                      />
                    </div>
                    <div>
                      <p class="text-sm">
                        <router-link 
                          :to="{ name: 'VisitedUserProfile', params: { username: activity.username } }" 
                          class="font-semibold hover:text-indigo-600 transition-colors"
                        >
                          {{ activity.username }}
                        </router-link> 
                        {{ activity.action }}
                        <span v-if="activity.type === 'comment'" class="italic">"{{ activity.content }}"</span>
                      </p>
                      <p class="text-xs text-gray-500">{{ formatDate(activity.created_at) }}</p>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Invite Users Modal -->
    <div 
      v-if="showInviteModal" 
      class="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
    >
      <div class="bg-white rounded-xl shadow-xl p-6 max-w-md w-full mx-4 transform transition-all duration-300">
        <h2 class="text-2xl font-bold mb-6">Invite Users</h2>
        <div class="space-y-4">
          <div>
            <label for="inviteUsers" class="block text-sm font-medium text-gray-700 mb-1">Username</label>
            <input
              v-model="inviteUserQuery"
              @input="searchUsers"
              type="text"
              id="inviteUsers"
              class="w-full px-4 py-2 bg-gray-50 border-0 rounded-lg focus:ring-2 focus:ring-indigo-500 transition-all duration-300"
              placeholder="Start typing a username..."
            >
            <div 
              v-if="filteredUsers.length > 0" 
              class="mt-2 bg-white border border-gray-200 rounded-lg shadow-lg max-h-40 overflow-y-auto"
            >
              <div 
                v-for="user in filteredUsers" 
                :key="user.id" 
                @click="selectUser(user)"
                class="p-3 hover:bg-gray-50 cursor-pointer transition-colors"
              >
                {{ user.username }}
              </div>
            </div>
          </div>
          
          <div>
            <label class="block text-sm font-medium text-gray-700 mb-2">Selected Users</label>
            <div class="flex flex-wrap gap-2">
              <div 
                v-for="user in selectedUsers" 
                :key="user.id" 
                class="bg-indigo-100 text-indigo-800 px-3 py-1 rounded-full text-sm font-medium flex items-center"
              >
                {{ user.username }}
                <button 
                  @click="removeUser(user)" 
                  class="ml-2 text-indigo-600 hover:text-indigo-800 transition-colors"
                >
                  &times;
                </button>
              </div>
            </div>
          </div>
        </div>
        
        <div class="flex justify-end gap-3 mt-6">
          <button
            @click="showInviteModal = false"
            class="px-4 py-2 text-gray-700 bg-gray-100 rounded-lg hover:bg-gray-200 transition-all duration-300"
          >
            Cancel
          </button>
          <button
            @click="inviteUsers"
            class="px-4 py-2 text-white bg-indigo-600 rounded-lg hover:bg-indigo-700 transition-all duration-300"
          >
            Send Invites
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, onMounted, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { ThumbsUpIcon, MessageCircleIcon, ShareIcon, BookmarkIcon, ActivityIcon, PenToolIcon } from 'lucide-vue-next';
import { groupService } from '@/services/groupService';
import { useStore } from 'vuex';

export default {
  name: 'GroupDetailsPage',
  components: {
    ThumbsUpIcon,
    MessageCircleIcon,
    ShareIcon,
    BookmarkIcon,
    ActivityIcon,
    PenToolIcon
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const groupId = route.params.id;
    const group = ref(null);
    const loading = ref(true);
    const error = ref(null);
    const isMember = ref(false);
    const searchQuery = ref('');
    const showInviteModal = ref(false);
    const inviteUserQuery = ref('');
    const filteredUsers = ref([]);
    const selectedUsers = ref([]);
    const currentTab = ref('Posts');
    const tabs = ['Posts', 'Members', 'Bookmarks'];
    const posts = ref([]);
    const members = ref([]);
    const moderators = ref([]);
    const bookmarkedPosts = ref([]);
    const postSortOption = ref('newest');
    const aboutTab = ref('about');
    const comments = ref([]);

    const fetchGroupDetails = async () => {
  try {
    loading.value = true;
    group.value = await groupService.getGroupDetails(groupId);
    const userId = store.getters['auth/getCurrentUserId'];
    posts.value = await groupService.getGroupPosts(groupId, userId);
    members.value = await groupService.getGroupMembers(groupId);
    moderators.value = members.value.filter(member => member.role === 'admin' || member.role === 'moderator');
    comments.value = await groupService.getRecentGroupComments(groupId, 50);
    
    isMember.value = members.value.some(member => member.user.id === userId);
    
    if (isMember.value) {
      bookmarkedPosts.value = await groupService.getBookmarkedPosts(userId, groupId);
    }
  } catch (err) {
    console.error('Error fetching group details:', err);
    error.value = 'Failed to load group details: ' + (err.message || 'Unknown error');
  } finally {
    loading.value = false;
  }
};

    const sortedPosts = computed(() => {
      let sortedPosts = [...posts.value];
      switch (postSortOption.value) {
        case 'mostLiked':
          return sortedPosts.sort((a, b) => (b.likes || 0) - (a.likes || 0));
        case 'mostCommented':
          return sortedPosts.sort((a, b) => b.comments[0].count - a.comments[0].count);
        case 'newest':
        default:
          return sortedPosts.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
      }
    });

    const filteredPosts = computed(() => {
      return sortedPosts.value.filter(post =>
        post.title.toLowerCase().includes(searchQuery.value.toLowerCase()) ||
        post.content.toLowerCase().includes(searchQuery.value.toLowerCase()) ||
        post.author.username.toLowerCase().includes(searchQuery.value.toLowerCase())
      );
    });

    const filteredMembers = computed(() => {
      return members.value.filter(member =>
        member.user.username.toLowerCase().includes(searchQuery.value.toLowerCase()) ||
        member.role.toLowerCase().includes(searchQuery.value.toLowerCase())
      );
    });

    const recentActivities = computed(() => {
  const stripHtml = (html) => {
    const tmp = document.createElement("DIV");
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || "";
  };
      const allActivities = [
    ...posts.value.map(post => ({
      id: `post_${post.id}`,
      type: 'post',
      username: post.author.username,
      action: 'created a new post',
      created_at: post.created_at
    })),
    ...members.value.map(member => ({
      id: `member_${member.id}`,
      type: 'join',
      username: member.user.username,
      action: 'joined the group',
      created_at: member.joined_at || member.created_at
    })),
    ...comments.value.map(comment => ({
      id: `comment_${comment.id}`,
      type: 'comment',
      username: comment.author.username,
      action: 'commented on a post',
      content: stripHtml(comment.content).substring(0, 50) + (stripHtml(comment.content).length > 50 ? '...' : ''),
      created_at: comment.created_at
    }))
  ];

  return allActivities
    .sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
    .slice(0, 20);
});

    const joinGroup = async () => {
      const userId = store.getters['auth/getCurrentUserId'];
      if (!userId) {
        console.error('User not logged in');
        return;
      }
      try {
        await groupService.addGroupMember(groupId, userId);
        isMember.value = true;
        await fetchGroupDetails();
      } catch (err) {
        console.error('Error joining group:', err);
        error.value = 'Failed to join group: ' + (err.message || 'Unknown error');
      }
    };

    const truncateContent = (content) => {
      const strippedContent = content.replace(/<[^>]*>/g, '');
      return strippedContent.length > 200 ? strippedContent.slice(0, 200) + '...' : strippedContent;
    };

    const likePost = async (post) => {
  try {
    const userId = store.getters['auth/getCurrentUserId'];
    if (post.isLiked) {
      await groupService.unlikePost(post.id, userId);
      post.likes--;
    } else {
      await groupService.likePost(post.id, userId);
      post.likes++;
    }
    post.isLiked = !post.isLiked;
    // Create a new array to trigger reactivity
    posts.value = posts.value.map(p => p.id === post.id ? {...p} : p);
  } catch (error) {
    console.error('Error liking/unliking post:', error);
  }
};

    const searchUsers = async () => {
      if (inviteUserQuery.value.length < 2) {
        filteredUsers.value = [];
        return;
      }
      try {
        filteredUsers.value = await groupService.searchUsers(inviteUserQuery.value);
      } catch (err) {
        console.error('Error searching users:', err);
        error.value = 'Failed to search users: ' + (err.message || 'Unknown error');
      }
    };

    const selectUser = (user) => {
      if (!selectedUsers.value.some(u => u.id === user.id)) {
        selectedUsers.value.push(user);
      }
      inviteUserQuery.value = '';
      filteredUsers.value = [];
    };

    const removeUser = (user) => {
      selectedUsers.value = selectedUsers.value.filter(u => u.id !== user.id);
    };

    const inviteUsers = async () => {
      try {
        const currentUserId = store.getters['auth/getCurrentUserId'];
        for (const user of selectedUsers.value) {
          await groupService.inviteUserToGroup(groupId, currentUserId, user.id);
        }
        showInviteModal.value = false;
        selectedUsers.value = [];
        // Show success message or notification here
      } catch (err) {
        console.error('Error inviting users:', err);
        error.value = 'Failed to invite users: ' + (err.message || 'Unknown error');
      }
    };

    const formatDate = (date) => {
      return new Date(date).toLocaleDateString('en-US', { 
        year: 'numeric', 
        month: 'long', 
        day: 'numeric' 
      });
    };

    const removeBookmark = async (postId) => {
      try {
        const userId = store.getters['auth/getCurrentUserId'];
        await groupService.unbookmarkPost(postId, userId);
        bookmarkedPosts.value = bookmarkedPosts.value.filter(post => post.id !== postId);
      } catch (err) {
        console.error('Error removing bookmark:', err);
        error.value = 'Failed to remove bookmark: ' + (err.message || 'Unknown error');
      }
    };

    onMounted(async () => {
      await fetchGroupDetails();
    });

    watch(currentTab, (newTab) => {
      if (newTab === 'Bookmarks' && bookmarkedPosts.value.length === 0) {
        const userId = store.getters['auth/getCurrentUserId'];
        if (userId) {
          groupService.getBookmarkedPosts(userId, groupId).then(posts => {
            bookmarkedPosts.value = posts;
          });
        }
      }
    });

    return {
      group,
      loading,
      error,
      isMember,
      posts,
      members,
      moderators,
      bookmarkedPosts,
      recentActivities,
      searchQuery,
      filteredPosts,
      filteredMembers,
      showInviteModal,
      inviteUserQuery,
      filteredUsers,
      selectedUsers,
      currentTab,
      tabs,
      postSortOption,
      aboutTab,
      joinGroup,
      searchUsers,
      selectUser,
      removeUser,
      inviteUsers,
      formatDate,
      router,
      truncateContent,
      sortedPosts,
      likePost,
      removeBookmark
    };
  }
};
</script>

<style scoped>
.group-details-page {
  /* Add any specific styles for the page */
}

.post-image {
  max-width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 0.75rem;
}

/* Enhanced scrollbar styling */
.recent-activity-scroll {
  max-height: 300px;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: #818cf8 #e5e7eb;
}

.recent-activity-scroll::-webkit-scrollbar {
  width: 6px;
}
.recent-activity-scroll::-webkit-scrollbar-track {
  background: #e5e7eb;
  border-radius: 3px;
}

.recent-activity-scroll::-webkit-scrollbar-thumb {
  background-color: #818cf8;
  border-radius: 3px;
}
</style>