<template>
  <div class="min-h-screen bg-gradient-to-br from-gray-50 to-gray-100 py-12">
    <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <!-- Loading State -->
      <div v-if="loading" class="flex justify-center items-center min-h-[400px]">
        <div class="animate-spin rounded-full h-12 w-12 border-b-2 border-purple-600"></div>
      </div>

      <!-- Error State -->
      <div v-else-if="error" class="bg-red-50 p-4 rounded-lg text-red-600">
        {{ error }}
      </div>

      <!-- Content -->
      <div v-else-if="list" class="space-y-8">
        <!-- Header -->
        <div class="bg-white rounded-2xl shadow-xl p-6">
          <div class="flex items-center justify-between mb-4">
            <h1 class="text-2xl font-bold text-gray-900">{{ list.title }}</h1>
            <div class="flex items-center space-x-2">
              <span class="text-gray-600">Created by</span>
              <div class="flex items-center">
                <img 
                  :src="list.creator?.avatar_url || defaultAvatar" 
                  :alt="list.creator?.name"
                  class="w-8 h-8 rounded-full mr-2 object-cover ring-2 ring-purple-100"
                />
                <router-link 
                  :to="{ name: 'VisitedUserProfile', params: { username: list.creator?.username }}"
                  class="font-medium text-purple-600 hover:underline"
                >
                  {{ list.creator?.username }}
                </router-link>
              </div>
            </div>
          </div>
          <p v-if="list.description" class="text-gray-700">{{ list.description }}</p>
        </div>

        <!-- Members Section -->
        <div class="bg-white rounded-2xl shadow-xl p-6">
          <h2 class="text-xl font-bold text-gray-900 mb-6">Members</h2>
          <div v-if="members.length === 0" class="text-center text-gray-500">
            No members yet
          </div>
          <div v-else class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
            <div v-for="member in members" :key="member.id" 
              class="flex items-center p-4 bg-gray-50 rounded-xl">
              <router-link 
  :to="{ name: 'VisitedUserProfile', params: { username: member.username }}"
  class="cursor-pointer"
>
  <img 
    :src="member.avatar_url || defaultAvatar" 
    :alt="member.name"
    class="w-10 h-10 rounded-full mr-3 object-cover ring-2 ring-purple-100"
  />
</router-link>
              <div>
                <router-link 
                  :to="{ name: 'VisitedUserProfile', params: { username: member.username }}"
                  class="font-medium hover:text-purple-600"
                >
                  {{ member.name }}
                </router-link>
                <p class="text-sm text-gray-600">@{{ member.username }}</p>
              </div>
            </div>
          </div>
        </div>

        <!-- Books Section -->
        <div class="bg-white rounded-2xl shadow-xl p-6">
          <h2 class="text-xl font-bold text-gray-900 mb-6">Recommended Books</h2>
          <div v-if="books.length === 0" class="text-center text-gray-500">
            No books recommended yet
          </div>
          <div class="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-6">
            <div v-for="book in sortedBooks" :key="book.id" 
              class="group bg-gray-50 rounded-xl overflow-hidden cursor-pointer"
              @click="router.push(`/story/${book.stories.id}`)">
              <div 
  class="relative pt-[150%]"
  @click="router.push(`/story/${book.stories.id}`)"
>
  <img 
    :src="book.stories.cover_image" 
    :alt="book.stories.title"
    class="absolute inset-0 w-full h-full object-cover transform transition-transform duration-300 group-hover:scale-105"
  />
  <button
    v-if="list.creator?.id === user?.id"
    @click.stop.prevent="removeBook(book.stories.id)"
    class="absolute top-2 right-2 p-2 bg-red-500 rounded-full text-white opacity-0 group-hover:opacity-100 transition-opacity z-10"
  >
                  <span class="sr-only">Remove book</span>
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                    <path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd" />
                  </svg>
                </button>
                <div class="absolute inset-0 bg-gradient-to-t from-black/60 via-black/20 to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-300"></div>
              </div>
              <div class="p-4">
                <h3 class="font-bold text-lg mb-1 group-hover:text-purple-600 transition-colors">
                  {{ book.stories.title }}
                </h3>
                <p class="text-gray-600 text-sm mb-2">by {{ book.stories.author.username }}</p>
                
                <p v-if="book.message" class="mt-2 text-gray-700 text-sm italic">
                  "{{ book.message }}"
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, computed } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import defaultAvatar from '@/assets/default-avatar-svg.png';
import { supabase } from '@/supabase';

const router = useRouter();
const route = useRoute();

const loading = ref(true);
const error = ref(null);
const list = ref(null);
const members = ref([]);
const books = ref([]);
const user = ref(null);
const sortBy = ref('date');

const sortedBooks = computed(() => {
  if (sortBy.value === 'name') {
    return [...books.value].sort((a, b) => 
      a.stories.title.localeCompare(b.stories.title)
    );
  }
  // Default date sort uses the list_stories id which is auto-incrementing
  return [...books.value].sort((a, b) => b.id - a.id);
});

const removeBook = async (bookId) => {
  try {
    const { error: deleteError } = await supabase
      .from('list_stories')
      .delete()
      .eq('list_id', route.params.id)
      .eq('story_id', bookId)
      
    if (deleteError) throw deleteError
    
    // Remove from local state
    books.value = books.value.filter(book => book.stories.id !== bookId)
  } catch (err) {
    console.error('Error removing book:', err)
  }
};

const fetchData = async () => {
  try {
    // First get auth user
    const { data: { user: authUser } } = await supabase.auth.getUser()
    
    // Then get database user
    if (authUser) {
      const { data: dbUser } = await supabase
        .from('users')
        .select('id')
        .eq('id', authUser.id)
        .single()
      user.value = dbUser
    }
   
    const listId = route.params.id;
    
    // First check if user has access (either creator or member)
    const { data: accessCheck } = await supabase
      .from('recommendation_list_items')
      .select('id')
      .eq('list_id', listId)
      .eq('user_id', authUser?.id)
      .single();

    // If not a member, check if they're the creator
    if (!accessCheck) {
      const { data: creatorCheck } = await supabase
        .from('recommendation_lists')
        .select('id')
        .eq('id', listId)
        .eq('user_id', authUser?.id)
        .single();

      if (!creatorCheck) {
        error.value = "You don't have permission to view this list";
        loading.value = false;
        return;
      }
    }
    
    // Get list and creator
    const { data: listData, error: listError } = await supabase
      .from('recommendation_lists')
      .select(`
        *,
        creator:users (
          id, 
          name,
          username,
          avatar_url
        )
      `)
      .eq('id', listId)
      .single();
    if (listError) throw listError;
    list.value = listData;
    // Get members
    const { data: memberData, error: memberError } = await supabase
      .from('recommendation_list_items')
      .select(`
        users (
          id,
          username,
          name,
          avatar_url
        )
      `)
      .eq('list_id', listId);
    if (memberError) throw memberError;
    members.value = memberData.map(item => item.users);
    // Get stories - using explicit joins
    const { data: storyData, error: storyError } = await supabase
      .from('list_stories')
      .select(`
        *,
        stories (
          id,
          title,
          blurb,
          cover_image,
          author:users (
            id,
            name,
            username
          )
        )
      `)
      .eq('list_id', listId);
    if (storyError) throw storyError;
    books.value = storyData;
  } catch (err) {
    console.error('Error:', err);
    error.value = err.message;
  } finally {
    loading.value = false;
  }
};

onMounted(() => {
  fetchData();
});
</script>