<template>
  <div v-if="story" class="story-page bg-gray-100 min-h-screen p-8">
    <div class="max-w-4xl mx-auto bg-white rounded-lg shadow-md overflow-hidden">
      <div class="bg-gradient-to-r from-blue-500 to-purple-600 p-6 text-white">
        <h1 class="text-3xl font-bold">{{ story.title }}</h1>
        <router-link :to="{ name: 'VisitedUserProfile', params: { username: story.author?.username } }" class="hover:underline">
          {{ story.author?.username }}
        </router-link>
      </div>

      <div class="p-6">
        <!-- Main content section -->
        <div class="flex mb-6">
          <!-- Left side with image -->
          <img :src="story.cover_image" :alt="story.title" class="w-1/3 h-auto rounded-lg shadow-md mr-6" />
          
          <!-- Right side with stats and actions -->
          <div class="flex-1">
            <p class="text-gray-600 mb-4">Last updated: {{ formatDate(story.latest_update) }}</p>
            
            <!-- Stats Grid - Reordered -->
            <div class="grid grid-cols-2 gap-4 mb-4">
              <div class="text-center p-2 bg-gray-100 rounded">
                <p class="text-2xl font-bold text-blue-600">{{ story.chapters?.length || 0 }}</p>
                <p class="text-sm text-gray-600">Chapters</p>
              </div>
              <div class="text-center p-2 bg-gray-100 rounded">
                <p class="text-2xl font-bold text-blue-600">{{ story.comments || 0 }}</p>
                <p class="text-sm text-gray-600">Comments</p>
              </div>
              <div class="text-center p-2 bg-gray-100 rounded">
                <p class="text-2xl font-bold text-blue-600">{{ story.reads || 0 }}</p>
                <p class="text-sm text-gray-600">Reads</p>
              </div>
              <div class="text-center p-2 bg-gray-100 rounded">
                <p class="text-2xl font-bold text-blue-600">{{ story.votes || 0 }}</p>
                <p class="text-sm text-gray-600">Votes</p>
              </div>
            </div>

            <!-- Tags -->
            <div class="flex flex-wrap gap-2 mb-4">
              <router-link 
                v-for="tag in story.tags" 
                :key="tag" 
                :to="{ name: 'ExplorePage', query: { tag: tag } }"
                class="bg-blue-100 text-blue-800 px-2 py-1 rounded-full text-sm hover:bg-blue-200"
              >
                {{ tag }}
              </router-link>
            </div>

            <!-- Action Buttons -->
            <div class="flex space-x-4">
              <button @click="showRecommendModal = true" class="bg-blue-600 text-white px-4 py-2 rounded-full hover:bg-blue-700 transition duration-300">
                <UserPlusIcon class="w-5 h-5 inline-block mr-2" />
                Recommend Story
              </button>
              <button @click="handleSaveToBookshelf" class="bg-green-600 text-white px-4 py-2 rounded-full hover:bg-green-700 transition duration-300">
                <BookOpen class="w-5 h-5 inline-block mr-2" />
                {{ isInBookshelf ? 'Saved' : 'Add to Bookshelf' }}
              </button>
            </div>

            <!-- Hide Activity Checkbox -->
            <div class="mt-4 flex items-center">
              <input 
                type="checkbox" 
                id="hideActivity" 
                v-model="hideActivity" 
                class="mr-2"
                @change="toggleActivityVisibility"
              >
              <label for="hideActivity" class="text-sm text-gray-700">Hide activity for this story from public profile</label>
            </div>
          </div>
        </div>

        <!-- Collapsible Blurb -->
<div class="mb-6">
  <div class="flex items-center justify-between mb-2">
    <h2 class="text-2xl font-bold">Story Blurb</h2>
    <button 
      @click="isBlurbExpanded = !isBlurbExpanded" 
      class="text-blue-600 hover:text-blue-700"
    >
      {{ isBlurbExpanded ? 'Show Less' : 'Show More' }}
    </button>
  </div>
  <p :class="{'line-clamp-3': isBlurbExpanded}" class="transition-all duration-300">
    {{ story.blurb }}
  </p>
</div>

        <!-- Chapters List -->
        <div class="mb-8">
          <h2 class="text-2xl font-bold mb-2">Chapters</h2>
          <ul v-if="story.chapters && story.chapters.length" class="space-y-2 mb-6 bg-gray-50 rounded-lg divide-y divide-gray-200">
            <li v-for="chapter in story.chapters" :key="chapter.id" class="flex justify-between items-center p-3 hover:bg-gray-100">
              <a @click="navigateToChapter(chapter.id)" class="text-blue-600 hover:underline cursor-pointer">
                Chapter {{ chapter.chapter_number }} - {{ chapter.title }}
              </a>
              <span class="text-gray-500 text-sm">{{ formatDate(chapter.created_at) }}</span>
            </li>
          </ul>
          <p v-else class="text-gray-600">No chapters available yet.</p>
        </div>

        <!-- Author Info -->
<h2 class="text-2xl font-bold mb-2">About the Author</h2>
<div class="flex items-center space-x-4 mb-6 bg-gray-50 p-4 rounded-lg">
  <img 
    :src="story.author?.avatar_url || defaultAvatar" 
    :alt="story.author?.username" 
    class="w-16 h-16 rounded-full"
  />
  <div>
    <router-link 
      :to="{ name: 'VisitedUserProfile', params: { username: story.author?.username } }" 
      class="hover:underline"
    >
      {{ story.author?.username }}
    </router-link>
    <p class="text-gray-600">{{ story.author?.bio }}</p>
  </div>
</div>

        <!-- Recommended Stories with adjusted sizing -->
        <h2 class="text-2xl font-bold mb-2">Recommended Stories</h2>
        <div class="grid grid-cols-3 gap-4">
          <div v-for="recommendedStory in recommendedStories" :key="recommendedStory.id" class="bg-gray-100 rounded-lg p-3">
            <router-link :to="{ name: 'StoryPage', params: { id: recommendedStory.id } }">
              <div class="aspect-[2/3] mb-2 overflow-hidden rounded-md">
                <img 
                  :src="recommendedStory.cover_image" 
                  :alt="recommendedStory.title" 
                  class="w-full h-full object-cover transform hover:scale-105 transition-transform duration-300"
                />
              </div>
              <h3 class="font-semibold text-sm">{{ recommendedStory.title }}</h3>
              <p class="text-xs text-gray-600">by {{ recommendedStory.author }}</p>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div v-else class="text-center py-8">
    <p class="text-2xl text-gray-600">Loading story...</p>
  </div>

  <!-- Recommend Modal -->
  <div v-if="showRecommendModal" class="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
    <div class="bg-white rounded-lg p-8 w-full max-w-md shadow-2xl">
      <h2 class="text-3xl font-bold mb-6 text-indigo-800">Recommend</h2>
      <form @submit.prevent="submitRecommendation" class="space-y-6">
        <div>
          <label class="block text-sm font-medium text-gray-700 mb-2">Select Recommendation List</label>
          <select 
            v-model="selectedList" 
            class="w-full px-4 py-2 rounded-md border-2 border-indigo-300 focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 text-gray-700"
          >
            <option value="" disabled selected>Select a list</option>
            <option v-for="list in recommendationLists" :key="list.id" :value="list.id">
              {{ list.title }}
            </option>
          </select>
        </div>
        <div>
          <label class="block text-sm font-medium text-gray-700 mb-2">Personal Message (optional)</label>
          <textarea 
            v-model="recommendationMessage" 
            rows="3" 
            class="w-full px-4 py-2 rounded-md border-2 border-indigo-300 focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            placeholder="Add a personal note to your recommendation"
          ></textarea>
        </div>
        <div class="flex justify-end space-x-4">
          <button 
            @click="showRecommendModal = false" 
            type="button" 
            class="px-6 py-2 bg-gray-200 text-gray-800 rounded-full hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-opacity-50"
          >
            Cancel
          </button>
          <button 
  type="submit" 
  class="px-6 py-2 bg-indigo-600 text-white rounded-full hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-opacity-50"
  :disabled="isSubmitting"
>
  {{ isSubmitting ? 'Sending...' : 'Send Recommendation' }}
</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { ref, computed, onMounted, onUnmounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { UserPlusIcon, BookOpen } from 'lucide-vue-next';
import defaultAvatar from '@/assets/default-avatar-svg.png';
import { supabase } from '@/supabase';

export default {
  name: 'StoryPage',
  components: {
    UserPlusIcon,
    BookOpen,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const story = ref(null);
    const isInBookshelf = ref(false);
    const recommendedStories = ref([]);
    const showRecommendModal = ref(false);
    const selectedList = ref(null);
    const recommendationMessage = ref('');
    const recommendationLists = ref([]);
    const hideActivity = ref(false);
    const readProgress = ref(0);
    const isBlurbExpanded = ref(true);
    const currentReadingStatus = ref(null);
    let readTrackingInterval;

    const fetchStory = async () => {
  try {
    const { data, error } = await supabase
      .from('stories')
      .select(`
        *,
        author:users!author_id(username, avatar_url, bio),
        chapters!inner(
          id, 
          title, 
          created_at, 
          chapter_number,
          published,
          updated_at
        ),
        story_reads(count),
        story_votes(count),
        story_comments(count)
      `)
      .eq('id', route.params.id)
      .eq('chapters.published', true)
      .order('updated_at', { foreignTable: 'chapters', ascending: false })  // Fixed order syntax
      .limit(1)
      .single();

    if (error) {
      console.error('Error fetching story:', error);
      return null;
    }

    if (!data) {
      console.error('No story data found');
      return null;
    }

    // Get the latest update time from either the latest chapter or story publish date
    const latestUpdate = data.chapters?.[0]?.updated_at || data.published_at || data.created_at;

    return {
      ...data,
      latest_update: latestUpdate,
      reads: data.story_reads?.[0]?.count || 0,
      votes: data.story_votes?.[0]?.count || 0,
      comments: data.story_comments?.[0]?.count || 0
    };
  } catch (err) {
    console.error('Error in fetchStory:', err);
    return null;
  }
};

    const truncatedBio = computed(() => {
  const bio = story.value?.author?.bio || '';
  return bio.split('.')[0] + '.';
});

    const fetchRecommendedStories = async (genre) => {
  if (!genre) {
    console.error('Genre is not available');
    return [];
  }

  const { data: recommendedStoriesData, error } = await supabase
    .from('stories')
    .select('id, title, author:users!author_id(username), cover_image')
    .eq('genre', genre)
    .neq('id', route.params.id)
    .limit(3);

  if (error) {
    console.error('Error fetching recommended stories:', error);
    return [];
  }

  return recommendedStoriesData.map(story => ({
    id: story.id,
    title: story.title,
    author: story.author.username,
    cover_image: story.cover_image
  }));
};

    const fetchRecommendationLists = async () => {
  const { data: { user } } = await supabase.auth.getUser();
  if (!user) {
    console.error('No authenticated user found');
    return;
  }
  const { data, error } = await supabase
    .from('recommendation_lists')
    .select('id, title')
    .eq('user_id', user.id);

  if (error) {
    console.error('Error fetching recommendation lists:', error);
  } else {
    recommendationLists.value = data;
    console.log('Fetched recommendation lists:', data);
  }
};

    const updateReadProgress = async () => {
  const { data: user } = await supabase.auth.getUser();
  const { error } = await supabase  // Remove 'data' from destructuring
    .from('story_reads')
    .upsert({
      story_id: story.value.id,
      user_id: user.id,
      progress: readProgress.value,
      last_read_at: new Date().toISOString()
    }, { onConflict: 'story_id, user_id' });

  if (error) {
    console.error('Error updating read progress:', error);
  }
};

    const trackReadProgress = () => {
      const totalHeight = document.documentElement.scrollHeight - window.innerHeight;
      const progress = (window.scrollY / totalHeight) * 100;
      readProgress.value = Math.min(Math.max(progress, readProgress.value), 100);
    };

    const handleSaveToBookshelf = async () => {
    const { data: { user } } = await supabase.auth.getUser();
    if (!user) return;
    
    const { error } = await supabase
      .from('bookshelf')
      .upsert({
        user_id: user.id,
        story_id: story.value.id,
        status: 'Want to Read',
        added_at: new Date().toISOString()
      }, { 
        onConflict: 'user_id, story_id'
      });

    if (error) {
      console.error('Error saving to bookshelf:', error);
    } else {
      isInBookshelf.value = true;
    }
  };

    const toggleActivityVisibility = async () => {
  const { data: { user } } = await supabase.auth.getUser();
  const { error } = await supabase
    .from('user_preferences')
    .upsert({
      user_id: user.id,
      story_id: story.value.id,
      hide_activity: hideActivity.value
    }, { onConflict: 'user_id, story_id' });

  if (error) {
    console.error('Error updating activity visibility:', error);
  } else {
    console.log('Activity visibility updated:', hideActivity.value);
  }
};

    onMounted(async () => {
  const storyId = route.params.id;
  
  // Fetch story first
  const storyData = await fetchStory(storyId);
  if (!storyData) {
    console.error('Failed to fetch story data');
    return;
  }
  
  story.value = storyData;

  // Now fetch recommended stories using the genre from the loaded story
  if (story.value?.genre) {
    recommendedStories.value = await fetchRecommendedStories(story.value.genre);
  }

  // Only log stats if we have story data
  if (story.value) {
    console.log('Story stats:', {
      reads: story.value?.reads || 0,
      votes: story.value?.votes || 0,
      comments: story.value?.comments || 0,
      chapters: story.value?.chapters?.length || 0
    });
  }

  // Fetch recommendation lists after ensuring we have a logged-in user
  await fetchRecommendationLists();

  const { data: { user } } = await supabase.auth.getUser();
  if (user && story.value) {
    const [bookshelfEntry, userPreference] = await Promise.all([
      supabase
        .from('bookshelf')
        .select('status')
        .eq('user_id', user.id)
        .eq('story_id', storyId)
        .maybeSingle(),
      supabase
        .from('user_preferences')
        .select('hide_activity')
        .eq('user_id', user.id)
        .eq('story_id', storyId)
        .maybeSingle()
    ]);

    isInBookshelf.value = !!bookshelfEntry.data;
    hideActivity.value = userPreference.data?.hide_activity || false;
    
    if (bookshelfEntry.data) {
      currentReadingStatus.value = bookshelfEntry.data.status;
    }
  }

  window.addEventListener('scroll', trackReadProgress);
  readTrackingInterval = setInterval(updateReadProgress, 30000);
});

    onUnmounted(() => {
      window.removeEventListener('scroll', trackReadProgress);
      clearInterval(readTrackingInterval);
    });

    const submitRecommendation = async () => {
  if (!selectedList.value) return;
  
  const { data: { user } } = await supabase.auth.getUser();
  if (!user) return;

  // Add loading state
  const isSubmitting = ref(false);
  isSubmitting.value = true;

  try {
    // 1. Create the recommendation
    const { data: recommendation, error: recError } = await supabase
      .from('recommendations')
      .insert({
        list_id: selectedList.value,
        story_id: story.value.id,
        recommender_id: user.id,
        message: recommendationMessage.value || null
      })
      .select()
      .single();

    if (recError) {
      console.error('Error creating recommendation:', recError);
      throw recError;
    }

    // 2. Add story to the list if not already there
    const { error: listError } = await supabase
      .from('list_stories')
      .insert({
        list_id: selectedList.value,
        story_id: story.value.id,
        added_by: user.id
      })
      .select()  // Changed this part
      .single();

    if (listError && !listError.message.includes('duplicate')) { // Ignore duplicate errors
      console.error('Error adding story to list:', listError);
      throw listError;
    }

    // 3. Get all list members to notify
    const { data: listMembers, error: membersError } = await supabase
      .from('recommendation_list_items')
      .select('user_id')
      .eq('list_id', selectedList.value);

    if (membersError) {
      console.error('Error fetching list members:', membersError);
      throw membersError;
    }

    // 4. Create notifications for all members
    if (listMembers?.length) {
      const notifications = listMembers
        .filter(member => member.user_id !== user.id) // Don't notify the recommender
        .map(member => ({
          user_id: member.user_id,
          type: 'story_recommendation',
          message: `${user.user_metadata.username || user.email} recommended "${story.value.title}"`,
          link: `/story/${story.value.id}`,
          recommendation_id: recommendation.id,
          read: false,
          created_at: new Date().toISOString()
        }));

      if (notifications.length > 0) {
        const { error: notifyError } = await supabase
          .from('notifications')
          .insert(notifications);

        if (notifyError) {
          console.error('Error creating notifications:', notifyError);
          throw notifyError;
        }
      }
    }

    // Success! Clear the modal
    selectedList.value = null;
    recommendationMessage.value = '';
    showRecommendModal.value = false;

    // Show success alert
    alert('Story successfully recommended to list!');

  } catch (error) {
    console.error('Error in recommendation process:', error);
    alert('Failed to recommend story. Please try again.');
  } finally {
    isSubmitting.value = false;
  }
};

    const formatDate = (dateString) => {
      if (!dateString) return '';
      const options = { year: 'numeric', month: 'long', day: 'numeric' };
      return new Date(dateString).toLocaleDateString(undefined, options);
    };

    const navigateToChapter = (chapterId) => {
      if (story.value && story.value.id) {
        router.push(`/story/${story.value.id}/chapter/${chapterId}`);
      }
    };

    return {
      story,
      isInBookshelf,
      recommendedStories,
      showRecommendModal,
      selectedList,
      recommendationMessage,
      recommendationLists,
      hideActivity,
      submitRecommendation,
      handleSaveToBookshelf,
      navigateToChapter,
      toggleActivityVisibility,
      defaultAvatar,
      formatDate,
      isBlurbExpanded,
      currentReadingStatus,
      truncatedBio
    };
  }
};
</script>