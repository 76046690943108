// FollowModal.vue
<template>
  <div v-if="show" class="fixed inset-0 z-50 flex items-center justify-center">
    <!-- Backdrop -->
    <div class="absolute inset-0 bg-black bg-opacity-50" @click="$emit('close')"></div>
    
    <!-- Modal -->
    <div class="relative bg-white rounded-lg shadow-xl w-full max-w-md mx-4 max-h-[80vh] flex flex-col">
      <!-- Header -->
      <div class="px-6 py-4 border-b">
        <h3 class="text-xl font-semibold">{{ title }}</h3>
        <button 
          class="absolute top-4 right-4 text-gray-400 hover:text-gray-500"
          @click="$emit('close')"
        >
          <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
      </div>
      
      <!-- Content -->
      <div class="flex-1 overflow-y-auto px-6 py-4">
        <div v-if="loading" class="flex justify-center py-8">
          <div class="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500"></div>
        </div>
        
        <div v-else-if="error" class="text-red-500 text-center py-4">
          {{ error }}
        </div>
        
        <div v-else-if="users.length === 0" class="text-gray-500 text-center py-4">
          No {{ type }} found
        </div>
        
        <div v-else class="space-y-4">
          <div
            v-for="user in users"
            :key="user.id"
            class="flex items-center space-x-4 p-3 hover:bg-gray-50 rounded-lg transition-colors cursor-pointer"
            @click="navigateToProfile(user.username)"
          >
            <img
              :src="user.avatar_url || defaultAvatar"
              :alt="user.name"
              class="w-12 h-12 rounded-full object-cover"
              @error="handleAvatarError"
            />
            <div class="flex-1">
              <div class="font-medium">{{ user.name }}</div>
              <div class="text-sm text-gray-500">@{{ user.username }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, watch } from 'vue';
import { useRouter } from 'vue-router';
import { defineProps, defineEmits } from 'vue';
import { supabase } from '@/supabase';
import defaultAvatar from '@/assets/default-avatar-svg.png';

const props = defineProps({
  show: Boolean,
  type: {
    type: String,
    validator: (value) => ['followers', 'following'].includes(value)
  },
  userId: String,
  title: String
});

const emit = defineEmits(['close']);
const router = useRouter();

const users = ref([]);
const loading = ref(false);
const error = ref(null);

const fetchUsers = async () => {
  loading.value = true;
  error.value = null;
  
  try {
    let query;
    if (props.type === 'followers') {
      query = supabase
        .from('followers')
        .select(`
          follower:users!follower_id (
            id,
            name,
            username,
            avatar_url
          )
        `)
        .eq('followed_id', props.userId);
    } else {
      query = supabase
        .from('followers')
        .select(`
          followed:users!followed_id (
            id,
            name,
            username,
            avatar_url
          )
        `)
        .eq('follower_id', props.userId);
    }

    const { data, error: fetchError } = await query;
    
    if (fetchError) throw fetchError;
    
    users.value = data.map(item => props.type === 'followers' ? item.follower : item.followed);
  } catch (err) {
    console.error(`Error fetching ${props.type}:`, err);
    error.value = `Failed to load ${props.type}`;
  } finally {
    loading.value = false;
  }
};

const navigateToProfile = (username) => {
  emit('close');
  router.push(`/profile/${username}`);
};

const handleAvatarError = (e) => {
  e.target.src = defaultAvatar;
};

onMounted(() => {
  if (props.show) {
    fetchUsers();
  }
});

watch(() => props.show, (newValue) => {
  if (newValue) {
    fetchUsers();
  }
});
</script>