<template>
  <div class="min-h-screen bg-gradient-to-br from-gray-50 to-gray-100">
    <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
      <div class="flex flex-col lg:flex-row gap-8">
        <!-- Left Sidebar -->
        <div class="lg:w-1/4 space-y-6">
          <!-- Profile Card -->
          <div class="bg-white rounded-2xl shadow-xl overflow-hidden transition-all duration-300 hover:shadow-2xl">
            <div class="bg-gradient-to-r from-indigo-600 to-purple-600 h-48 relative">
              <img 
                :src="user.avatar_url || defaultAvatar" 
                :alt="user.name" 
                class="absolute left-1/2 transform -translate-x-1/2 -bottom-16 w-32 h-32 rounded-full border-4 border-white shadow-lg object-cover ring-4 ring-purple-100"
              />
            </div>
            <div class="pt-20 px-6 pb-8 text-center">
              <h1 class="text-2xl font-bold text-gray-900">{{ user.name }}</h1>
              <p class="text-purple-600 font-medium mt-1">@{{ user.username }}</p>
              <p class="text-gray-600 mt-2">{{ user.tagline }}</p>
              
              <!-- Stats Grid -->
              <div class="grid grid-cols-3 gap-4 mt-6 p-4 bg-gray-50 rounded-xl">
                <div class="cursor-pointer group" @click="viewFollowers">
                  <p class="font-bold text-lg text-gray-900 group-hover:text-purple-600 transition-colors">{{ user.followers }}</p>
                  <p class="text-sm text-gray-600">Followers</p>
                </div>
                <div class="cursor-pointer group" @click="viewFollowing">
                  <p class="font-bold text-lg text-gray-900 group-hover:text-purple-600 transition-colors">{{ user.following }}</p>
                  <p class="text-sm text-gray-600">Following</p>
                </div>
                <div>
                  <p class="font-bold text-lg text-gray-900">{{ user.stories }}</p>
                  <p class="text-sm text-gray-600">Stories</p>
                </div>
              </div>

              <!-- Action Buttons -->
              <div class="mt-6 space-y-3">
                <button 
                  @click="followUser" 
                  class="w-full px-6 py-3 rounded-xl font-medium text-white transition-all duration-300 shadow-lg hover:shadow-xl"
                  :class="isFollowing ? 'bg-gray-600 hover:bg-gray-700' : 'bg-gradient-to-r from-indigo-600 to-purple-600 hover:from-indigo-700 hover:to-purple-700'"
                >
                  {{ isFollowing ? 'Unfollow' : 'Follow' }}
                </button>
                <button 
                  @click="openMessageBox"
                  class="w-full px-6 py-3 bg-gradient-to-r from-green-600 to-teal-600 text-white font-medium rounded-xl hover:from-green-700 hover:to-teal-700 transition-all duration-300 shadow-lg hover:shadow-xl"
                >
                  Send Message
                </button>
              </div>
            </div>
          </div>

          <!-- Writing Stats Card -->
          <div class="bg-white rounded-2xl shadow-xl p-6 transition-all duration-300 hover:shadow-2xl">
            <h2 class="text-xl font-bold text-gray-900 mb-6 flex items-center">
              <span class="w-1.5 h-6 bg-gradient-to-b from-indigo-600 to-purple-600 rounded-lg mr-3"></span>
              Writing Stats
            </h2>
            <div class="space-y-6">
              <div class="flex justify-between items-center p-4 bg-gray-50 rounded-xl">
                <span class="text-gray-700 font-medium">Total Words</span>
                <span class="font-bold text-purple-600">{{ user.stats.totalWords.toLocaleString() }}</span>
              </div>
              <div class="flex justify-between items-center p-4 bg-gray-50 rounded-xl">
                <span class="text-gray-700 font-medium">Stories Published</span>
                <span class="font-bold text-purple-600">{{ user.stats.storiesPublished }}</span>
              </div>
              <div class="flex justify-between items-center p-4 bg-gray-50 rounded-xl">
                <span class="text-gray-700 font-medium">Total Chapters</span>
                <span class="font-bold text-purple-600">{{ user.stats.totalChapters }}</span>
              </div>
            </div>
          </div>
        </div>

        <!-- Main Content -->
        <div class="lg:flex-1">
          <div class="bg-white rounded-2xl shadow-xl overflow-hidden transition-all duration-300 hover:shadow-2xl">
            <!-- Tabs -->
            <div class="flex border-b bg-gray-50 p-1">
              <button
                v-for="tab in visibleTabs"
                :key="tab"
                @click="currentTab = tab"
                class="px-6 py-3 font-medium rounded-xl transition-all duration-300"
                :class="currentTab === tab ? 'bg-white text-purple-600 shadow-sm' : 'text-gray-600 hover:text-gray-900'"
              >
                {{ tab }}
              </button>
            </div>

            <!-- Content Area -->
            <div class="p-8">
              <!-- About Tab -->
              <div v-if="currentTab === 'About'" class="space-y-8">
                <!-- Bio Section -->
                <div class="bg-gray-50 rounded-2xl p-6">
                  <div class="flex justify-between items-center mb-4">
                    <h2 class="text-2xl font-bold text-gray-900">About Me</h2>
                    <button 
                      @click="toggleAboutMe" 
                      class="text-purple-600 hover:text-purple-700 font-medium transition-colors"
                    >
                      {{ showAboutMe ? 'Hide' : 'Show' }}
                    </button>
                  </div>
                  <p v-if="showAboutMe" class="text-gray-700 leading-relaxed">{{ user.bio }}</p>
                </div>

                <!-- Published Books Grid -->
                <div>
                  <h2 class="text-2xl font-bold text-gray-900 mb-6">Published Books</h2>
                  <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                    <div 
                      v-for="story in user.publishedStories" 
                      :key="story.id" 
                      class="group bg-gray-50 rounded-2xl overflow-hidden cursor-pointer transform transition-all duration-300 hover:-translate-y-1 hover:shadow-xl"
                      @click="viewBook(story.id)"
                    >
                      <div class="relative pt-[150%]">
                        <img 
                          :src="story.cover_image" 
                          :alt="story.title"
                          class="absolute inset-0 w-full h-full object-cover transform transition-transform duration-300 group-hover:scale-105"
                        />
                        <div class="absolute inset-0 bg-gradient-to-t from-black/60 via-black/20 to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-300"></div>
                      </div>
                      <div class="p-6">
                        <h3 class="font-bold text-lg mb-2 group-hover:text-purple-600 transition-colors">{{ story.title }}</h3>
                        <div class="flex items-center justify-between">
                          <span class="px-3 py-1 bg-purple-100 text-purple-600 rounded-full text-sm font-medium">
                            {{ story.genre }}
                          </span>
                          <div class="flex items-center text-gray-600">
                            <EyeIcon class="w-4 h-4 mr-1" />
                            <span class="text-sm">{{ story.reads.toLocaleString() }}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- Message Board -->
                <div>
                  <h2 class="text-2xl font-bold text-gray-900 mb-6">Message Board</h2>
                  
                  <!-- Pinned Announcements -->
                  <div v-if="user.pinnedAnnouncements.length > 0" class="mb-8">
                    <h3 class="text-xl font-bold text-gray-900 mb-4">Pinned Announcements</h3>
                    <div class="space-y-4">
                      <div 
                        v-for="announcement in user.pinnedAnnouncements" 
                        :key="announcement.id" 
                        class="bg-yellow-50 border border-yellow-200 p-6 rounded-xl"
                      >
                        <div v-html="announcement.content"></div>
                        <div class="mt-4 text-sm text-gray-600">{{ formatDate(announcement.created_at) }}</div>
                      </div>
                    </div>
                  </div>

                  <!-- New Message Form -->
                  <div class="mb-8">
                    <form @submit.prevent="addMessage" class="bg-gray-50 rounded-xl p-6">
                      <textarea
                        v-model="newMessage"
                        rows="3"
                        class="w-full border border-gray-200 rounded-xl p-4 focus:ring-2 focus:ring-purple-500 focus:border-transparent transition-all duration-300"
                        placeholder="Write a message..."
                      ></textarea>
                      <button
                        type="submit"
                        class="mt-4 px-6 py-3 bg-gradient-to-r from-indigo-600 to-purple-600 text-white font-medium rounded-xl hover:from-indigo-700 hover:to-purple-700 transition-all duration-300 shadow-lg hover:shadow-xl"
                      >
                        Post Message
                      </button>
                    </form>
                  </div>

                  <!-- Messages -->
                  <div class="space-y-6">
                    <div 
                      v-for="message in user.messages" 
                      :key="message.id" 
                      class="bg-gray-50 rounded-xl p-6 transition-all duration-300 hover:shadow-lg"
                    >
                      <div class="flex items-center mb-4">
                        <img 
                          :src="message.author.avatar_url || defaultAvatar" 
                          :alt="message.author.name"
                          class="w-12 h-12 rounded-full mr-4 object-cover ring-2 ring-purple-100"
                        />
                        <div>
                          <router-link 
                            :to="{ name: 'VisitedUserProfile', params: { username: message.author.username } }" 
                            class="font-bold text-lg hover:text-purple-600 transition-colors"
                          >
                            {{ message.author.name }}
                          </router-link>
                          <p class="text-gray-600 text-sm">{{ formatDate(message.created_at) }}</p>
                        </div>
                      </div>

                      <div v-html="message.content" class="text-gray-800 leading-relaxed mb-6"></div>

                      <!-- Replies -->
                      <div class="space-y-4 ml-12 border-l-2 border-purple-100 pl-6">
                        <div 
                          v-for="reply in message.replies" 
                          :key="reply.id" 
                          class="bg-white rounded-xl p-4 shadow-sm"
                        >
                          <div class="flex items-center mb-3">
                            <img 
                              :src="reply.author.avatar_url || defaultAvatar" 
                              :alt="reply.author.name"
                              class="w-8 h-8 rounded-full mr-3 object-cover ring-2 ring-purple-100"
                            />
                            <div>
                              <router-link 
                                :to="{ name: 'VisitedUserProfile', params: { username: reply.author.username } }" 
                                class="font-medium hover:text-purple-600 transition-colors"
                              >
                                {{ reply.author.name }}
                              </router-link>
                              <p class="text-gray-500 text-xs">{{ formatDate(reply.created_at) }}</p>
                            </div>
                          </div>
                          <p class="text-gray-700">{{ reply.content }}</p>
                        </div>
                      </div>

                      <!-- Reply Form -->
                      <div class="mt-6">
                        <button 
                          @click="showReplyForm(message.id)" 
                          class="text-purple-600 hover:text-purple-700 font-medium transition-colors"
                        >
                          Reply
                        </button>
                        <div v-if="replyingToMessageId === message.id" class="mt-4">
                          <textarea
                            v-model="newReply"
                            rows="3"
                            class="w-full border border-gray-200 rounded-xl p-4 focus:ring-2 focus:ring-purple-500 focus:border-transparent transition-all duration-300"
                            placeholder="Write a reply..."
                          ></textarea>
                          <button
                            @click="addReply(message.id)"
                            class="mt-3 px-6 py-2 bg-purple-600 text-white font-medium rounded-xl hover:bg-purple-700 transition-all duration-300"
                          >
                            Submit Reply
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Bookshelf Tab -->
              <div v-if="currentTab === 'Bookshelf'">
                <h2 class="text-2xl font-bold text-gray-900 mb-6">Bookshelf</h2>
                <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
                  <div 
                    v-for="book in user.bookshelves" 
                    :key="book.id" 
                    class="group bg-gray-50 rounded-2xl overflow-hidden transform transition-all duration-300 hover:-translate-y-1 hover:shadow-xl"
                  >
                    <div class="relative pt-[150%]">
                      <img 
                        :src="book.cover_image" 
                        :alt="book.title"
                        class="absolute inset-0 w-full h-full object-cover transform transition-transform duration-300 group-hover:scale-105"
                      />
                      <div class="absolute inset-0 bg-gradient-to-t from-black/60 via-black/20 to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-300"></div>
                    </div>
                    <div class="p-6">
                      <h3 class="font-bold text-lg mb-2 line-clamp-2 group-hover:text-purple-600 transition-colors">{{ book.title }}</h3>
                      <p class="text-gray-600 line-clamp-1">by {{ book.author }}</p>
                      <span 
                        class="mt-3 px-3 py-1 inline-block rounded-full text-sm font-medium"
                        :class="{
                          'bg-green-100 text-green-800': book.status === 'Read',
                          'bg-purple-100 text-purple-800': book.status === 'Currently Reading',
                          'bg-blue-100 text-blue-800': book.status === 'Want to Read'
                        }"
                      >
                        {{ book.status }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Activity Tab -->
              <div v-if="currentTab === 'Activity'" class="space-y-6">
                <h2 class="text-2xl font-bold text-gray-900 mb-6">Recent Activity</h2>
                <div class="space-y-4">
                  <div 
                    v-for="activity in user.activityFeed" 
                    :key="activity.id" 
                    class="group bg-gray-50 rounded-xl p-4 transition-all duration-300 hover:shadow-lg"
                  >
                    <div class="flex items-center">
                      <!-- Only show public activity types -->
                      <div class="p-2 rounded-xl mr-4" 
                        :class="{
                          'bg-blue-100': activity.type === 'comment',
                          'bg-green-100': activity.type === 'follow',
                          'bg-yellow-100': activity.type === 'story_published',
                          'bg-purple-100': activity.type === 'list_created'
                        }"
                      >
                        <MessageSquareIcon v-if="activity.type === 'comment'" class="w-5 h-5 text-blue-500" />
                        <UserPlusIcon v-if="activity.type === 'follow'" class="w-5 h-5 text-green-500" />
                        <BookIcon v-if="activity.type === 'story_published'" class="w-5 h-5 text-yellow-500" />
                        <ListIcon v-if="activity.type === 'list_created'" class="w-5 h-5 text-purple-500" />
                      </div>
                      <div class="flex-1">
                        <p class="text-gray-800">
                          {{ activity.description }}
                          <router-link :to="activity.link" class="font-medium text-purple-600 hover:text-purple-700 transition-colors">
                            {{ activity.linkText }}
                          </router-link>
                        </p>
                        <p class="text-gray-500 text-sm mt-1">{{ activity.time }}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
<FollowModal
  v-if="showFollowersModal"
  :show="showFollowersModal"
  type="followers"
  :userId="user.id"
  title="Followers"
  @close="showFollowersModal = false"
/>

<FollowModal
  v-if="showFollowingModal"
  :show="showFollowingModal"
  type="following"
  :userId="user.id"
  title="Following"
  @close="showFollowingModal = false"
/>
  </div>
</template>

<script>
import { ref, reactive, computed, onMounted, watch } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import FollowModal from './FollowModal.vue';
import { 
  EyeIcon, 
  MessageSquareIcon, 
  UserPlusIcon, 
  BookIcon,
  ListIcon 
} from 'lucide-vue-next';
import { supabase } from '@/supabase';
import defaultAvatar from '@/assets/default-avatar-svg.png';

export default {
  name: 'VisitedUserProfile',
  props: {
    username: {
      type: String,
      required: true
    }
  },
  components: {
    EyeIcon,
    MessageSquareIcon,
    UserPlusIcon,
    BookIcon,
    ListIcon,
    FollowModal
  },
  setup(props) {
    const router = useRouter();
    const store = useStore();
    const currentUser = computed(() => store.getters['auth/currentUser']);
    const isFollowing = ref(false);
    
    const user = reactive({
      id: '',
      username: '',
      name: '',
      tagline: '',
      avatar_url: '',
      bio: '',
      followers: 0,
      following: 0,
      stories: 0,
      stats: {
        totalWords: 0,
        storiesPublished: 0,
        totalChapters: 0
      },
      publishedStories: [],
      bookshelves: [],
      activityFeed: [],
      messages: [],
      pinnedAnnouncements: []
    });

    const visibleTabs = ref(['About', 'Bookshelf', 'Activity']);
    const currentTab = ref('About');
    const showAboutMe = ref(false);
    const replyingToMessageId = ref(null);
    const newReply = ref('');
    const newMessage = ref('');
    
    const showFollowersModal = ref(false);
    const showFollowingModal = ref(false);

    const fetchUserProfile = async () => {
      try {
        const { data, error } = await supabase
          .from('users')
          .select('*')
          .eq('username', props.username)
          .single();

        if (error) throw error;

        if (data) {
          Object.assign(user, data);
          await Promise.all([
            fetchFollowCounts(),
            fetchPublishedStories(),
            fetchBookshelves(),
            fetchActivityFeed(),
            fetchMessages(),
            fetchPinnedAnnouncements()
          ]);
        } else {
          throw new Error('User not found');
        }
      } catch (error) {
        console.error('Error fetching user profile:', error);
      }
    };

    const fetchPublishedStories = async () => {
      try {
        const { data, error } = await supabase
          .from('stories')
          .select(`
            id,
            title,
            blurb,
            genre,
            cover_image,
            reads,
            word_count,
            published_at,
            published,
            status
          `)
          .eq('author_id', user.id)
          .or('published.eq.true,status.eq.published')
          .order('published_at', { ascending: false });

        if (error) throw error;
        
        user.publishedStories = data.map(story => ({
          ...story,
          reads: story.reads || 0,
          description: story.blurb
        }));
        
        user.stats.storiesPublished = data.length;
        user.stats.totalWords = data.reduce((sum, story) => sum + (story.word_count || 0), 0);
      } catch (error) {
        console.error('Error fetching published stories:', error);
      }
    };

    const fetchBookshelves = async () => {
  try {
    const { data, error } = await supabase
      .from('bookshelf')
      .select(`
        id,
        status,
        stories (
          id,
          title,
          cover_image,
          users!author_id (username)
        )
      `)
      .eq('user_id', user.id)
      .eq('status', 'Currently Reading');

    if (error) throw error;
    
    user.bookshelves = data.map(item => ({
      id: item.stories.id,
      title: item.stories.title,
      author: item.stories.users.username,
      cover_image: item.stories.cover_image
    }));

    console.log('Currently reading books:', user.currentlyReading);
  } catch (error) {
    console.error('Error fetching currently reading books:', error);
  }
};

    const fetchActivityFeed = async () => {
      try {
        const activities = [];

        // Fetch story publications
        const { data: publications } = await supabase
          .from('stories')
          .select('id, title, published_at')
          .eq('author_id', user.id)
          .eq('published', true)
          .order('published_at', { ascending: false });

        if (publications) {
          publications.forEach(pub => {
            activities.push({
              id: `pub_${pub.id}`,
              type: 'story_published',
              description: 'Published a new story',
              linkText: pub.title,
              link: `/story/${pub.id}`,
              time: formatRelativeTime(pub.published_at)
            });
          });
        }

        // Fetch follows
        const { data: follows } = await supabase
          .from('followers')
          .select(`
            id,
            created_at,
            followed:followed_id(username)
          `)
          .eq('follower_id', user.id)
          .order('created_at', { ascending: false });

        if (follows) {
          follows.forEach(follow => {
            activities.push({
              id: `follow_${follow.id}`,
              type: 'follow',
              description: 'Started following',
              linkText: follow.followed.username,
              link: `/profile/${follow.followed.username}`,
              time: formatRelativeTime(follow.created_at)
            });
          });
        }

        user.activityFeed = activities
          .sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
          .slice(0, 10);

      } catch (error) {
        console.error('Error fetching activity feed:', error);
        user.activityFeed = [];
      }
    };

    const fetchMessages = async () => {
      try {
        const { data, error } = await supabase
          .from('profile_messages')
          .select(`
            id,
            content,
            created_at,
            author:users!author_id (
              id,
              name,
              username,
              avatar_url
            ),
            replies:profile_message_replies (
              id,
              content,
              created_at,
              author:users!author_id (
                id,
                name,
                username,
                avatar_url
              )
            )
          `)
          .eq('profile_id', user.id)
          .order('created_at', { ascending: false });

        if (error) throw error;
        user.messages = data;
      } catch (error) {
        console.error('Error fetching messages:', error);
      }
    };

    const fetchPinnedAnnouncements = async () => {
      try {
        const { data, error } = await supabase
          .from('announcements')
          .select('*')
          .eq('user_id', user.id)
          .eq('is_pinned', true)
          .order('created_at', { ascending: false });

        if (error) throw error;
        user.pinnedAnnouncements = data;
      } catch (error) {
        console.error('Error fetching pinned announcements:', error);
      }
    };

    const fetchFollowCounts = async () => {
  try {
    if (user && user.id) {
      const { count: followersCount, error: followersError } = await supabase
        .from('followers')
        .select('*', { count: 'exact' })
        .eq('followed_id', user.id);
      const { count: followingCount, error: followingError } = await supabase
        .from('followers')
        .select('*', { count: 'exact' })
        .eq('follower_id', user.id);
      if (followersError) throw followersError;
      if (followingError) throw followingError;
      user.followers = followersCount;
      user.following = followingCount;
    }
  } catch (error) {
    console.error('Error fetching follow counts:', error);
  }
};

    // Add visitor-specific functions
    const followUser = async () => {
      if (!currentUser.value) {
        router.push('/login');
        return;
      }

      try {
        if (isFollowing.value) {
          const { error } = await supabase
            .from('followers')
            .delete()
            .eq('follower_id', currentUser.value.id)
            .eq('followed_id', user.id);

          if (error) throw error;
          user.followers--;
        } else {
          const { error } = await supabase
            .from('followers')
            .insert({
              follower_id: currentUser.value.id,
              followed_id: user.id
            });

          if (error) throw error;
          user.followers++;
        }
        isFollowing.value = !isFollowing.value;
      } catch (error) {
        console.error('Error following/unfollowing user:', error);
      }
    };

    const openMessageBox = () => {
      if (!currentUser.value) {
        router.push('/login');
        return;
      }
      
      router.push({ 
        name: 'Messages', 
        params: { userId: user.id },
        query: { new: 'true' }
      });
    };

    // Keep shared functions
    const showReplyForm = (messageId) => {
      if (!currentUser.value) {
        router.push('/login');
        return;
      }
      replyingToMessageId.value = messageId;
    };

    const addReply = async (messageId) => {
      if (!currentUser.value || !newReply.value.trim()) return;

      try {
        const { data, error } = await supabase
          .from('profile_message_replies')
          .insert({
            message_id: messageId,
            author_id: currentUser.value.id,
            content: newReply.value
          })
          .select(`
            id,
            content,
            created_at,
            author:users!author_id (
              id,
              name,
              username,
              avatar_url
            )
          `)
          .single();

        if (error) throw error;

        const message = user.messages.find(m => m.id === messageId);
        if (message) {
          message.replies.push(data);
        }
        
        newReply.value = '';
        replyingToMessageId.value = null;
      } catch (error) {
        console.error('Error adding reply:', error);
      }
    };

    const addMessage = async () => {
      if (!currentUser.value || !newMessage.value.trim()) return;

      try {
        const { data, error } = await supabase
          .from('profile_messages')
          .insert({
            profile_id: user.id,
            author_id: currentUser.value.id,
            content: newMessage.value
          })
          .select(`
            id,
            content,
            created_at,
            author:users!author_id (
              id,
              name,
              username,
              avatar_url
            )
          `)
          .single();

        if (error) throw error;

        user.messages.unshift({
          ...data,
          replies: []
        });
        
        newMessage.value = '';
      } catch (error) {
        console.error('Error adding message:', error);
      }
    };

    const formatRelativeTime = (dateString) => {
      const date = new Date(dateString);
      const now = new Date();
      const diffInSeconds = Math.floor((now - date) / 1000);

      if (diffInSeconds < 60) return 'just now';
      if (diffInSeconds < 3600) return `${Math.floor(diffInSeconds / 60)}m ago`;
      if (diffInSeconds < 86400) return `${Math.floor(diffInSeconds / 3600)}h ago`;
      if (diffInSeconds < 604800) return `${Math.floor(diffInSeconds / 86400)}d ago`;
      return `${Math.floor(diffInSeconds / 604800)}w ago`;
    };

    const formatDate = (dateString) => {
      return new Date(dateString).toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
      });
    };

    const viewBook = (bookId) => {
      router.push({ name: 'StoryPage', params: { id: bookId } });
    };

    const viewFollowers = () => {
  showFollowersModal.value = true;
};

const viewFollowing = () => {
  showFollowingModal.value = true;
};

    const toggleAboutMe = () => {
      showAboutMe.value = !showAboutMe.value;
    };

    onMounted(async () => {
      await store.dispatch('auth/checkAuth');
      await fetchUserProfile();
      if (currentUser.value) {
        const { data } = await supabase
          .from('followers')
          .select()
          .eq('follower_id', currentUser.value.id)
          .eq('followed_id', user.id)
          .single();
        isFollowing.value = !!data;
      }
    });

    watch(() => props.username, fetchUserProfile);

    return {
      user,
      defaultAvatar,
      visibleTabs,
      currentTab,
      currentUser,
      showAboutMe,
      isFollowing,
      replyingToMessageId,
      newReply,
      newMessage,
      toggleAboutMe,
      followUser,
      openMessageBox,
      showReplyForm,
      addReply,
      addMessage,
      viewBook,
      viewFollowers,
      viewFollowing,
      formatDate,
      formatRelativeTime,
      showFollowersModal,
      showFollowingModal
    };
  }
};
</script>

<style scoped>
::v-deep .ql-editor {
  min-height: 100px;
  max-height: 200px;
  overflow-y: auto;
}

::v-deep .ql-toolbar {
  border-top-left-radius: 0.375rem;
  border-top-right-radius: 0.375rem;
}

::v-deep .ql-container {
  border-bottom-left-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem;
}

textarea:focus {
  outline: none;
  border-color: transparent;
  box-shadow: 0 0 0 2px rgba(124, 58, 237, 0.3);
}

.group:hover .group-hover\:scale-105 {
  transform: scale(1.05);
}
</style>