<template>
  <div v-if="loading" class="text-center py-8">
    <p class="text-2xl text-gray-600">Loading preview...</p>
  </div>

  <div v-else-if="error" class="text-center py-8">
    <p class="text-2xl text-red-600">{{ error }}</p>
    <button 
      @click="$router.back()" 
      class="mt-4 px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600"
    >
      Go Back
    </button>
  </div>

  <div v-else-if="chapter" class="chapter-page bg-gray-100 min-h-screen">
    <!-- Preview Banner -->
    <div class="bg-yellow-100 border-b border-yellow-200 p-4">
      <div class="max-w-4xl mx-auto flex justify-between items-center">
        <p class="text-yellow-700">
          <span class="font-bold">Preview Mode</span> - This is how your chapter will appear to readers
        </p>
        <button 
          @click="$router.push(`/preview/${storyId}`)" 
          class="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600"
        >
          Back to Story Preview
        </button>
      </div>
    </div>

    <header class="bg-gradient-to-r from-purple-600 to-indigo-600 text-white shadow-md p-4">
      <div class="max-w-4xl mx-auto flex justify-between items-center">
        <button @click="$router.push(`/preview/${storyId}`)" class="hover:text-gray-200 flex items-center">
          <ArrowLeftIcon class="w-6 h-6 mr-2" />
          <span class="text-lg font-semibold">{{ story.title }}</span>
        </button>
        <div class="relative">
          <button @click.stop="toggleChapterList" class="flex items-center text-lg font-semibold bg-white text-indigo-600 px-4 py-2 rounded-full hover:bg-gray-100 transition duration-300">
            {{ chapter.title }}
            <ChevronDownIcon class="ml-2 w-5 h-5" />
          </button>
          <div v-if="showChapterList" class="absolute top-full right-0 mt-2 w-64 bg-white rounded-md shadow-xl z-10">
            <div v-for="chap in story.chapters" :key="chap.id" 
                 @click="goToChapter(chap.id)"
                 class="p-2 hover:bg-gray-100 cursor-pointer">
              <div class="flex items-center">
                <span 
                  :class="[
                    'mr-2 px-2 py-0.5 rounded-full text-xs font-medium',
                    chap.published ? 'bg-green-100 text-green-800' : 'bg-yellow-100 text-yellow-800'
                  ]"
                >
                  {{ chap.published ? 'Published' : 'Draft' }}
                </span>
                <span class="text-gray-800">{{ chap.title }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>

    <main class="max-w-4xl mx-auto mt-8">
      <div class="bg-white shadow-lg rounded-lg overflow-hidden">
        <div class="p-6">
          <div class="flex justify-between items-center mb-4">
            <h1 class="text-3xl font-bold">{{ chapter.title }}</h1>
            <span 
              :class="[
                'px-3 py-1 rounded-full text-sm font-medium',
                chapter.published ? 'bg-green-100 text-green-800' : 'bg-yellow-100 text-yellow-800'
              ]"
            >
              {{ chapter.published ? 'Published' : 'Draft' }}
            </span>
          </div>

          <div class="prose max-w-none">
            <p v-for="(paragraph, index) in chapterParagraphs" :key="index" class="mb-4">
              {{ paragraph }}
            </p>
          </div>
        </div>

        <div class="bg-gray-50 p-4 flex justify-between items-center">
          <button 
            v-if="previousChapterId" 
            @click="goToChapter(previousChapterId)" 
            class="text-indigo-600 hover:text-indigo-800 flex items-center"
          >
            <ArrowLeftIcon class="w-5 h-5 mr-1" />
            Previous Chapter
          </button>
          <div v-else></div>

          <button 
            v-if="nextChapterId" 
            @click="goToChapter(nextChapterId)" 
            class="text-indigo-600 hover:text-indigo-800 flex items-center"
          >
            Next Chapter
            <ArrowRightIcon class="w-5 h-5 ml-1" />
          </button>
          <div v-else></div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import { ref, computed, onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { ArrowLeftIcon, ArrowRightIcon, ChevronDownIcon } from 'lucide-vue-next';
import { supabase } from '@/supabase';

export default {
  name: 'PreviewChapterPage',
  components: {
    ArrowLeftIcon,
    ArrowRightIcon,
    ChevronDownIcon,
  },

  setup() {
    const route = useRoute();
    const router = useRouter();
    const loading = ref(true);
    const error = ref(null);
    const showChapterList = ref(false);
    const story = ref(null);
    const chapter = ref(null);

    const storyId = route.params.storyId;
    const chapterId = route.params.chapterId;

    const chapterParagraphs = computed(() => {
      return chapter.value?.content?.split('\n\n') || [];
    });

    const previousChapterId = computed(() => {
      if (!story.value?.chapters) return null;
      const index = story.value.chapters.findIndex(c => c.id === chapter.value?.id);
      return index > 0 ? story.value.chapters[index - 1].id : null;
    });

    const nextChapterId = computed(() => {
      if (!story.value?.chapters) return null;
      const index = story.value.chapters.findIndex(c => c.id === chapter.value?.id);
      return index < story.value.chapters.length - 1 ? story.value.chapters[index + 1].id : null;
    });

    const fetchStoryAndChapter = async () => {
      try {
        const { data: { user } } = await supabase.auth.getUser();
        if (!user) {
          error.value = 'You must be logged in to preview chapters';
          return;
        }

        // Fetch story with all chapters
        const { data: storyData, error: storyError } = await supabase
          .from('stories')
          .select(`
            id,
            title,
            author_id,
            chapters(
              id,
              title,
              chapter_number,
              published
            )
          `)
          .eq('id', storyId)
          .eq('author_id', user.id)
          .single();

        if (storyError) throw storyError;
        if (!storyData) throw new Error('Story not found');

        // Sort chapters
        storyData.chapters.sort((a, b) => a.chapter_number - b.chapter_number);
        story.value = storyData;

        // Fetch full chapter data
        const { data: chapterData, error: chapterError } = await supabase
          .from('chapters')
          .select('*')
          .eq('id', chapterId)
          .eq('story_id', storyId)
          .single();

        if (chapterError) throw chapterError;
        if (!chapterData) throw new Error('Chapter not found');

        chapter.value = chapterData;

      } catch (err) {
        console.error('Error fetching preview:', err);
        error.value = 'Failed to load chapter preview';
      } finally {
        loading.value = false;
      }
    };

    const toggleChapterList = () => {
      showChapterList.value = !showChapterList.value;
    };

    const goToChapter = (newChapterId) => {
      router.push(`/preview/${storyId}/chapter/${newChapterId}`);
    };

    // Close dropdowns when clicking outside
    const handleClickOutside = () => {
  if (showChapterList.value) {
    showChapterList.value = false;
  }
};

    onMounted(() => {
      fetchStoryAndChapter();
      document.addEventListener('click', handleClickOutside);
    });

    return {
      loading,
      error,
      story,
      chapter,
      storyId,
      showChapterList,
      chapterParagraphs,
      previousChapterId,
      nextChapterId,
      toggleChapterList,
      goToChapter
    };
  }
};
</script>