<template>
  <div id="app">
    <nav v-if="isLoggedIn" class="bg-white shadow-md">
      <div class="container mx-auto px-4 py-4 flex justify-between items-center">
        <router-link to="/" class="text-2xl font-bold text-indigo-600">NarraNook</router-link>
        
        <!-- Mobile menu button with notification indicator -->
        <button @click="toggleMobileMenu" class="md:hidden relative">
          <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16m-7 6h7"></path>
          </svg>
          <span v-if="unreadNotifications || unreadMessages" class="absolute top-0 right-0 block h-2 w-2 rounded-full bg-red-400"></span>
        </button>

        <!-- Desktop menu -->
        <div class="hidden md:flex items-center space-x-4">
          <router-link to="/explore" class="text-gray-700 hover:text-indigo-600">Explore</router-link>
          <router-link to="/my-works" class="text-gray-700 hover:text-indigo-600">My Works</router-link>
          <router-link to="/groups" class="text-gray-700 hover:text-indigo-600">Groups</router-link>
          <router-link to="/challenges" class="text-gray-700 hover:text-indigo-600">Challenges</router-link>
          <router-link to="/bookshelf" class="text-gray-700 hover:text-indigo-600">Bookshelf</router-link>
          <button 
  @click.stop="toggleNotifications" 
  class="notification-button relative bg-white p-1 rounded-full text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
            <span class="sr-only">View notifications</span>
            <BellIcon class="h-6 w-6" />
            <span v-if="unreadNotifications" class="absolute top-0 right-0 block h-2 w-2 rounded-full bg-red-400 ring-2 ring-white"></span>
          </button>
          <router-link to="/messages" class="relative bg-white p-1 rounded-full text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
            <span class="sr-only">Messages</span>
            <MessageSquareIcon class="h-6 w-6" />
            <span v-if="unreadMessages" class="absolute top-0 right-0 block h-2 w-2 rounded-full bg-red-400 ring-2 ring-white"></span>
          </router-link>
          <div class="ml-3 relative">
            <div>
              <button 
  @click.stop="toggleUserMenu" 
  class="user-menu-button bg-white rounded-full flex text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500" 
  id="user-menu" 
  aria-haspopup="true">
  <span class="sr-only">Open user menu</span>
  <img 
  class="h-9 w-9 rounded-full object-cover" 
  :src="userAvatar" 
  alt=""
/>
</button>
            </div>
            <transition
              enter-active-class="transition ease-out duration-100"
              enter-from-class="transform opacity-0 scale-95"
              enter-to-class="transform opacity-100 scale-100"
              leave-active-class="transition ease-in duration-75"
              leave-from-class="transform opacity-100 scale-100"
              leave-to-class="transform opacity-0 scale-95"
            >
              <div v-if="showUserMenu" class="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5" role="menu" aria-orientation="vertical" aria-labelledby="user-menu">
                <router-link :to="{ name: 'CurrentUserProfile' }" class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" role="menuitem">
  Your Profile
</router-link>
                <router-link to="/settings" class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" role="menuitem">Settings</router-link>
                <router-link v-if="isAdmin" to="/admin" class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" role="menuitem">Admin Dashboard</router-link>
                <a @click="logout" href="#" class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" role="menuitem">Sign out</a>
              </div>
            </transition>
          </div>
        </div>
      </div>

      <!-- Mobile menu -->
      <div v-if="showMobileMenu" class="md:hidden">
        <div class="px-2 pt-2 pb-3 space-y-1 sm:px-3">
          <router-link @click="closeMobileMenu" to="/explore" class="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-indigo-600 hover:bg-gray-50">Explore</router-link>
          <router-link @click="closeMobileMenu" to="/my-works" class="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-indigo-600 hover:bg-gray-50">My Works</router-link>
          <router-link @click="closeMobileMenu" to="/groups" class="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-indigo-600 hover:bg-gray-50">Groups</router-link>
          <router-link @click="closeMobileMenu" to="/challenges" class="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-indigo-600 hover:bg-gray-50">Challenges</router-link>
          <router-link @click="closeMobileMenu" to="/bookshelf" class="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-indigo-600 hover:bg-gray-50">Bookshelf</router-link>
          <router-link @click="closeMobileMenu" to="/messages" class="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-indigo-600 hover:bg-gray-50">
            Messages
            <span v-if="unreadMessages" class="ml-2 inline-block h-2 w-2 rounded-full bg-red-400"></span>
          </router-link>
          <button @click.stop="toggleNotifications" class="w-full text-left px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-indigo-600 hover:bg-gray-50">
            Notifications
            <span v-if="unreadNotifications" class="ml-2 inline-block h-2 w-2 rounded-full bg-red-400"></span>
          </button>
          <router-link @click="closeMobileMenu" to="/profile" class="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-indigo-600 hover:bg-gray-50">Your Profile</router-link>
          <router-link @click="closeMobileMenu" to="/settings" class="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-indigo-600 hover:bg-gray-50">Settings</router-link>
          <router-link v-if="isAdmin" @click="closeMobileMenu" to="/admin" class="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-indigo-600 hover:bg-gray-50">Admin Dashboard</router-link>
          <button @click="logoutAndCloseMobileMenu" class="w-full text-left px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-indigo-600 hover:bg-gray-50">Sign out</button>
        </div>
      </div>
    </nav>

    <router-view></router-view>

    <!-- Notification Panel -->
<div v-if="showNotifications" class="notification-panel fixed top-16 right-4 mt-2 w-96 bg-white rounded-lg shadow-lg z-50 max-h-[80vh] overflow-y-auto">
  <div class="p-4">
    <h3 class="text-lg font-semibold mb-2">Notifications ({{ notifications.length }})</h3>
    <p v-if="notificationError" class="text-red-500 mb-2">{{ notificationError }}</p>
    <pre v-if="notifications.length === 0" class="text-sm text-gray-500">No notifications</pre>
    <ul v-else class="space-y-2">
      <li 
        v-for="notification in notifications" 
        :key="notification.id" 
        class="flex items-start space-x-2 p-2 hover:bg-gray-100 rounded cursor-pointer"
      >
        <span class="mt-1">
          <MessageSquareIcon v-if="notification.type === NOTIFICATION_TYPES.COMMENT" class="w-5 h-5 text-blue-500" />
          <HeartIcon v-if="notification.type === NOTIFICATION_TYPES.APPLAUSE" class="w-5 h-5 text-red-500" />
          <UserPlusIcon v-if="notification.type === NOTIFICATION_TYPES.FOLLOW" class="w-5 h-5 text-indigo-500" />
          <AtSignIcon v-if="notification.type === NOTIFICATION_TYPES.MENTION" class="w-5 h-5 text-yellow-500" />
          <UsersIcon v-if="notification.type === NOTIFICATION_TYPES.ANNOUNCEMENT" class="w-5 h-5 text-purple-500" />
          <MessageCircleIcon v-if="notification.type === NOTIFICATION_TYPES.GROUP_COMMENT" class="w-5 h-5 text-orange-500" />
          <UserIcon v-if="notification.type === NOTIFICATION_TYPES.RL_INVITE" class="w-5 h-5 text-green-500" />
          <CheckIcon v-if="notification.type === NOTIFICATION_TYPES.RL_ACCEPT" class="w-5 h-5 text-green-500" />
          <BookIcon v-if="notification.type === NOTIFICATION_TYPES.RECOMMENDATION" class="w-5 h-5 text-blue-500" />
          <PenToolIcon v-if="notification.type === NOTIFICATION_TYPES.NEW_CHAPTER" class="w-5 h-5 text-cyan-500" />
          <TrophyIcon v-if="notification.type === NOTIFICATION_TYPES.CHALLENGE_WINNER" class="w-5 h-5 text-yellow-500" />
          <FlagIcon v-if="notification.type === NOTIFICATION_TYPES.CHALLENGE_INVITE" class="w-5 h-5 text-pink-500" />
          <BellIcon v-if="notification.type === NOTIFICATION_TYPES.ADMIN_BROADCAST" class="w-5 h-5 text-red-500" />
        </span>
        <div class="flex-grow">
          <p class="text-sm" :class="{ 'font-semibold': !notification.read }">
            {{ notification.type === 'rl_invite' 
  ? `@${notification.recommendation_list_invites.users.username} invited you to join their recommendation list: ${notification.recommendation_list_invites.recommendation_lists.title}`
  : notification.message 
}}
          </p>
          <p class="text-xs text-gray-500">{{ formatTime(notification.created_at) }}</p>
        </div>
        <div v-if="notification.type === NOTIFICATION_TYPES.RL_INVITE" class="flex space-x-2">
          <button @click.stop="acceptInvite(notification)" class="text-green-500 hover:text-green-700">
            <CheckIcon class="w-5 h-5" />
          </button>
          <button @click.stop="rejectInvite(notification)" class="text-red-500 hover:text-red-700">
            <XIcon class="w-5 h-5" />
          </button>
        </div>
        <div v-else-if="notification.link" @click="handleNotificationClick(notification)" class="w-full h-full absolute top-0 left-0"></div>
      </li>
    </ul>
  </div>
</div>
  </div>
</template>

<script>
import { ref, computed, onMounted, onUnmounted } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { formatDistanceToNow } from 'date-fns';
import defaultAvatar from '@/assets/default-avatar-svg.png';
import { supabase } from '@/supabase';
import { onBeforeMount } from 'vue';
import { refreshSupabaseSchema } from './supabase';
import { eventBus } from '@/eventBus';
import debounce from 'lodash/debounce';
import { 
  BellIcon, 
  MessageSquareIcon, 
  HeartIcon, 
  UserPlusIcon, 
  AtSignIcon, 
  UsersIcon, 
  MessageCircleIcon,
  UserIcon,
  CheckIcon,
  BookIcon,
  PenToolIcon,
  TrophyIcon,
  FlagIcon,
  XIcon
} from 'lucide-vue-next';

const NOTIFICATION_TYPES = {
  COMMENT: 'comment',
  APPLAUSE: 'applause',
  FOLLOW: 'follow',
  MENTION: 'mention',
  ANNOUNCEMENT: 'announcement',
  GROUP_COMMENT: 'group_comment',
  RL_INVITE: 'rl_invite',
  RL_ACCEPT: 'rl_accept',
  RECOMMENDATION: 'recommendation',
  NEW_CHAPTER: 'new_chapter',
  CHALLENGE_INVITE: 'challenge_invite',
  CHALLENGE_WINNER: 'challenge_winner',
  ADMIN_BROADCAST: 'admin_broadcast'
};

export default {
  name: 'App',
  components: {
    BellIcon,
    MessageSquareIcon,
    HeartIcon,
    UserPlusIcon,
    AtSignIcon,
    UsersIcon,
    MessageCircleIcon,
    UserIcon,
    CheckIcon,
    BookIcon,
    PenToolIcon,
    TrophyIcon,
    FlagIcon,
    XIcon
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const notificationError = ref(null);
    const activeSubscriptions = ref([]);
    const isPageVisible = ref(true);
    const updateInterval = ref(null);
    const authListener = ref(null);
    const subscriptionStatus = ref({});
    const user = ref(null);

    eventBus.on('logged-out', () => {
      router.push('/auth')
    });

    const isLoggedIn = computed(() => store.getters['auth/isLoggedIn']);
    const isAdmin = computed(() => {
      const adminStatus = store.getters['auth/isAdmin'];
      console.log('Is admin in App.vue:', adminStatus);
      console.log('Current user in App.vue:', store.getters['auth/currentUser']);
      return adminStatus;
    });

    const userAvatar = computed(() => user.value?.avatar_url || defaultAvatar);

    const showUserMenu = ref(false);
    const showNotifications = ref(false);
    const showMobileMenu = ref(false);
    const notifications = ref([]);
    const unreadNotifications = computed(() => notifications.value.some(n => !n.read));
    const unreadMessages = ref(false);
    const pendingInvitesCount = ref(0);
    const isReconnecting = ref(false);
const lastActiveTimestamp = ref(Date.now());

    const handleVisibilityChange = async () => {
  const wasVisible = isPageVisible.value;
  isPageVisible.value = document.visibilityState === 'visible';

  if (!wasVisible && isPageVisible.value) {
    isReconnecting.value = true;
    const timeSinceLastActive = Date.now() - lastActiveTimestamp.value;

    // Force full reconnection if been inactive for over 5 minutes
    if (timeSinceLastActive > 300000) {
      await supabase.removeAllChannels();
      window.location.reload();
      return;
    }

    try {
      await setupRealTimeSubscriptions();
      await debouncedFetchNotifications();
      await debouncedCheckUnreadMessages();
      await fetchPendingInvitesCount();
    } finally {
      isReconnecting.value = false;
      lastActiveTimestamp.value = Date.now();
    }
  } else if (!isPageVisible.value) {
    lastActiveTimestamp.value = Date.now();
  }
};

    const setupRealTimeSubscriptions = async () => {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) return;

      // Clean up existing subscriptions
      activeSubscriptions.value.forEach(sub => {
  if (sub && sub.subscription) {
    supabase.removeChannel(sub);
  }
});
      activeSubscriptions.value = [];
      subscriptionStatus.value = {};

      const notificationsSub = supabase
        .channel('notifications')
        .on('postgres_changes', 
          { 
            event: '*', 
            schema: 'public', 
            table: 'notifications', 
            filter: `user_id=eq.${user.id}` 
          },
          debouncedFetchNotifications
        )
        .subscribe((status) => {
          subscriptionStatus.value['notifications'] = status;
          console.log('Notifications subscription status:', status);
        });

      const messagesSub = supabase
        .channel('messages')
        .on('postgres_changes', 
          { 
            event: '*', 
            schema: 'public', 
            table: 'messages', 
            filter: `recipient_id=eq.${user.id}` 
          },
          debouncedCheckUnreadMessages
        )
        .subscribe((status) => {
          subscriptionStatus.value['messages'] = status;
          console.log('Messages subscription status:', status);
        });

      const invitesSub = supabase
        .channel('invites')
        .on('postgres_changes', 
          { 
            event: '*', 
            schema: 'public', 
            table: 'recommendation_list_invites', 
            filter: `user_id=eq.${user.id}` 
          },
          (payload) => {
            console.log('Invite change detected:', payload);
            debouncedFetchNotifications();
            fetchPendingInvitesCount();
          }
        )
        .subscribe((status) => {
          subscriptionStatus.value['invites'] = status;
          console.log('Invites subscription status:', status);
        });

      activeSubscriptions.value = [notificationsSub, messagesSub, invitesSub];
    };

    const debouncedFetchNotifications = debounce(async () => {
      if (!isLoggedIn.value || !isPageVisible.value) return;
      await fetchNotifications();
    }, 1000);

    const debouncedCheckUnreadMessages = debounce(async () => {
      if (!isLoggedIn.value || !isPageVisible.value) return;
      await checkUnreadMessages();
    }, 1000);

    const initializeAuth = async () => {
      const { data: { session } } = await supabase.auth.getSession()
      if (session) {
        store.commit('auth/setUser', session.user)
      }

      supabase.auth.onAuthStateChange((event, session) => {
        if (event === 'SIGNED_IN') {
          store.commit('auth/setUser', session.user)
        } else if (event === 'SIGNED_OUT') {
          store.commit('auth/setUser', null)
        }
      })
    }

    onBeforeMount(async () => {
      await refreshSupabaseSchema()
      await initializeAuth()
    })

    const profileRoute = computed(() => {
      const user = store.getters['auth/currentUser'];
      console.log('Navbar profileRoute, currentUser:', user);
      return user ? '/profile' : '/';
    });

    const toggleUserMenu = () => {
      showUserMenu.value = !showUserMenu.value;
    };

    const closeUserMenu = () => {
      showUserMenu.value = false;
    };

    const toggleNotifications = () => {
      showNotifications.value = !showNotifications.value;
    };

    const closeNotifications = (event) => {
      if (!event.target.closest('.notification-panel') && !event.target.closest('button')) {
        showNotifications.value = false;
      }
    };

    const handleNotificationClick = async (notification) => {
      if (!notification.read) {
        await markNotificationAsRead(notification.id);
      }
      router.push(notification.link);
      showNotifications.value = false;
    };

    const formatTime = (timestamp) => {
      return formatDistanceToNow(new Date(timestamp), { addSuffix: true });
    };

    const toggleMobileMenu = () => {
      showMobileMenu.value = !showMobileMenu.value;
      if (showMobileMenu.value) {
        showUserMenu.value = false;
        showNotifications.value = false;
      }
    };

    const closeMobileMenu = () => {
      showMobileMenu.value = false;
    };

    const logoutAndCloseMobileMenu = async () => {
      await logout();
      closeMobileMenu();
    };

    const logout = async () => {
      try {
        await store.dispatch('auth/logout');
        store.dispatch('auth/forceLogout');
        router.push('/auth');
        window.location.reload();
      } catch (error) {
        console.error('Logout failed:', error);
      }
    };

    const closeMenus = (event) => {
  // Close user menu if click is outside menu and menu button
  if (!event.target.closest('#user-menu') && !event.target.closest('.user-menu-button')) {
    showUserMenu.value = false;
  }

  // Close notifications if click is outside panel and notification button
  if (!event.target.closest('.notification-panel') && !event.target.closest('.notification-button')) {
    showNotifications.value = false;
  }
};

    const fetchNotifications = async () => {
      console.log('Fetching notifications...');
      try {
        const { data: { user }, error: authError } = await supabase.auth.getUser();
        if (authError) {
          console.error('Auth error:', authError);
          throw authError;
        }
        console.log('Current user:', user);
        if (!user) throw new Error('No user logged in');

        console.log('Querying notifications for user:', user.id);
        const { data, error: fetchError } = await supabase
  .from('notifications')
  .select(`
    *,
    recommendation_list_invites (
      id,
      status,
      recommendation_lists (title),
      users!inviter_id (username)
    )
  `)
  .eq('user_id', user.id)
  .order('created_at', { ascending: false });

        if (fetchError) {
          console.error('Supabase error:', fetchError);
          throw fetchError;
        }

        console.log('Fetched notifications:', data);
        notifications.value = data.map(notification => ({
          ...notification,
          invite_id: notification.recommendation_list_invites?.id,
          invite_status: notification.recommendation_list_invites?.status
        }));
      } catch (err) {
        console.error('Error fetching notifications:', err);
        notificationError.value = 'Failed to fetch notifications: ' + (err.message || 'Unknown error');
      }
    };

    const fetchPendingInvitesCount = async () => {
      const { data: { user } } = await supabase.auth.getUser();
      if (user) {
        const { count, error } = await supabase
          .from('recommendation_list_invites')
          .select('id', { count: 'exact' })
          .eq('user_id', user.id)
          .eq('status', 'pending');

        if (error) {
          console.error('Error fetching pending invites count:', error);
        } else {
          pendingInvitesCount.value = count;
        }
      }
    };

    const handleInviteAction = async (notification, action) => {
      try {
        console.log(`${action}ing invite with ID:`, notification.invite_id);
        const { data, error } = await supabase.rpc(
          `${action}_recommendation_list_invite`,
          { p_invite_id: notification.invite_id }
        );
        
        if (error) {
          console.error('Supabase RPC error:', error);
          throw error;
        }

        console.log('Supabase RPC response:', data);
        if (data === true) {
          notifications.value = notifications.value.filter(n => n.id !== notification.id);
          pendingInvitesCount.value = Math.max(0, pendingInvitesCount.value - 1);
          
          eventBus.emit('show-toast', {
            type: 'success',
            message: `Successfully ${action}ed invitation`
          });

          // Refresh data
          debouncedFetchNotifications();
          fetchPendingInvitesCount();
        } else {
          throw new Error(`Invite ${action} failed on the server`);
        }
      } catch (err) {
        console.error(`Error ${action}ing invite:`, err);
        notificationError.value = `Failed to ${action} invite: ${err.message || 'Unknown error'}`;
        eventBus.emit('show-toast', {
          type: 'error',
          message: `Failed to ${action} invitation`
        });
      }
    };

    const acceptInvite = (notification) => handleInviteAction(notification, 'accept');
    const rejectInvite = (notification) => handleInviteAction(notification, 'reject');

    const checkUnreadMessages = async () => {
      const user = store.getters['auth/currentUser'];
      if (user) {
        const { count, error } = await supabase
          .from('messages')
          .select('*', { count: 'exact', head: true })
          .eq('recipient_id', user.id)
          .eq('read', false);

        if (error) {
          console.error('Error checking unread messages:', error);
        } else {
          unreadMessages.value = count > 0;
        }
      }
    };

    const markNotificationAsRead = async (notificationId) => {
      const { error } = await supabase
        .from('notifications')
        .update({ read: true })
        .eq('id', notificationId);

      if (error) {
        console.error('Error marking notification as read:', error);
      } else {
        const index = notifications.value.findIndex(n => n.id === notificationId);
        if (index !== -1) {
          notifications.value[index].read = true;
        }
      }
    };

    onMounted(async () => {
      const { data: { user: authUser } } = await supabase.auth.getUser();
  if (authUser) {
    const { data: dbUser } = await supabase
      .from('users')
      .select('id, avatar_url, name')
      .eq('id', authUser.id)
      .single();
    user.value = dbUser;
  }

      document.addEventListener('mousedown', closeMenus);
      const isAuthenticated = await store.dispatch('auth/checkAuth');
      console.log('Is authenticated:', isAuthenticated);
      if (isAuthenticated) {
        console.log('Current user after checkAuth:', store.getters['auth/currentUser']);
        console.log('Is admin after checkAuth:', store.getters['auth/isAdmin']);
      }

      if (!isAuthenticated && router.currentRoute.value.meta.requiresAuth) {
        router.push('/auth');
      }

      document.addEventListener('visibilitychange', handleVisibilityChange);
      
      authListener.value = supabase.auth.onAuthStateChange(async (event, session) => {
        console.log('Auth state changed:', event);
        if (event === 'SIGNED_IN') {
          await store.dispatch('auth/setUser', session.user);
          console.log('User set after sign in:', store.getters['auth/currentUser']);
          console.log('Is admin after sign in:', store.getters['auth/isAdmin']);
          await setupRealTimeSubscriptions();
          if (router.currentRoute.value.path === '/auth') {
            router.push('/dashboard');
          }
        } else if (event === 'SIGNED_OUT') {
          await store.dispatch('auth/logout');
          activeSubscriptions.value.forEach(sub => supabase.removeChannel(sub));
          activeSubscriptions.value = [];
          if (router.currentRoute.value.meta.requiresAuth) {
            router.push('/auth');
          }
        }
      });

      document.addEventListener('click', closeNotifications);
      
      if (isLoggedIn.value) {
        await setupRealTimeSubscriptions();
        await debouncedFetchNotifications();
        await debouncedCheckUnreadMessages();
        await fetchPendingInvitesCount();
      }

      updateInterval.value = setInterval(() => {
        if (isLoggedIn.value && isPageVisible.value) {
          debouncedFetchNotifications();
          debouncedCheckUnreadMessages();
          fetchPendingInvitesCount();
        }
      }, 60000);
   }); 

    
    onUnmounted(() => {
        document.removeEventListener('visibilitychange', handleVisibilityChange);
        document.removeEventListener('click', closeNotifications);
        activeSubscriptions.value.forEach(sub => {
          if (subscriptionStatus.value[sub.subscription.topic] === 'SUBSCRIBED') {
            supabase.removeChannel(sub);
          }
        });
        clearInterval(updateInterval.value);
        authListener.value.unsubscribe()
        debouncedFetchNotifications.cancel();
        debouncedCheckUnreadMessages.cancel();
      });

    return {
      isLoggedIn,
      userAvatar,
      defaultAvatar,
      showUserMenu,
      showNotifications,
      showMobileMenu,
      notifications,
      unreadNotifications,
      unreadMessages,
      pendingInvitesCount,
      profileRoute,
      toggleUserMenu,
      closeUserMenu,
      toggleNotifications,
      toggleMobileMenu,
      closeMobileMenu,
      logoutAndCloseMobileMenu,
      handleNotificationClick,
      logout,
      formatTime,
      notificationError,
      isAdmin,
      NOTIFICATION_TYPES,
      acceptInvite,
      rejectInvite
    };
  }
};
</script>

<style>
.page-content {
  position: relative;
  z-index: 1;
}

.notification-panel {
  z-index: 40 !important;
}

.user-menu {
  z-index: 50 !important;
}

.mobile-menu {
  z-index: 30 !important;
}

@media (max-width: 768px) {
  .notification-panel {
    width: 95%;
    left: 2.5%;
    right: 2.5%;
  }
}
</style>