<template>
  <div v-if="loading" class="min-h-screen flex items-center justify-center bg-gray-100">
    <div class="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
  </div>

  <div v-else-if="error" class="min-h-screen flex items-center justify-center bg-gray-100 p-4">
    <div class="text-center text-red-600">{{ error }}</div>
  </div>

  <div v-else-if="story" class="edit-story-details bg-gray-100 min-h-screen p-8">
    <div class="max-w-4xl mx-auto bg-white rounded-lg shadow-md overflow-hidden">
      <div class="bg-gradient-to-r from-blue-500 to-purple-600 p-6 text-white">
        <h1 class="text-3xl font-bold">Edit Story: {{ story.title }}</h1>
      </div>

      <form @submit.prevent="handleSubmit" class="p-6 space-y-6">
        <!-- Cover Image Section -->
<div class="border rounded-lg p-4">
  <h2 class="text-lg font-semibold mb-4">Cover Image</h2>
  <div class="flex flex-col md:flex-row gap-4">
    <!-- Current Cover Preview -->
    <div class="w-full md:w-1/3">
      <div class="aspect-[2/3] bg-gray-100 rounded-lg overflow-hidden">
        <img 
          :src="coverPreview || story.cover_image" 
          alt="Cover"
          class="w-full h-full object-cover"
        >
      </div>
    </div>
            
            <!-- Upload Section -->
            <div class="w-full md:w-2/3">
              <input 
                type="file" 
                @change="handleCoverChange" 
                accept="image/jpeg, image/png"
                class="block w-full text-sm text-gray-500 mb-4
                  file:mr-4 file:py-2 file:px-4
                  file:rounded-md file:border-0
                  file:text-sm file:font-semibold
                  file:bg-blue-50 file:text-blue-700
                  hover:file:bg-blue-100"
              >
              <div class="text-sm text-gray-600 space-y-1">
                <p class="font-medium">Cover Image Requirements:</p>
                <ul class="list-disc ml-4 space-y-1">
                  <li>File format: JPG or PNG</li>
                  <li>Maximum size: 10MB</li>
                  <li>Recommended dimensions: 1600 × 2400 pixels</li>
                  <li>Aspect ratio: 2:3 (width:height)</li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <!-- Rest of the form fields -->
        <div class="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div>
            <label class="block text-gray-700 text-sm font-bold mb-2" for="title">Title</label>
            <input 
              v-model="story.title" 
              id="title" 
              type="text" 
              class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              required
            >
          </div>

          <div>
            <label class="block text-gray-700 text-sm font-bold mb-2" for="genre">Genre</label>
            <select 
              v-model="story.genre" 
              id="genre" 
              class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              required
            >
              <option value="">Select a genre</option>
              <option v-for="genre in genres" :key="genre" :value="genre">{{ genre }}</option>
            </select>
          </div>

          <div>
            <label class="block text-gray-700 text-sm font-bold mb-2" for="targetAudience">Target Audience</label>
            <select 
              v-model="story.target_audience" 
              id="targetAudience" 
              class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              required
            >
              <option value="">Select target audience</option>
              <option value="Children">Children</option>
              <option value="Young Adult">Young Adult</option>
              <option value="Adult">Adult</option>
              <option value="All Ages">All Ages</option>
            </select>
          </div>

          <div>
            <label class="block text-gray-700 text-sm font-bold mb-2" for="copyright">Copyright</label>
            <select 
              v-model="story.copyright" 
              id="copyright" 
              class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              required
            >
              <option value="">Select copyright status</option>
              <option value="All Rights Reserved">All Rights Reserved</option>
              <option value="Creative Commons">Creative Commons</option>
              <option value="Public Domain">Public Domain</option>
            </select>
          </div>
        </div>

        <div>
          <label class="block text-gray-700 text-sm font-bold mb-2" for="tags">Tags (comma-separated)</label>
          <input 
            v-model="tagsString" 
            id="tags" 
            type="text" 
            class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          >
        </div>

        <div>
          <label class="block text-gray-700 text-sm font-bold mb-2" for="blurb">Blurb</label>
          <textarea 
            v-model="story.blurb" 
            id="blurb" 
            rows="4"
            class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            required
          ></textarea>
        </div>

        <div class="flex items-center">
          <input 
            type="checkbox" 
            id="isAdult" 
            v-model="story.is_adult"
            class="rounded border-gray-300 text-blue-600 focus:ring-blue-500"
          >
          <label for="isAdult" class="ml-2 text-sm text-gray-900">
            18+ Content
          </label>
        </div>

        <div class="flex justify-between">
          <button 
            type="button"
            @click="$router.push(`/story/${story.id}/details`)" 
            class="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          >
            Cancel
          </button>
          <button 
            type="submit"
            :disabled="updating"
            class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline disabled:opacity-50"
          >
            {{ updating ? 'Updating...' : 'Update Story' }}
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { ref, computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { supabase } from '@/supabase';
import { useToast } from "vue-toastification";
import { useStore } from 'vuex';

export default {
  name: 'EditStoryDetails',
  
  setup() {
    const route = useRoute();
    const router = useRouter();
    const toast = useToast();
    const store = useStore();
    
    const story = ref(null);
    const loading = ref(true);
    const updating = ref(false);
    const error = ref(null);
    const newCover = ref(null);

    const genres = [
      'Fantasy', 'Science Fiction', 'Romance', 'Mystery', 'Thriller',
      'Urban', 'Poetry', 'Horror', 'Historical Fiction', 'Non-fiction'
    ];

    const tagsString = computed({
      get: () => story.value?.tags?.join(', ') || '',
      set: (val) => {
        if (story.value) {
          story.value.tags = val.split(',').map(tag => tag.trim()).filter(tag => tag);
        }
      }
    });

    const handleCoverChange = (event) => {
      const file = event.target.files[0];
      if (!file) return;
      
      if (!['image/jpeg', 'image/png'].includes(file.type)) {
        toast.error('Please upload a JPG or PNG file');
        return;
      }
      
      if (file.size > 10 * 1024 * 1024) {  // 10MB limit
        toast.error('File size must be less than 10MB');
        return;
      }
      
      newCover.value = file;
    };

    const uploadCover = async () => {
      if (!newCover.value) return null;

      const user = store.getters['auth/currentUser'];
      if (!user) throw new Error('User not authenticated');

      const fileExt = newCover.value.name.split('.').pop();
      const fileName = `${Math.random().toString(36).substring(2)}.${fileExt}`;
      const filePath = `${user.id}/${fileName}`;

      const { error: uploadError } = await supabase.storage
        .from('story-covers')
        .upload(filePath, newCover.value);

      if (uploadError) throw uploadError;

      const { data } = supabase.storage
        .from('story-covers')
        .getPublicUrl(filePath);

      return data.publicUrl;
    };

    const fetchStory = async () => {
      try {
        const { data, error: fetchError } = await supabase
          .from('stories')
          .select('*')
          .eq('id', route.params.id)
          .single();

        if (fetchError) throw fetchError;
        story.value = data;
      } catch (e) {
        console.error('Error fetching story:', e);
        error.value = 'Failed to load story details';
      } finally {
        loading.value = false;
      }
    };

    const handleSubmit = async () => {
      updating.value = true;
      try {
        let coverUrl = story.value.cover_image;
        
        if (newCover.value) {
          coverUrl = await uploadCover();
        }

        const { error: updateError } = await supabase
          .from('stories')
          .update({
            title: story.value.title,
            blurb: story.value.blurb,
            genre: story.value.genre,
            target_audience: story.value.target_audience,
            copyright: story.value.copyright,
            is_adult: story.value.is_adult,
            tags: story.value.tags,
            cover_image: coverUrl
          })
          .eq('id', story.value.id);

        if (updateError) throw updateError;
        
        toast.success('Story updated successfully');
        router.push(`/story/${story.value.id}/details`);
      } catch (e) {
        console.error('Error updating story:', e);
        toast.error('Failed to update story');
      } finally {
        updating.value = false;
      }
    };

    // Fetch story on component mount
    fetchStory();

    return {
      story,
      loading,
      updating,
      error,
      genres,
      tagsString,
      handleCoverChange,
      handleSubmit
    };
  }
};
</script>