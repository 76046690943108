<template>
  <div class="min-h-screen bg-gradient-to-br from-blue-50 to-indigo-50 p-4 sm:p-6">
    <div class="max-w-7xl mx-auto bg-white rounded-2xl shadow-xl overflow-hidden border border-gray-100">
      <div class="flex h-[calc(100vh-8rem)]">
        <!-- Conversations List -->
        <div class="w-1/3 border-r border-gray-100 flex flex-col bg-gray-50">
          <div class="p-4 border-b border-gray-100 flex justify-between items-center bg-white">
            <h2 class="text-xl font-semibold text-gray-800">Messages</h2>
            <button @click="showNewMessageModal = true" 
              class="bg-blue-600 hover:bg-blue-700 text-white px-4 py-2 rounded-full text-sm font-medium transition-colors duration-150 ease-in-out flex items-center gap-2">
              <span class="hidden sm:inline">New Message</span>
              <PlusIcon class="w-4 h-4" />
            </button>
          </div>
          
          <div class="p-4 bg-white">
            <div class="relative">
              <input
                v-model="searchQuery"
                type="text"
                placeholder="Search messages..."
                class="w-full pl-10 pr-4 py-2 border border-gray-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent bg-gray-50"
              />
              <SearchIcon class="w-5 h-5 text-gray-400 absolute left-3 top-2.5" />
            </div>
          </div>

          <div class="overflow-y-auto flex-grow">
            <div
              v-for="conversation in filteredConversations"
              :key="conversation.id"
              @click="selectConversation(conversation)"
              class="group p-4 cursor-pointer transition-all duration-200 hover:bg-blue-50 flex justify-between items-center"
              :class="{ 'bg-blue-50': selectedConversation?.id === conversation.id }"
            >
              <div class="flex items-center space-x-3 flex-grow">
                <div class="relative">
                  <img :src="conversation.avatar" :alt="conversation.name" 
                    class="w-12 h-12 rounded-full object-cover border-2 border-white shadow-sm" />
                  <div v-if="conversation.unreadCount" 
                    class="absolute -top-1 -right-1 bg-blue-500 text-white rounded-full w-5 h-5 flex items-center justify-center text-xs font-bold shadow-sm">
                    {{ conversation.unreadCount }}
                  </div>
                </div>
                <div class="flex-grow min-w-0">
                  <h3 class="font-semibold text-gray-900">{{ conversation.name }}</h3>
                  <div class="flex items-center text-sm">
                    <span v-if="conversation.isTyping" class="text-blue-600 font-medium">Typing...</span>
                    <p v-else class="truncate text-gray-600">{{ conversation.lastMessage }}</p>
                  </div>
                </div>
                <div class="text-xs text-gray-400">{{ formatDate(conversation.updated_at) }}</div>
              </div>
              <button @click.stop="deleteConversation(conversation.id)" 
                class="ml-2 text-gray-400 hover:text-red-500 opacity-0 group-hover:opacity-100 transition-opacity duration-200">
                <TrashIcon class="w-4 h-4" />
              </button>
            </div>
          </div>
        </div>

        <!-- Message View -->
        <div class="w-2/3 flex flex-col bg-white">
          <div v-if="selectedConversation" class="border-b border-gray-100 p-4 flex items-center justify-between bg-white shadow-sm">
            <div class="flex items-center">
              <img :src="selectedConversation.avatar" :alt="selectedConversation.name" 
                class="w-12 h-12 rounded-full object-cover border-2 border-white shadow-sm mr-3" />
              <div>
                <h3 class="font-semibold text-gray-900">{{ selectedConversation.name }}</h3>
                <p v-if="isTyping" class="text-sm text-blue-600 font-medium">typing...</p>
              </div>
            </div>
            <div class="flex items-center space-x-4">
              <button 
                @click="toggleFileSharing" 
                class="flex items-center space-x-2 px-4 py-2 rounded-lg transition-colors duration-150"
                :class="fileSharingEnabled ? 'bg-green-100 text-green-700' : 'bg-gray-100 text-gray-700'"
              >
                <FileIcon class="w-4 h-4" />
                <span class="text-sm font-medium">{{ fileSharingEnabled ? 'Files Enabled' : 'Enable Files' }}</span>
              </button>
            </div>
          </div>

          <div v-if="selectedConversation" class="flex-grow overflow-y-auto p-6" ref="messageContainer">
            <div v-for="message in selectedConversation.messages" :key="message.id" class="mb-6">
  <!-- Message bubble with just text content -->
  <div 
    v-if="message.content"
    :class="[
      'max-w-[70%] rounded-2xl p-4 shadow-sm', 
      message.sent ? 'ml-auto bg-blue-600 text-white' : 'bg-gray-100 text-gray-900'
    ]"
  >
    <div class="flex justify-between items-start gap-2">
      <div class="flex-grow">
        <div class="break-words">
          <div>{{ message.content }}</div>
        </div>
      </div>
      
      <div class="flex items-center space-x-2" v-if="message.sent">
        <button 
          @click="deleteMessage(message.id)"
          class="text-gray-400 hover:text-red-500 transition-colors"
        >
          <TrashIcon class="w-4 h-4" />
        </button>
      </div>
    </div>
  </div>

  <!-- Attachments outside bubble but aligned -->
  <div 
    v-if="message.attachment_url" 
    :class="['mt-2 max-w-[70%]', message.sent ? 'ml-auto' : '']"
  >
    <div v-if="!message.attachment_type?.startsWith('image/')" 
      class="flex items-center gap-2 text-sm text-gray-600">
      <FileIcon class="w-4 h-4" />
      <span class="truncate">{{ message.attachment_name }}</span>
    </div>

    <img 
      v-if="message.attachment_type?.startsWith('image/')"
      :src="message.attachment_url" 
      :alt="message.attachment_name"
      class="rounded-lg cursor-pointer hover:opacity-90 transition-opacity"
      @click="openImagePreview(message.attachment_url)"
    >
  </div>
  
  <div :class="['text-xs mt-2 flex items-center space-x-2', message.sent ? 'justify-end' : 'justify-start']">
    <span class="text-gray-500">{{ message.timestamp }}</span>
    <span v-if="message.sent && message.read_at" class="text-blue-600">
      Read {{ formatDate(message.read_at) }}
    </span>
  </div>
</div>
          </div>
          <div v-else class="flex-grow flex items-center justify-center text-gray-500">
            <div class="text-center">
              <MessageSquareIcon class="w-12 h-12 mx-auto text-gray-300 mb-2" />
              <p>Select a conversation or start a new one</p>
            </div>
          </div>

          <!-- Message Input -->
          <div class="p-4 border-t border-gray-100 bg-white">
            <form @submit.prevent="sendMessage" class="space-y-2">
              <div class="flex items-end space-x-2">
                <div class="flex-grow relative">
                  <textarea
                    v-model="newMessage"
                    @input="handleTyping"
                    placeholder="Type a message..."
                    class="w-full p-3 border border-gray-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent min-h-[2.5rem] max-h-32 resize-y bg-gray-50"
                    :maxlength="2000"
                  ></textarea>
                  <button 
                    type="button" 
                    @click="showEmojiPicker = !showEmojiPicker"
                    class="absolute right-3 bottom-3 text-gray-400 hover:text-gray-600 transition-colors"
                  >
                    <SmileIcon class="w-5 h-5" />
                  </button>
                  <div 
                    v-if="showEmojiPicker" 
                    class="absolute bottom-full right-0 mb-2 z-50"
                  >
                    <EmojiPicker 
                      @select="addEmoji"
                      :showSearch="true"
                      :showPreview="true"
                      :showRecents="true"
                      theme="light"
                    />
                  </div>
                  <div class="text-xs text-gray-400 mt-1">
                    {{ newMessage.length }}/2000 characters
                  </div>
                </div>
                <div class="flex space-x-2">
                  <input
                    type="file"
                    ref="fileInput"
                    class="hidden"
                    @change="handleFileSelect"
                    :accept="fileSharingEnabled ? '*/*' : ''"
                  >
                  <button
                    type="button"
                    @click="triggerFileInput"
                    class="p-3 text-gray-400 hover:text-gray-600 disabled:opacity-50 disabled:cursor-not-allowed transition-colors"
                    :disabled="!fileSharingEnabled"
                    :title="fileSharingEnabled ? 'Attach file' : 'File sharing not enabled'"
                  >
                    <PaperclipIcon class="w-5 h-5" />
                  </button>
                  <button 
                    type="submit" 
                    class="bg-blue-600 text-white px-6 py-3 rounded-lg hover:bg-blue-700 transition-colors duration-150 disabled:opacity-50 disabled:cursor-not-allowed flex items-center gap-2"
                    :disabled="!canSendMessage"
                  >
                    <span>Send</span>
                    <SendIcon class="w-4 h-4" />
                  </button>
                </div>
              </div>
              <!-- Selected file preview -->
              <div v-if="selectedFile" class="flex items-center space-x-2 bg-gray-50 p-3 rounded-lg border border-gray-200">
                <FileIcon class="w-4 h-4 text-gray-500" />
                <span class="text-sm text-gray-700 truncate">{{ selectedFile.name }}</span>
                <button @click="selectedFile = null" class="text-gray-400 hover:text-red-500 transition-colors">
                  <XIcon class="w-4 h-4" />
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <!-- Modals with updated styling -->
    <div v-if="showNewMessageModal" class="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div class="bg-white rounded-xl p-6 w-[28rem] max-w-full mx-4">
        <h3 class="text-xl font-semibold text-gray-900 mb-4">New Message</h3>
        <div class="relative">
          <input
            v-model="newMessageRecipient"
            @input="handleRecipientInput"
            type="text"
            placeholder="Recipient's name"
            class="w-full p-3 border border-gray-200 rounded-lg mb-4 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent bg-gray-50"
          >
          <div v-if="showAutocomplete" class="absolute z-10 w-full bg-white border rounded-lg shadow-lg max-h-48 overflow-y-auto">
            <div
              v-for="user in filteredUsers"
              :key="user.id"
              @click="selectUser(user)"
              class="p-3 hover:bg-gray-50 cursor-pointer transition-colors"
            >
              {{ user.username }}
            </div>
          </div>
        </div>
        <textarea
          v-model="newMessageContent"
          placeholder="Type your message..."
          class="w-full p-3 border border-gray-200 rounded-lg mb-4 h-32 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent bg-gray-50"
          :maxlength="2000"
        ></textarea>
        <div class="text-xs text-gray-400 mb-4">
          {{ newMessageContent.length }}/2000 characters
        </div>
        <div class="flex justify-end space-x-3">
          <button @click="showNewMessageModal = false" 
            class="px-4 py-2 text-gray-600 hover:text-gray-800 font-medium">
            Cancel
          </button>
          <button @click="startNewConversation" 
            class="bg-blue-600 text-white px-4 py-2 rounded-lg hover:bg-blue-700 transition-colors duration-150 font-medium">
            Send
          </button>
        </div>
      </div>
    </div>

    <!-- Image Preview Modal -->
    <div v-if="previewImage" 
      class="fixed inset-0 bg-black bg-opacity-90 flex items-center justify-center z-50 cursor-pointer" 
      @click="previewImage = null">
      <img :src="previewImage" 
        class="max-w-[90vw] max-h-[90vh] object-contain rounded-lg shadow-2xl" 
        alt="Preview">
    </div>
  </div>
</template>

<script>
import { ref, computed, onMounted, onUnmounted } from 'vue';
import { useRoute } from 'vue-router';
import { supabase } from '@/supabase';
import { useStore } from 'vuex';
import { 
  Trash as TrashIcon, 
  File as FileIcon, 
  Paperclip as PaperclipIcon,
  X as XIcon,
  Smile as SmileIcon,
  Search as SearchIcon,
  Plus as PlusIcon,
  Send as SendIcon,
  MessageSquare as MessageSquareIcon
} from 'lucide-vue-next';
import { formatDistanceToNow } from 'date-fns';
import { EmojiPicker } from 'vue3-emoji-picker'
import 'vue3-emoji-picker/css'

export default {
  name: 'MessagesPage',
  components: {
    TrashIcon,
    FileIcon,
    PaperclipIcon,
    XIcon,
    SmileIcon,
    EmojiPicker,
    SearchIcon,
    PlusIcon,
    SendIcon,
    MessageSquareIcon
  },

  setup() {
    const store = useStore();
    const route = useRoute();
    const currentUser = computed(() => store.getters['auth/currentUser']);
    
    // Core refs
    const conversations = ref([]);
    const selectedConversation = ref(null);
    const newMessage = ref('');
    const showNewMessageModal = ref(false);
    const newMessageRecipient = ref('');
    const newMessageContent = ref('');
    const showAutocomplete = ref(false);
    const searchQuery = ref('');
    const fileInput = ref(null);
    const selectedFile = ref(null);
    const messageContainer = ref(null);
    const previewImage = ref(null);
    const fileSharingEnabled = ref(false);
    const isTyping = ref(false);
    const typingTimeout = ref(null);
    const showEmojiPicker = ref(false);
    const filteredUsers = ref([]);

    // Subscriptions
    let messagesSubscription = null;
    let typingSubscription = null;
    let conversationsSubscription = null;

    // Computed properties
    const canSendMessage = computed(() => {
      return (newMessage.value.trim() || selectedFile.value) && selectedConversation.value;
    });

    const filteredConversations = computed(() => {
      return conversations.value.filter(conv => 
        conv.name.toLowerCase().includes(searchQuery.value.toLowerCase()) ||
        conv.lastMessage?.toLowerCase().includes(searchQuery.value.toLowerCase())
      );
    });

    // File handling functions
    const triggerFileInput = () => {
      if (fileSharingEnabled.value) {
        fileInput.value.click();
      }
    };

    const handleFileSelect = async (event) => {
      const file = event.target.files[0];
      if (file) {
        if (file.size > 10 * 1024 * 1024) { // 10MB limit
          alert('File size must be less than 10MB');
          event.target.value = null;
          return;
        }
        selectedFile.value = file;
      }
    };

    const uploadFile = async (file) => {
      const fileExt = file.name.split('.').pop();
      const fileName = `${Math.random()}.${fileExt}`;
      const filePath = `${currentUser.value.id}/${fileName}`;

      const { error: uploadError } = await supabase.storage
        .from('message-attachments')
        .upload(filePath, file);

      if (uploadError) throw uploadError;

      const { data: { publicUrl } } = supabase.storage
        .from('message-attachments')
        .getPublicUrl(filePath);

      return {
        url: publicUrl,
        type: file.type,
        name: file.name,
        size: file.size
      };
    };

    // Message handling functions
    const sendMessage = async () => {
      if (!canSendMessage.value) return;

      let attachmentData = null;
      if (selectedFile.value) {
        try {
          attachmentData = await uploadFile(selectedFile.value);
        } catch (error) {
          console.error('Error uploading file:', error);
          alert('Failed to upload file. Please try again.');
          return;
        }
      }

      try {
        const messageData = {
          conversation_id: selectedConversation.value.id,
          sender_id: currentUser.value.id,
          recipient_id: selectedConversation.value.otherUser.id,
          content: newMessage.value,
          attachment_url: attachmentData?.url,
          attachment_type: attachmentData?.type,
          attachment_name: attachmentData?.name,
          attachment_size: attachmentData?.size
        };

        const { data: newMessageData, error: sendError } = await supabase
          .from('messages')
          .insert(messageData)
          .select()
          .single();

        if (sendError) throw sendError;

        // Update conversation's last message
        const { error: updateError } = await supabase
          .from('conversations')
          .update({ 
            last_message: attachmentData ? `Sent ${attachmentData.name}` : newMessage.value,
            updated_at: new Date().toISOString()
          })
          .eq('id', selectedConversation.value.id);

        if (updateError) throw updateError;

        // Clear input and file
        newMessage.value = '';
        selectedFile.value = null;
        if (fileInput.value) {
          fileInput.value.value = '';
        }

        // Update local state
        selectedConversation.value.messages.push({
          ...newMessageData,
          sent: true,
          timestamp: formatDate(newMessageData.created_at)
        });

        scrollToBottom();
      } catch (error) {
        console.error('Error sending message:', error);
        alert('Failed to send message. Please try again.');
      }
    };

    const deleteMessage = async (messageId) => {
      if (!confirm('Are you sure you want to delete this message?')) return;

      try {
        const { error: deleteError } = await supabase
          .from('messages')
          .delete()
          .eq('id', messageId);

        if (deleteError) throw deleteError;

        // Remove message from local state
        selectedConversation.value.messages = selectedConversation.value.messages
          .filter(msg => msg.id !== messageId);

        // Update conversation's last message if needed
        if (selectedConversation.value.messages.length > 0) {
          const lastMessage = selectedConversation.value.messages[selectedConversation.value.messages.length - 1];
          await updateConversationLastMessage(lastMessage);
        }
      } catch (error) {
        console.error('Error deleting message:', error);
        alert('Failed to delete message. Please try again.');
      }
    };

    const markMessageAsRead = async (messageId) => {
      try {
        const { error: readError } = await supabase
          .from('messages')
          .update({ read_at: new Date().toISOString() })
          .eq('id', messageId);

        if (readError) {
          console.error('Error marking message as read:', readError);
        }
      } catch (error) {
        console.error('Error marking message as read:', error);
      }
    };

    // Conversation handling functions
    const fetchConversations = async () => {
      try {
        const { data: conversationsData, error } = await supabase
          .from('conversations')
          .select(`
            id,
            user1_id,
            user2_id,
            users!conversations_user2_id_fkey(id, username, avatar_url),
            last_message,
            updated_at,
            file_sharing_permissions(enabled)
          `)
          .or(`user1_id.eq.${currentUser.value.id},user2_id.eq.${currentUser.value.id}`)
          .order('updated_at', { ascending: false });

        if (error) throw error;

        conversations.value = await Promise.all(conversationsData.map(async (conv) => {
          const otherUserId = conv.user1_id === currentUser.value.id ? conv.user2_id : conv.user1_id;
          
          // Fetch other user's details
          const { data: otherUser } = await supabase
            .from('users')
            .select('id, username, avatar_url')
            .eq('id', otherUserId)
            .single();

          return {
            id: conv.id,
            name: otherUser.username,
            avatar: otherUser.avatar_url || "/default-avatar.png",
            lastMessage: conv.last_message,
            updated_at: conv.updated_at,
            fileSharingEnabled: conv.file_sharing_permissions?.[0]?.enabled || false,
            unreadCount: 0,
            otherUserId,
            otherUser
          };
        }));

        // Set up unread counts
        await Promise.all(conversations.value.map(async (conv) => {
          const { count } = await supabase
            .from('messages')
            .select('id', { count: 'exact' })
            .eq('conversation_id', conv.id)
            .eq('sender_id', conv.otherUserId)
            .is('read_at', null);

          conv.unreadCount = count || 0;
        }));
      } catch (error) {
        console.error('Error fetching conversations:', error);
        alert('Failed to load conversations. Please try again.');
      }
    };

        const selectConversation = async (conversation) => {
      selectedConversation.value = conversation;
      
      try {
        // Fetch messages
        const { data: messages, error: messagesError } = await supabase
          .from('messages')
          .select('*')
          .eq('conversation_id', conversation.id)
          .order('created_at', { ascending: true });

        if (messagesError) throw messagesError;

        // Fetch file sharing permissions
        const { data: permissionData, error: permissionsError } = await supabase
          .from('file_sharing_permissions')
          .select('enabled')
          .eq('conversation_id', conversation.id)
          .single();

        if (permissionsError && permissionsError.code !== 'PGRST116') { // Ignore not found error
          throw permissionsError;
        }

        fileSharingEnabled.value = permissionData?.enabled || false;
        
        selectedConversation.value.messages = messages.map(msg => ({
          ...msg,
          sent: msg.sender_id === currentUser.value.id,
          timestamp: formatDate(msg.created_at)
        }));

        // Mark unread messages as read
        const unreadMessages = messages.filter(msg => 
          msg.sender_id !== currentUser.value.id && !msg.read_at
        );
        
        if (unreadMessages.length > 0) {
          await Promise.all(unreadMessages.map(msg => markMessageAsRead(msg.id)));
          selectedConversation.value.unreadCount = 0;
        }

        // Clean up existing subscriptions
        if (messagesSubscription) {
          messagesSubscription.unsubscribe();
        }
        if (typingSubscription) {
          typingSubscription.unsubscribe();
        }

        setupSubscriptions();
        scrollToBottom();
      } catch (error) {
        console.error('Error loading conversation:', error);
        alert('Failed to load conversation. Please try again.');
      }
    };

    const deleteConversation = async (conversationId) => {
      if (!confirm('Are you sure you want to delete this conversation?')) return;

      try {
        // Delete messages first
        const { error: messagesError } = await supabase
          .from('messages')
          .delete()
          .eq('conversation_id', conversationId);

        if (messagesError) throw messagesError;

        // Then delete the conversation
        const { error: conversationError } = await supabase
          .from('conversations')
          .delete()
          .eq('id', conversationId);

        if (conversationError) throw conversationError;

        conversations.value = conversations.value.filter(c => c.id !== conversationId);
        if (selectedConversation.value?.id === conversationId) {
          selectedConversation.value = null;
        }
      } catch (error) {
        console.error('Error deleting conversation:', error);
        alert('Failed to delete conversation. Please try again.');
      }
    };

    // File sharing functions
    const toggleFileSharing = async () => {
      try {
        const { error: sharingError } = await supabase
          .from('file_sharing_permissions')
          .upsert({
            conversation_id: selectedConversation.value.id,
            enabled: !fileSharingEnabled.value,
            enabled_by: currentUser.value.id
          }, {
            onConflict: 'conversation_id'
          });

        if (sharingError) throw sharingError;
        fileSharingEnabled.value = !fileSharingEnabled.value;
      } catch (error) {
        console.error('Error toggling file sharing:', error);
        alert('Failed to update file sharing settings. Please try again.');
      }
    };

    // New conversation functions
    const handleRecipientInput = async () => {
      if (newMessageRecipient.value.length > 0) {
        try {
          const { data: users, error: searchError } = await supabase
            .from('users')
            .select('id, username')
            .ilike('username', `%${newMessageRecipient.value}%`)
            .limit(5);

          if (searchError) throw searchError;

          showAutocomplete.value = users.length > 0;
          filteredUsers.value = users;
        } catch (error) {
          console.error('Error searching users:', error);
        }
      } else {
        showAutocomplete.value = false;
      }
    };

    const selectUser = (user) => {
      newMessageRecipient.value = user.username;
      showAutocomplete.value = false;
    };

    const startNewConversation = async () => {
      if (newMessageRecipient.value.trim() && newMessageContent.value.trim()) {
        try {
          // Check if recipient exists
          const { data: recipientUser, error: userError } = await supabase
            .from('users')
            .select('id')
            .eq('username', newMessageRecipient.value)
            .single();

          if (userError) {
            alert('User not found.');
            return;
          }

          // Check for existing conversation
          const { data: existingConv } = await supabase
            .from('conversations')
            .select('id')
            .or(
              `and(user1_id.eq.${currentUser.value.id},user2_id.eq.${recipientUser.id}),` +
              `and(user1_id.eq.${recipientUser.id},user2_id.eq.${currentUser.value.id})`
            )
            .single();

          if (existingConv) {
            const conversation = conversations.value.find(c => c.id === existingConv.id);
            if (conversation) {
              selectConversation(conversation);
              showNewMessageModal.value = false;
              return;
            }
          }

          // Determine user order based on ID values
          let user1_id, user2_id;
          if (currentUser.value.id < recipientUser.id) {
            user1_id = currentUser.value.id;
            user2_id = recipientUser.id;
          } else {
            user1_id = recipientUser.id;
            user2_id = currentUser.value.id;
          }

          // Create new conversation matching exact schema
          const { data: newConversation, error: convError } = await supabase
            .from('conversations')
            .insert({
              user1_id,
              user2_id,
              last_message: newMessageContent.value
            })
            .select()
            .single();

          if (convError) throw convError;

          // Send first message
          const { error: msgError } = await supabase
            .from('messages')
            .insert({
              conversation_id: newConversation.id,
              sender_id: currentUser.value.id,
              recipient_id: recipientUser.id,
              content: newMessageContent.value,
            });

          if (msgError) throw msgError;

          // Add to local state with correct user information
          const otherUser = {
            id: recipientUser.id,
            username: newMessageRecipient.value,
            avatar_url: "/default-avatar.png"
          };

          const newConv = {
            id: newConversation.id,
            name: newMessageRecipient.value,
            avatar: otherUser.avatar_url,
            lastMessage: newMessageContent.value,
            updated_at: new Date().toISOString(),
            messages: [{
              id: Date.now(),
              content: newMessageContent.value,
              sent: true,
              timestamp: formatDate(new Date()),
            }],
            otherUser,
            otherUserId: recipientUser.id,
            user1_id,
            user2_id
          };

          conversations.value.unshift(newConv);
          await selectConversation(newConv);
          
          // Reset form
          showNewMessageModal.value = false;
          newMessageRecipient.value = '';
          newMessageContent.value = '';
        } catch (error) {
          console.error('Error starting new conversation:', error);
          alert('Failed to start conversation. Please try again.');
        }
      }
    };

    // Typing indicators
    const updateTypingStatus = async (isTyping) => {
      if (!selectedConversation.value) return;
      
      try {
        const { error: typingError } = await supabase
          .from('typing_indicators')
          .upsert({
            conversation_id: selectedConversation.value.id,
            user_id: currentUser.value.id,
            is_typing: isTyping,
            last_typed: new Date().toISOString()
          }, {
            onConflict: 'conversation_id,user_id'
          });

        if (typingError) throw typingError;
      } catch (error) {
        console.error('Error updating typing status:', error);
      }
    };

    const handleTyping = () => {
      if (typingTimeout.value) {
        clearTimeout(typingTimeout.value);
      }

      updateTypingStatus(true);

      typingTimeout.value = setTimeout(() => {
        updateTypingStatus(false);
      }, 2000);
    };

    // Emoji handling
    const addEmoji = (emoji) => {
      newMessage.value += emoji.i;
      showEmojiPicker.value = false;
    };

    // Real-time subscriptions
    const setupSubscriptions = () => {
      // Messages subscription
      messagesSubscription = supabase
        .channel(`messages-${selectedConversation.value.id}`)
        .on('postgres_changes', {
          event: 'INSERT',
          schema: 'public',
          table: 'messages',
          filter: `conversation_id=eq.${selectedConversation.value.id}`
        }, async (payload) => {
          const newMessage = payload.new;
          
          // Only process messages from other user
          if (newMessage.sender_id !== currentUser.value.id) {
            selectedConversation.value.messages.push({
              ...newMessage,
              sent: false,
              timestamp: formatDate(newMessage.created_at)
            });

            // Mark as read if conversation is currently selected
            if (selectedConversation.value.id === newMessage.conversation_id) {
              await markMessageAsRead(newMessage.id);
            } else {
              // Update unread count for the conversation
              const conversation = conversations.value.find(
                c => c.id === newMessage.conversation_id
              );
              if (conversation) {
                conversation.unreadCount = (conversation.unreadCount || 0) + 1;
              }
            }

            scrollToBottom();
          }
        })
        .subscribe();

      // Typing indicators subscription
      typingSubscription = supabase
        .channel(`typing-${selectedConversation.value.id}`)
        .on('postgres_changes', {
          event: '*',
          schema: 'public',
          table: 'typing_indicators',
          filter: `conversation_id=eq.${selectedConversation.value.id}`
        }, (payload) => {
          if (payload.new.user_id !== currentUser.value.id) {
            isTyping.value = payload.new.is_typing;
          }
        })
        .subscribe();

      // Conversations subscription
      conversationsSubscription = supabase
        .channel('conversations')
        .on('postgres_changes', {
          event: '*',
          schema: 'public',
          table: 'conversations',
          filter: `or(user1_id.eq.${currentUser.value.id},user2_id.eq.${currentUser.value.id})`
        }, (payload) => {
          if (payload.eventType === 'DELETE') {
            conversations.value = conversations.value.filter(c => c.id !== payload.old.id);
            if (selectedConversation.value?.id === payload.old.id) {
              selectedConversation.value = null;
            }
          } else if (payload.eventType === 'UPDATE') {
            const updatedConv = conversations.value.find(c => c.id === payload.new.id);
            if (updatedConv) {
              updatedConv.lastMessage = payload.new.last_message;
              updatedConv.updated_at = payload.new.updated_at;
            }
          }
        })
        .subscribe();
    };

    // Utility functions
    const formatDate = (date) => {
      return formatDistanceToNow(new Date(date), { addSuffix: true });
    };

    const scrollToBottom = () => {
      setTimeout(() => {
        if (messageContainer.value) {
          messageContainer.value.scrollTop = messageContainer.value.scrollHeight;
        }
      }, 50);
    };

    const openImagePreview = (url) => {
      previewImage.value = url;
    };

    // Update conversation's last message
    const updateConversationLastMessage = async (message) => {
      try {
        const { error: updateError } = await supabase
          .from('conversations')
          .update({ 
            last_message: message.content,
            updated_at: new Date().toISOString()
          })
          .eq('id', selectedConversation.value.id);

        if (updateError) throw updateError;
      } catch (error) {
        console.error('Error updating conversation:', error);
      }
    };

    // Lifecycle hooks
    onMounted(async () => {
      await fetchConversations();
      
      if (route.query.new === 'true' && route.params.userId) {
        showNewMessageModal.value = true;
        const { data: userData, error: userError } = await supabase
          .from('users')
          .select('username')
          .eq('id', route.params.userId)
          .single();
        
        if (userError) {
          console.error('Error fetching user:', userError);
          return;
        }

        if (userData) {
          newMessageRecipient.value = userData.username;
        }
      }
    });

    onUnmounted(() => {
      if (messagesSubscription) {
        messagesSubscription.unsubscribe();
      }
      if (typingSubscription) {
        typingSubscription.unsubscribe();
      }
      if (conversationsSubscription) {
        conversationsSubscription.unsubscribe();
      }
      if (typingTimeout.value) {
        clearTimeout(typingTimeout.value);
      }
    });

    return {
      // State
      conversations,
      selectedConversation,
      newMessage,
      showNewMessageModal,
      newMessageRecipient,
      newMessageContent,
      showAutocomplete,
      searchQuery,
      fileInput,
      selectedFile,
      messageContainer,
      previewImage,
      fileSharingEnabled,
      isTyping,
      showEmojiPicker,
      filteredUsers,
      
      // Computed
      canSendMessage,
      filteredConversations,
      
      // Methods
      selectConversation,
      sendMessage,
      deleteMessage,
      deleteConversation,
      handleRecipientInput,
      selectUser,
      startNewConversation,
      triggerFileInput,
      handleFileSelect,
      toggleFileSharing,
      formatDate,
      openImagePreview,
      handleTyping,
      addEmoji,
    };
  }
};
</script>

<style>
.v3-emoji-picker {
  --ep-color-bg: #ffffff;
  --ep-color-sbg: #f8fafc;
  --ep-color-border: #e2e8f0;
  --ep-color-selected: #2563eb;
  --ep-color-hover: #3b82f6;
  border-radius: 0.75rem;
  overflow: hidden;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
}

/* Scrollbar styling */
.overflow-y-auto {
  scrollbar-width: thin;
  scrollbar-color: #cbd5e1 transparent;
}

.overflow-y-auto::-webkit-scrollbar {
  width: 6px;
}

.overflow-y-auto::-webkit-scrollbar-track {
  background: transparent;
}

.overflow-y-auto::-webkit-scrollbar-thumb {
  background-color: #cbd5e1;
  border-radius: 3px;
}

/* Smooth transitions */
.transition-all {
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

/* Message bubble animations */
.messages-enter-active,
.messages-leave-active {
  transition: all 0.3s ease;
}

.messages-enter-from,
.messages-leave-to {
  opacity: 0;
  transform: translateY(20px);
}

/* Modal animations */
.modal-enter-active,
.modal-leave-active {
  transition: opacity 0.3s ease;
}

.modal-enter-from,
.modal-leave-to {
  opacity: 0;
}
</style>