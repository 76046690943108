<template>
  <div class="min-h-screen bg-gradient-to-br from-purple-50 to-fuchsia-50 py-12">
    <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <h1 class="text-4xl font-bold text-gray-900 mb-8">My Bookshelf</h1>

      <div class="mb-8 flex flex-col sm:flex-row justify-between items-start sm:items-center gap-4">
        <div class="flex flex-wrap gap-2">
          <button
            v-for="shelf in shelves"
            :key="shelf"
            @click="currentShelf = shelf"
            :class="[
              'px-4 py-2 rounded-lg font-medium transition-all duration-300 shadow-sm',
              currentShelf === shelf
                ? 'bg-purple-600 text-white transform scale-105'
                : 'bg-white text-gray-700 hover:bg-purple-50'
            ]"
          >
            {{ shelf }}
          </button>
        </div>
        <div class="flex flex-col sm:flex-row gap-3 w-full sm:w-auto">
          <div class="relative">
            <input
              v-model="searchQuery"
              type="text"
              placeholder="Search books..."
              class="pl-10 pr-4 py-2 w-full sm:w-64 border border-gray-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-500 focus:border-transparent bg-white shadow-sm"
            />
            <svg class="w-5 h-5 text-gray-400 absolute left-3 top-2.5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
            </svg>
          </div>
          <button
            @click="showAddBookModal = true"
            class="bg-purple-600 text-white px-6 py-2 rounded-lg hover:bg-purple-700 transition duration-300 shadow-sm flex items-center justify-center gap-2"
          >
            <svg class="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 4v16m8-8H4" />
            </svg>
            Add Book
          </button>
        </div>
      </div>

      <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6">
        <div
          v-for="book in filteredBooks"
          :key="book.id"
          class="group bg-white rounded-xl shadow hover:shadow-xl transition-all duration-300 overflow-hidden flex flex-col"
        >
          <div class="relative aspect-[2/3] overflow-hidden">
            <img 
              :src="book.cover_image" 
              :alt="book.title" 
              class="w-full h-full object-cover transform group-hover:scale-105 transition-transform duration-300"
            >
            <div class="absolute inset-0 bg-gradient-to-t from-black/60 to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-300"></div>
          </div>
          <div class="p-4 mt-auto">
            <h3 class="font-semibold text-lg text-gray-900 mb-1 line-clamp-2">{{ book.title }}</h3>
            <p class="text-gray-600 mb-3 text-sm">{{ book.author }}</p>
            <div class="flex justify-between items-center">
              <span 
                :class="[
                  'px-3 py-1 rounded-full text-sm font-medium',
                  {
                    'bg-green-100 text-green-800': book.status === 'Read',
                    'bg-purple-100 text-purple-800': book.status === 'Currently Reading',
                    'bg-amber-100 text-amber-800': book.status === 'Want to Read'
                  }
                ]"
              >
                {{ book.status }}
              </span>
              <button
                @click="openBookMenu(book)"
                class="text-gray-400 hover:text-gray-600 p-1 rounded-full hover:bg-gray-100 transition-colors duration-200"
              >
                <svg class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20">
                  <path d="M10 6a2 2 0 110-4 2 2 0 010 4zM10 12a2 2 0 110-4 2 2 0 010 4zM10 18a2 2 0 110-4 2 2 0 010 4z" />
                </svg>
              </button>
            </div>
            <button 
              @click="readBook(book)" 
              class="mt-4 w-full bg-purple-50 text-purple-600 py-2 rounded-lg hover:bg-purple-100 transition duration-300 font-medium"
            >
              Read Now
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Add Book Modal -->
    <div v-if="showAddBookModal" class="fixed inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center p-4 z-50">
      <div class="bg-white rounded-xl p-6 max-w-md w-full shadow-xl" @click.stop>
        <h2 class="text-2xl font-bold text-gray-900 mb-6">Add a Book</h2>
        <form @submit.prevent="addBook">
          <div class="mb-6">
            <input
              v-model="bookSearch"
              @input="searchBooks"
              type="text"
              placeholder="Search for a book..."
              class="w-full px-4 py-2 rounded-lg border border-gray-200 focus:ring-2 focus:ring-purple-500 focus:border-transparent"
            >
          </div>
          <div v-if="searchResults.length > 0" class="mb-6">
            <select
              v-model="selectedBook"
              class="w-full px-4 py-2 rounded-lg border border-gray-200 focus:ring-2 focus:ring-purple-500 focus:border-transparent"
            >
              <option v-for="book in searchResults" :key="book.id" :value="book">
                {{ book.title }} by {{ book.author }}
              </option>
            </select>
          </div>
          <div class="mb-6">
            <select
              v-model="newBookStatus"
              required
              class="w-full px-4 py-2 rounded-lg border border-gray-200 focus:ring-2 focus:ring-purple-500 focus:border-transparent"
            >
              <option value="Want to Read">Want to Read</option>
              <option value="Currently Reading">Currently Reading</option>
              <option value="Read">Read</option>
            </select>
          </div>
          <div class="flex justify-end gap-3">
            <button
              type="button"
              @click="closeAddBookModal"
              class="px-4 py-2 text-gray-600 hover:text-gray-800 font-medium"
            >
              Cancel
            </button>
            <button
              type="submit"
              class="bg-purple-600 text-white px-6 py-2 rounded-lg hover:bg-purple-700 transition duration-300 disabled:opacity-50"
              :disabled="!selectedBook"
            >
              Add
            </button>
          </div>
        </form>
      </div>
    </div>

    <!-- Book Menu Modal -->
    <div v-if="selectedBook" class="fixed inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center p-4 z-50">
      <div class="bg-white rounded-xl p-6 max-w-md w-full shadow-xl" @click.stop>
        <h2 class="text-2xl font-bold text-gray-900 mb-6">{{ selectedBook.title }}</h2>
        <div class="space-y-2">
          <button 
            v-for="status in ['Want to Read', 'Currently Reading', 'Read']"
            :key="status"
            @click="updateBookStatus(status)"
            class="w-full text-left px-4 py-3 rounded-lg hover:bg-gray-50 transition-colors duration-200 flex items-center gap-3"
          >
            <span class="w-5 h-5">
              <svg v-if="selectedBook.status === status" class="text-purple-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
              </svg>
            </span>
            {{ status }}
          </button>
          <button 
            @click="removeBook"
            class="w-full text-left px-4 py-3 rounded-lg text-red-600 hover:bg-red-50 transition-colors duration-200 flex items-center gap-3"
          >
            <span class="w-5 h-5">
              <svg fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
              </svg>
            </span>
            Remove
          </button>
        </div>
        <button 
          @click="closeBookMenu"
          class="mt-6 w-full px-4 py-2 text-gray-600 hover:text-gray-800 font-medium"
        >
          Cancel
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import { supabase } from '@/supabase'

export default {
  name: 'MyBookshelf',
  setup() {
    const router = useRouter()
    const shelves = ['All', 'Want to Read', 'Currently Reading', 'Read']
    const currentShelf = ref('All')
    const searchQuery = ref('')
    const books = ref([])
    const showAddBookModal = ref(false)
    const selectedBook = ref(null)
    const newBookStatus = ref('Want to Read')
    const bookSearch = ref('')
    const searchResults = ref([])

    const fetchBooks = async () => {
      const { data: { user } } = await supabase.auth.getUser()
      const { data, error } = await supabase
        .from('bookshelf')
        .select(`
          id,
          status,
          stories (
            id,
            title,
            cover_image,
            users!author_id (username)
          )
        `)
        .eq('user_id', user.id)

      if (error) {
        console.error('Error fetching books:', error)
      } else {
        books.value = data.map(item => ({
          id: item.stories.id,
          title: item.stories.title,
          author: item.stories.users.username,
          status: item.status,
          cover_image: item.stories.cover_image
        }))
      }
    }

    onMounted(fetchBooks)

    const filteredBooks = computed(() => {
      return books.value
        .filter(book => currentShelf.value === 'All' || book.status === currentShelf.value)
        .filter(book => 
          book.title.toLowerCase().includes(searchQuery.value.toLowerCase()) ||
          book.author.toLowerCase().includes(searchQuery.value.toLowerCase())
        )
    })

    const getStatusClass = (status) => {
      switch (status) {
        case 'Read': return 'text-green-600'
        case 'Currently Reading': return 'text-blue-600'
        case 'Want to Read': return 'text-yellow-600'
        default: return 'text-gray-600'
      }
    }

    const openBookMenu = (book) => {
      selectedBook.value = book
    }

    const closeBookMenu = () => {
      selectedBook.value = null
    }

    const updateBookStatus = async (newStatus) => {
      if (selectedBook.value) {
        const { data: { user } } = await supabase.auth.getUser()
        const { error } = await supabase
          .from('bookshelf')
          .update({ status: newStatus })
          .eq('user_id', user.id)
          .eq('story_id', selectedBook.value.id)

        if (error) {
          console.error('Error updating book status:', error)
        } else {
          const book = books.value.find(b => b.id === selectedBook.value.id)
          if (book) {
            book.status = newStatus
          }
        }
      }
      closeBookMenu()
    }

    const removeBook = async () => {
      if (selectedBook.value) {
        const { data: { user } } = await supabase.auth.getUser()
        const { error } = await supabase
          .from('bookshelf')
          .delete()
          .eq('user_id', user.id)
          .eq('story_id', selectedBook.value.id)

        if (error) {
          console.error('Error removing book:', error)
        } else {
          books.value = books.value.filter(b => b.id !== selectedBook.value.id)
        }
      }
      closeBookMenu()
    }

    const searchBooks = async () => {
      if (bookSearch.value.length > 2) {
        const { data, error } = await supabase
          .from('stories')
          .select(`
            id,
            title,
            users!author_id (username)
          `)
          .or(`title.ilike.%${bookSearch.value}%,users.username.ilike.%${bookSearch.value}%`)
          .limit(10)

        if (error) {
          console.error('Error searching books:', error)
        } else {
          searchResults.value = data.map(item => ({
            id: item.id,
            title: item.title,
            author: item.users.username
          }))
        }
      } else {
        searchResults.value = []
      }
    }

    const addBook = async () => {
  if (selectedBook.value) {
    const { data: { user } } = await supabase.auth.getUser()
    if (!user) {
      console.error('No authenticated user found');
      return;
    }
    const { error } = await supabase
      .from('bookshelf')
      .insert({
        user_id: user.id,
        story_id: selectedBook.value.id,
        status: newBookStatus.value
      })
      .select()

    if (error) {
      console.error('Error adding book to bookshelf:', error)
    } else {
      books.value.push({
        id: selectedBook.value.id,
        title: selectedBook.value.title,
        author: selectedBook.value.author,
        status: newBookStatus.value,
        cover_image: selectedBook.value.cover_image
      })
      closeAddBookModal()
    }
  }
}

    const closeAddBookModal = () => {
      showAddBookModal.value = false
      bookSearch.value = ''
      searchResults.value = []
      selectedBook.value = null
      newBookStatus.value = 'Want to Read'
    }

    const readBook = (book) => {
      router.push(`/read/${book.id}`)
    }

    return {
      shelves,
      currentShelf,
      searchQuery,
      books,
      showAddBookModal,
      selectedBook,
      newBookStatus,
      bookSearch,
      searchResults,
      filteredBooks,
      getStatusClass,
      openBookMenu,
      closeBookMenu,
      updateBookStatus,
      removeBook,
      searchBooks,
      addBook,
      closeAddBookModal,
      readBook
    }
  }
}
</script>