<template>
  <div class="min-h-screen bg-gradient-to-br from-gray-50 to-gray-100">
    <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
      <div class="flex flex-col lg:flex-row gap-8">
        <!-- Left Sidebar -->
        <div class="lg:w-1/4 space-y-6">
          <!-- Profile Card -->
          <div class="bg-white rounded-2xl shadow-xl overflow-hidden transition-all duration-300 hover:shadow-2xl">
            <div class="bg-gradient-to-r from-indigo-600 to-purple-600 h-48 relative">
              <img 
                :src="user.avatar_url || defaultAvatar" 
                :alt="user.name" 
                class="absolute left-1/2 transform -translate-x-1/2 -bottom-16 w-32 h-32 rounded-full border-4 border-white shadow-lg object-cover ring-4 ring-purple-100"
              />
            </div>
            <div class="pt-20 px-6 pb-8 text-center">
              <h1 class="text-2xl font-bold text-gray-900">{{ user.name }}</h1>
              <p class="text-purple-600 font-medium mt-1">@{{ user.username }}</p>
              <p class="text-gray-600 mt-2">{{ user.tagline }}</p>
              
              <!-- Stats Grid -->
              <div class="grid grid-cols-3 gap-4 mt-6 p-4 bg-gray-50 rounded-xl">
                <div class="cursor-pointer group" @click="viewFollowers">
                  <p class="font-bold text-lg text-gray-900 group-hover:text-purple-600 transition-colors">{{ user.followers }}</p>
                  <p class="text-sm text-gray-600">Followers</p>
                </div>
                <div class="cursor-pointer group" @click="viewFollowing">
                  <p class="font-bold text-lg text-gray-900 group-hover:text-purple-600 transition-colors">{{ user.following }}</p>
                  <p class="text-sm text-gray-600">Following</p>
                </div>
                <div>
                  <p class="font-bold text-lg text-gray-900">{{ user.publishedStories.length }}</p>
                  <p class="text-sm text-gray-600">Stories</p>
                </div>
              </div>
            </div>
          </div>

          <!-- Writing Stats Card -->
          <div class="bg-white rounded-2xl shadow-xl p-6 transition-all duration-300 hover:shadow-2xl">
            <h2 class="text-xl font-bold text-gray-900 mb-6 flex items-center">
              <span class="w-1.5 h-6 bg-gradient-to-b from-indigo-600 to-purple-600 rounded-lg mr-3"></span>
              Writing Stats
            </h2>
            <div class="space-y-6">
              <div class="flex justify-between items-center p-4 bg-gray-50 rounded-xl">
                <span class="text-gray-700 font-medium">Total Words</span>
                <span class="font-bold text-purple-600">{{ user.stats.totalWords.toLocaleString() }}</span>
              </div>
              <div class="flex justify-between items-center p-4 bg-gray-50 rounded-xl">
                <span class="text-gray-700 font-medium">Stories Published</span>
                <span class="font-bold text-purple-600">{{ user.stats.storiesPublished }}</span>
              </div>
            </div>
          </div>
        </div>

        <!-- Main Content -->
        <div class="lg:flex-1">
          <div class="bg-white rounded-2xl shadow-xl overflow-hidden transition-all duration-300 hover:shadow-2xl">
            <!-- Tabs -->
            <div class="flex border-b bg-gray-50 p-1">
              <button
                v-for="tab in visibleTabs"
                :key="tab"
                @click="currentTab = tab"
                class="px-6 py-3 font-medium rounded-xl transition-all duration-300"
                :class="currentTab === tab ? 'bg-white text-purple-600 shadow-sm' : 'text-gray-600 hover:text-gray-900'"
              >
                {{ tab }}
              </button>
            </div>

            <!-- Content Area -->
            <div class="p-8">
              <!-- About Tab -->
              <div v-if="currentTab === 'About'" class="space-y-8">
                <!-- Bio Section -->
                <div class="bg-gray-50 rounded-2xl p-6">
                  <div class="flex justify-between items-center mb-4">
                    <h2 class="text-2xl font-bold text-gray-900">About Me</h2>
                    <button 
                      @click="toggleAboutMe" 
                      class="text-purple-600 hover:text-purple-700 font-medium transition-colors"
                    >
                      {{ showAboutMe ? 'Hide' : 'Show' }}
                    </button>
                  </div>
                  <p v-if="showAboutMe" class="text-gray-700 leading-relaxed">{{ user.bio }}</p>
                </div>

                <!-- Published Books Grid -->
                <div>
                  <h2 class="text-2xl font-bold text-gray-900 mb-6">My Books</h2>
                  <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                    <div 
                      v-for="story in user.publishedStories" 
                      :key="story.id" 
                      class="group bg-gray-50 rounded-2xl overflow-hidden cursor-pointer transform transition-all duration-300 hover:-translate-y-1 hover:shadow-xl"
                      @click="viewBook(story.id)"
                    >
                      <div class="relative pt-[150%]">
                        <img 
                          :src="story.cover_image" 
                          :alt="story.title"
                          class="absolute inset-0 w-full h-full object-cover transform transition-transform duration-300 group-hover:scale-105"
                        />
                        <div class="absolute inset-0 bg-gradient-to-t from-black/60 via-black/20 to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-300"></div>
                      </div>
                      <div class="p-6">
                        <h3 class="font-bold text-lg mb-2 group-hover:text-purple-600 transition-colors">{{ story.title }}</h3>
                        <div class="flex items-center justify-between">
                          <span class="px-3 py-1 bg-purple-100 text-purple-600 rounded-full text-sm font-medium">
                            {{ story.genre }}
                          </span>
                          <div class="flex items-center text-gray-600">
                            <EyeIcon class="w-4 h-4 mr-1" />
                            <span class="text-sm">{{ story.reads.toLocaleString() }}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- Message Board -->
                <div>
                  <div class="flex justify-between items-center mb-6">
                    <h2 class="text-2xl font-bold text-gray-900">Message Board</h2>
                    <button 
                      @click="showAnnouncementModal = true" 
                      class="px-6 py-3 bg-gradient-to-r from-indigo-600 to-purple-600 text-white font-medium rounded-xl hover:from-indigo-700 hover:to-purple-700 transition-all duration-300 shadow-lg hover:shadow-xl"
                    >
                      Make Announcement
                    </button>
                  </div>

                  <!-- Announcements -->
<div class="mb-8">
  <!-- Pinned Announcement -->
  <div v-if="user.announcements.pinned" class="mb-8">
    <h3 class="text-xl font-bold text-gray-900 mb-4">Pinned Announcement</h3>
    <div class="bg-yellow-50 border border-yellow-200 p-6 rounded-xl">
      <div class="flex items-center justify-between mb-4">
        <div class="flex items-center">
          <img 
            :src="user.avatar_url || defaultAvatar" 
            :alt="user.name"
            class="w-12 h-12 rounded-full mr-4 object-cover ring-2 ring-purple-100"
          />
          <div>
            <p class="font-bold text-lg">{{ user.name }}</p>
            <p class="text-gray-600 text-sm">{{ formatDate(user.announcements.pinned.created_at) }}</p>
          </div>
        </div>
        <div class="flex gap-3">
          <button 
            @click="unpinAnnouncement(user.announcements.pinned.id)" 
            class="text-purple-600 hover:text-purple-700 font-medium transition-colors"
          >
            Unpin
          </button>
          <button 
            @click="deleteAnnouncement(user.announcements.pinned.id, true)" 
            class="text-red-500 hover:text-red-600 transition-colors"
          >
            <TrashIcon class="w-5 h-5" />
          </button>
        </div>
      </div>

      <div v-html="user.announcements.pinned.content" class="text-gray-800 leading-relaxed mb-6"></div>

      <!-- Replies -->
      <div class="space-y-4 ml-12 border-l-2 border-purple-100 pl-6">
        <div 
          v-for="reply in user.announcements.pinned.replies" 
          :key="reply.id" 
          class="bg-white rounded-xl p-4 shadow-sm"
        >
          <div class="flex items-center justify-between mb-3">
            <div class="flex items-center">
              <img 
                :src="reply.author?.avatar_url || defaultAvatar" 
  :alt="reply.author?.name || 'Deleted User'"
                class="w-8 h-8 rounded-full mr-3 object-cover ring-2 ring-purple-100"
              />
              <div>
                <router-link 
                  :to="{ name: 'VisitedUserProfile', params: { username: reply.author?.username } }" 
                  class="font-medium hover:text-purple-600 transition-colors"
                >
                  {{ reply.author?.name }}
                </router-link>
                <p class="text-gray-500 text-xs">{{ formatDate(reply.created_at) }}</p>
              </div>
            </div>
            <button 
              v-if="currentUser.id === user.id || currentUser.id === reply.author?.id" 
              @click="deleteReply(reply.id, user.announcements.pinned.id, true)" 
              class="text-red-500 hover:text-red-600 transition-colors"
            >
              <TrashIcon class="w-4 h-4" />
            </button>
          </div>
          <p class="text-gray-700">{{ reply.content }}</p>
          <button 
            @click="showReplyForm(reply.id)" 
            class="text-purple-600 hover:text-purple-700 font-medium transition-colors mt-2"
          >
            Reply
          </button>
          <div v-if="replyingToMessageId === reply.id" class="mt-4">
            <textarea
              v-model="newReply"
              rows="3"
              class="w-full border border-gray-200 rounded-xl p-4 focus:ring-2 focus:ring-purple-500 focus:border-transparent transition-all duration-300"
              placeholder="Write a reply..."
            ></textarea>
            <button
              @click="addReply(reply.id)"
              class="mt-3 px-6 py-2 bg-purple-600 text-white font-medium rounded-xl hover:bg-purple-700 transition-all duration-300"
            >
              Submit Reply
            </button>
          </div>
        </div>
      </div>

      <!-- Reply Form -->
      <div class="mt-6">
        <button 
          @click="showReplyForm(user.announcements.pinned.id)" 
          class="text-purple-600 hover:text-purple-700 font-medium transition-colors"
        >
          Reply
        </button>
        <div v-if="replyingToMessageId === user.announcements.pinned.id" class="mt-4">
          <textarea
            v-model="newReply"
            rows="3"
            class="w-full border border-gray-200 rounded-xl p-4 focus:ring-2 focus:ring-purple-500 focus:border-transparent transition-all duration-300"
            placeholder="Write a reply..."
          ></textarea>
          <button
            @click="addReply(user.announcements.pinned.id, true)"
            class="mt-3 px-6 py-2 bg-purple-600 text-white font-medium rounded-xl hover:bg-purple-700 transition-all duration-300"
          >
            Submit Reply
          </button>
        </div>
      </div>
    </div>
  </div>

  <!-- Regular Announcements -->
  <div v-if="user.announcements.regular.length > 0" class="space-y-4">
    <div 
      v-for="announcement in user.announcements.regular" 
      :key="announcement.id" 
      class="bg-gray-50 p-6 rounded-xl"
    >
      <div class="flex items-center justify-between mb-4">
        <div class="flex items-center">
          <img 
            :src="user.avatar_url || defaultAvatar" 
            :alt="user.name"
            class="w-12 h-12 rounded-full mr-4 object-cover ring-2 ring-purple-100"
          />
          <div>
            <p class="font-bold text-lg">{{ user.name }}</p>
            <p class="text-gray-600 text-sm">{{ formatDate(announcement.created_at) }}</p>
          </div>
        </div>
        <button 
          @click="deleteAnnouncement(announcement.id, false)" 
          class="text-red-500 hover:text-red-600 transition-colors"
        >
          <TrashIcon class="w-5 h-5" />
        </button>
      </div>

      <div v-html="announcement.content" class="text-gray-800 leading-relaxed mb-6"></div>

      <!-- Replies -->
      <div class="space-y-4 ml-12 border-l-2 border-purple-100 pl-6">
        <div 
          v-for="reply in announcement.replies" 
          :key="reply.id" 
          class="bg-white rounded-xl p-4 shadow-sm"
        >
          <div class="flex items-center justify-between mb-3">
            <div class="flex items-center">
              <img 
                :src="reply.author?.avatar_url || defaultAvatar" 
  :alt="reply.author?.name || 'Deleted User'"
                class="w-8 h-8 rounded-full mr-3 object-cover ring-2 ring-purple-100"
              />
              <div>
                <router-link 
                  :to="{ name: 'VisitedUserProfile', params: { username: reply.author?.username } }" 
                  class="font-medium hover:text-purple-600 transition-colors"
                >
                  {{ reply.author?.name }}
                </router-link>
                <p class="text-gray-500 text-xs">{{ formatDate(reply.created_at) }}</p>
              </div>
            </div>
            <button 
              v-if="currentUser.id === user.id || currentUser.id === reply.author?.id" 
              @click="deleteReply(reply.id, announcement.id)" 
              class="text-red-500 hover:text-red-600 transition-colors"
            >
              <TrashIcon class="w-4 h-4" />
            </button>
          </div>
          <p class="text-gray-700">{{ reply.content }}</p>
          <button 
            @click="showReplyForm(reply.id)" 
            class="text-purple-600 hover:text-purple-700 font-medium transition-colors mt-2"
          >
            Reply
          </button>
          <div v-if="replyingToMessageId === reply.id" class="mt-4">
            <textarea
              v-model="newReply"
              rows="3"
              class="w-full border border-gray-200 rounded-xl p-4 focus:ring-2 focus:ring-purple-500 focus:border-transparent transition-all duration-300"
              placeholder="Write a reply..."
            ></textarea>
            <button
              @click="addReply(reply.id)"
              class="mt-3 px-6 py-2 bg-purple-600 text-white font-medium rounded-xl hover:bg-purple-700 transition-all duration-300"
            >
              Submit Reply
            </button>
          </div>
        </div>
      </div>

      <!-- Reply Form -->
      <div class="mt-6">
        <button 
          @click="showReplyForm(announcement.id)" 
          class="text-purple-600 hover:text-purple-700 font-medium transition-colors"
        >
          Reply
        </button>
        <div v-if="replyingToMessageId === announcement.id" class="mt-4">
          <textarea
            v-model="newReply"
            rows="3"
            class="w-full border border-gray-200 rounded-xl p-4 focus:ring-2 focus:ring-purple-500 focus:border-transparent transition-all duration-300"
            placeholder="Write a reply..."
          ></textarea>
          <button
            @click="addReply(announcement.id)"
            class="mt-3 px-6 py-2 bg-purple-600 text-white font-medium rounded-xl hover:bg-purple-700 transition-all duration-300"
          >
            Submit Reply
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

                  <!-- Messages -->
                  <div class="space-y-6">
                    <div 
                      v-for="message in user.messages" 
                      :key="message.id" 
                      class="bg-gray-50 rounded-xl p-6 transition-all duration-300 hover:shadow-lg"
                    >
                      <div class="flex items-center justify-between mb-4">
                        <div class="flex items-center">
                          <img 
                            :src="message.author?.avatar_url || defaultAvatar" 
                            :alt="message.author?.name"
                            class="w-12 h-12 rounded-full mr-4 object-cover ring-2 ring-purple-100"
                          />
                          <div>
                            <router-link 
                              :to="{ name: 'VisitedUserProfile', params: { username: message.author?.username } }" 
                              class="font-bold text-lg hover:text-purple-600 transition-colors"
                            >
                              {{ message.author?.name }}
                            </router-link>
                            <p class="text-gray-600 text-sm">{{ formatDate(message.created_at) }}</p>
                          </div>
                        </div>
                        <button 
                          v-if="currentUser.id === user.id" 
                          @click="deleteMessage(message.id)" 
                          class="text-red-500 hover:text-red-600 transition-colors"
                        >
                          <TrashIcon class="w-5 h-5" />
                        </button>
                      </div>

                      <div v-html="message.content" class="text-gray-800 leading-relaxed mb-6"></div>

                      <!-- Replies -->
                      <div class="space-y-4 ml-12 border-l-2 border-purple-100 pl-6">
                        <div 
                          v-for="reply in message.replies" 
                          :key="reply.id" 
                          class="bg-white rounded-xl p-4 shadow-sm"
                        >
                          <div class="flex items-center mb-3">
                            <img 
                              :src="reply.author?.avatar_url || defaultAvatar" 
  :alt="reply.author?.name || 'Deleted User'"
                              class="w-8 h-8 rounded-full mr-3 object-cover ring-2 ring-purple-100"
                            />
                            <div>
                              <router-link 
                                :to="{ name: 'VisitedUserProfile', params: { username: reply.author?.username } }" 
                                class="font-medium hover:text-purple-600 transition-colors"
                              >
                                {{ reply.author?.name }}
                              </router-link>
                              <p class="text-gray-500 text-xs">{{ formatDate(reply.created_at) }}</p>
                            </div>
                          </div>
                          <p class="text-gray-700">{{ reply.content }}</p>
                        </div>
                      </div>

                      <!-- Reply Form -->
                      <div class="mt-6">
                        <button 
                          @click="showReplyForm(message.id)" 
                          class="text-purple-600 hover:text-purple-700 font-medium transition-colors"
                        >
                          Reply
                        </button>
                        <div v-if="replyingToMessageId === message.id" class="mt-4">
                          <textarea
                            v-model="newReply"
                            rows="3"
                            class="w-full border border-gray-200 rounded-xl p-4 focus:ring-2 focus:ring-purple-500 focus:border-transparent transition-all duration-300"
                            placeholder="Write a reply..."
                          ></textarea>
                          <button
                            @click="addReply(message.id)"
                            class="mt-3 px-6 py-2 bg-purple-600 text-white font-medium rounded-xl hover:bg-purple-700 transition-all duration-300"
                          >
                            Submit Reply
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Bookshelf Tab -->
              <div v-if="currentTab === 'Bookshelf'">
                <h2 class="text-2xl font-bold text-gray-900 mb-6">Currently Reading</h2>
                <div v-if="user.currentlyReading && user.currentlyReading.length > 0" 
                  class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
                  <div 
                    v-for="book in user.currentlyReading" 
                    :key="book.id" 
                    class="group bg-gray-50 rounded-2xl overflow-hidden transform transition-all duration-300 hover:-translate-y-1 hover:shadow-xl"
                  >
                    <div class="relative pt-[150%]">
                      <img 
                        :src="book.cover_image" 
                        :alt="book.title"
                        class="absolute inset-0 w-full h-full object-cover transform transition-transform duration-300 group-hover:scale-105"
                      />
                      <div class="absolute inset-0 bg-gradient-to-t from-black/60 via-black/20 to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-300"></div>
                    </div>
                    <div class="p-6">
                      <h3 class="font-bold text-lg mb-2 line-clamp-2 group-hover:text-purple-600 transition-colors">{{ book.title }}</h3>
                      <p class="text-gray-600 line-clamp-1">by {{ book.author }}</p>
                    </div>
                  </div>
                </div>
                <div v-else class="text-center py-12 bg-gray-50 rounded-2xl">
                  <BookIcon class="w-12 h-12 text-gray-400 mx-auto mb-4" />
                  <p class="text-gray-600 text-lg">You're not currently reading any books.</p>
                  <p class="text-gray-500">Why not start one?</p>
                </div>
              </div>

              <!-- Activity Tab -->
              <div v-if="currentTab === 'Activity'" class="space-y-6">
                <h2 class="text-2xl font-bold text-gray-900 mb-6">Recent Activity</h2>
                <div class="space-y-4">
                  <div 
                    v-for="activity in user.activityFeed" 
                    :key="activity.id" 
                    class="group bg-gray-50 rounded-xl p-4 transition-all duration-300 hover:shadow-lg"
                  >
                    <div class="flex items-center">
                      <div class="p-2 rounded-xl mr-4" 
                        :class="{
                          'bg-red-100': activity.type === 'applause',
                          'bg-blue-100': activity.type === 'comment',
                          'bg-green-100': activity.type === 'follow',
                          'bg-purple-100': activity.type === 'recommendation_invite',
                          'bg-yellow-100': activity.type === 'added_to_list'
                        }"
                      >
                        <HeartIcon v-if="activity.type === 'applause'" class="w-5 h-5 text-red-500" />
                        <MessageSquareIcon v-if="activity.type === 'comment'" class="w-5 h-5 text-blue-500" />
                        <UserPlusIcon v-if="activity.type === 'follow'" class="w-5 h-5 text-green-500" />
                        <UserIcon v-if="activity.type === 'recommendation_invite'" class="w-5 h-5 text-purple-500" />
                        <BookmarkIcon v-if="activity.type === 'added_to_list'" class="w-5 h-5 text-yellow-500" />
                      </div>
                      <div class="flex-1">
                        <p class="text-gray-800">
                          {{ activity.description }}
                          <router-link :to="activity.link" class="font-medium text-purple-600 hover:text-purple-700 transition-colors">
                            {{ activity.linkText }}
                          </router-link>
                        </p>
                        <p class="text-gray-500 text-sm mt-1">{{ activity.time }}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Recommendation Lists Tab -->
              <div v-if="currentTab === 'Recommendation Lists'">
                <RecommendationListManagement 
                  :lists="user.recommendationLists"
                  @deleteList="deleteRecommendationList"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Announcement Modal -->
    <div v-if="showAnnouncementModal" 
      class="fixed inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center z-50"
    >
      <div class="bg-white rounded-2xl p-8 w-11/12 max-w-3xl transform transition-all duration-300 scale-95 hover:scale-100">
        <h2 class="text-2xl font-bold text-gray-900 mb-6">Make Announcement</h2>
        <form @submit.prevent="sendAnnouncement" class="space-y-6">
          <div id="announcement-editor" class="bg-gray-50 rounded-xl overflow-hidden"></div>
          <div class="flex items-center">
            <input 
              type="checkbox" 
              id="pin-announcement" 
              v-model="pinAnnouncement" 
              class="w-4 h-4 text-purple-600 border-gray-300 rounded focus:ring-purple-500"
            >
            <label for="pin-announcement" class="ml-2 text-gray-700">Pin this announcement</label>
          </div>
          <div class="flex justify-end gap-4">
            <button
              type="button"
              @click="closeAnnouncementModal"
              class="px-6 py-3 border border-gray-200 rounded-xl font-medium text-gray-700 hover:bg-gray-50 transition-colors duration-300"
            >
              Cancel
            </button>
            <button
              type="submit"
              class="px-6 py-3 bg-gradient-to-r from-indigo-600 to-purple-600 text-white font-medium rounded-xl hover:from-indigo-700 hover:to-purple-700 transition-all duration-300 shadow-lg hover:shadow-xl"
            >
              Send to Followers
            </button>
          </div>
        </form>
      </div>
    </div>

    <!-- Follow Modals -->
    <FollowModal
      v-if="showFollowersModal"
      :show="showFollowersModal"
      type="followers"
      :userId="user.id"
      title="Followers"
      @close="showFollowersModal = false"
    />

    <FollowModal
      v-if="showFollowingModal"
      :show="showFollowingModal"
      type="following"
      :userId="user.id"
      title="Following"
      @close="showFollowingModal = false"
    />
  </div>
</template>

<script>
import { ref, reactive, computed, onMounted, onUnmounted, watch } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { Book, EyeIcon, HeartIcon, MessageSquareIcon, UserPlusIcon, TrashIcon, UserIcon, BookmarkIcon } from 'lucide-vue-next';
import RecommendationListManagement from './RecommendationListManagement.vue';
import Quill from 'quill';
import 'quill/dist/quill.snow.css';
import { supabase } from '@/supabase';
import defaultAvatar from '@/assets/default-avatar-svg.png';
import FollowModal from './FollowModal.vue';

export default {
  name: 'OwnedUserProfile',
  components: {
    EyeIcon,
    HeartIcon,
    MessageSquareIcon,
    UserPlusIcon,
    TrashIcon,
    UserIcon,
    BookmarkIcon,
    BookIcon: Book,
    RecommendationListManagement,
    FollowModal
  },
  setup() {
    const router = useRouter();
    const store = useStore();
    const showFollowersModal = ref(false);
    const showFollowingModal = ref(false);

    const currentUser = computed(() => store.getters['auth/currentUser']);

    const viewFollowers = () => {
  showFollowersModal.value = true;
};

const viewFollowing = () => {
  showFollowingModal.value = true;
};
    
    const user = reactive({
      id: '',
      username: '',
      name: '',
      tagline: '',
      avatar_url: '',
      bio: '',
      followers: 0,
      following: 0,
      stories: 0,
      stats: {
        totalWords: 0,
        storiesPublished: 0,
      },
      achievements: [],
      publishedStories: [],
      bookshelves: [],
      activityFeed: [],
      messages: [],
      recommendationLists: [],
      announcements: {
    pinned: null,
    regular: []
  }
    });

    const visibleTabs = ref(['About', 'Bookshelf', 'Activity', 'Recommendation Lists']);
    const currentTab = ref('About');
    const showAboutMe = ref(true);
    const showAnnouncementModal = ref(false);
    const pinAnnouncement = ref(false);
    const replyingToMessageId = ref(null);
    const newReply = ref('');
    const newMessage = ref('');
    const newListTitle = ref('');
    const newListDescription = ref('');
    let quill = null;

    const fetchUserProfile = async () => {
      try {
        const { data, error } = await supabase
          .from('users')
          .select('*')
          .eq('id', currentUser.value.id)
          .single();

        if (error) throw error;

        if (data) {
          Object.assign(user, data);
          await Promise.all([
            fetchFollowCounts(),
            fetchPublishedStories(),
            fetchBookshelves(),
            fetchActivityFeed(),
            fetchMessages(),
            fetchAnnouncements(),
            fetchRecommendationLists(),
          ]);
        } else {
          throw new Error('User not found');
        }
      } catch (error) {
        console.error('Error fetching user profile:', error);
      }
    };

    const fetchPublishedStories = async () => {
  try {
    const { data, error } = await supabase
      .from('stories')
      .select(`
        id,
        title,
        blurb,
        genre,
        cover_image,
        reads,
        word_count,
        published_at,
        published,
        status
      `)
      .eq('author_id', user.id)
      .or('published.eq.true,status.eq.published')
      .order('published_at', { ascending: false });

    if (error) throw error;
    
    console.log('Fetched stories:', data);
    
    user.publishedStories = data.map(story => ({
      ...story,
      reads: story.reads || 0,
      description: story.blurb
    }));
    
    // Update story-related stats
    user.stats.storiesPublished = data.length;
    user.stats.totalWords = data.reduce((sum, story) => sum + (story.word_count || 0), 0);
    
  } catch (error) {
    console.error('Error fetching published stories:', error);
  }
};

    const fetchBookshelves = async () => {
  try {
    const { data, error } = await supabase
      .from('bookshelf')
      .select(`
        id,
        status,
        stories (
          id,
          title,
          cover_image,
          users!author_id (username)
        )
      `)
      .eq('user_id', user.id)
      .eq('status', 'Currently Reading');

    if (error) throw error;
    
    user.currentlyReading = data.map(item => ({
      id: item.stories.id,
      title: item.stories.title,
      author: item.stories.users.username,
      cover_image: item.stories.cover_image
    }));

    console.log('Currently reading books:', user.currentlyReading);
  } catch (error) {
    console.error('Error fetching currently reading books:', error);
  }
};

    async function fetchActivityFeed() {
  try {
    const activities = [];

    // Fetch follows
    const { data: follows, error: followsError } = await supabase
      .from('followers')
      .select(`
        id,
        followed:followed_id(
          id,
          username,
          name
        ),
        created_at
      `)
      .eq('follower_id', user.id)
      .order('created_at', { ascending: false });

    if (followsError) throw followsError;

    if (follows) {
      follows.forEach(follow => {
        activities.push({
          id: `follow_${follow.id}`,
          type: 'follow',
          description: 'Started following',
          linkText: follow.followed.username,
          link: `/profile/${follow.followed.username}`,
          time: formatRelativeTime(follow.created_at)
        });
      });
    }

    // Fetch recommendation list invites
    const { data: invites, error: invitesError } = await supabase
      .from('recommendation_list_invites')
      .select(`
        id,
        list_id,
        created_at,
        list:recommendation_lists!list_id(
          title
        ),
        inviter:inviter_id(
          username,
          name
        )
      `)
      .eq('user_id', user.id)
      .order('created_at', { ascending: false });

    if (invitesError) throw invitesError;

    if (invites) {
      invites.forEach(invite => {
        activities.push({
          id: `invite_${invite.id}`,
          type: 'recommendation_invite',
          description: `${invite.inviter.username} invited you to join their Recommendation List`,
          linkText: invite.list.title,
          link: `/recommendation-list/${invite.list_id}`,
          time: formatRelativeTime(invite.created_at)
        });
      });
    }

    // Optional: Fetch chapter votes if they exist
    const { data: votes, error: votesError } = await supabase
  .from('chapter_votes')
  .select(`
    id,
    voted_at,
    chapters!inner (
      id,
      title,
      story_id,
      stories!inner (
        id,
        title
      )
    )
  `)
  .eq('user_id', user.id)
  .order('voted_at', { ascending: false });

    // Only add votes if we have data and no error
    if (!votesError && votes && votes.length > 0) {
      votes.forEach(vote => {
        if (vote.chapter?.story) {  // Only add if we have valid story data
          activities.push({
            id: `vote_${vote.id}`,
            type: 'applause',
            description: 'Liked the story',
            linkText: vote.chapter.story.title,
            link: `/story/${vote.chapter.story.id}`,
            time: formatRelativeTime(vote.created_at)
          });
        }
      });
    }

    // Optional: Fetch chapter comments if they exist
    const { data: comments, error: commentsError } = await supabase
      .from('chapter_comments')
      .select(`
        id,
        chapter:chapters!chapter_id (
          story:stories!story_id (
            id,
            title
          )
        ),
        created_at
      `)
      .eq('user_id', user.id)
      .order('created_at', { ascending: false });

    // Only add comments if we have data and no error
    if (!commentsError && comments && comments.length > 0) {
      comments.forEach(comment => {
        if (comment.chapter?.story) {  // Only add if we have valid story data
          activities.push({
            id: `comment_${comment.id}`,
            type: 'comment',
            description: 'Commented on',
            linkText: comment.chapter.story.title,
            link: `/story/${comment.chapter.story.id}`,
            time: formatRelativeTime(comment.created_at)
          });
        }
      });
    }

    // Sort all activities by time (most recent first) and limit to 10
    user.activityFeed = activities
      .sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
      .slice(0, 10);

  } catch (error) {
    console.error('Error fetching activity feed:', error);
    user.activityFeed = []; // Ensure we always have an array
  }
}

const formatRelativeTime = (dateString) => {
  const date = new Date(dateString);
  const now = new Date();
  const diffInSeconds = Math.floor((now - date) / 1000);

  if (diffInSeconds < 60) return 'just now';
  if (diffInSeconds < 3600) return `${Math.floor(diffInSeconds / 60)} minutes ago`;
  if (diffInSeconds < 86400) return `${Math.floor(diffInSeconds / 3600)} hours ago`;
  if (diffInSeconds < 604800) return `${Math.floor(diffInSeconds / 86400)} days ago`;
  return `${Math.floor(diffInSeconds / 604800)} weeks ago`;
};

    const fetchMessages = async () => {
  try {
    const { data, error } = await supabase
      .from('profile_messages')
      .select(`
        id,
        content,
        author_id,
        profile_id,
        created_at,
        author:users!author_id (
          id,
          name,
          username,
          avatar_url
        ),
        replies:profile_message_replies (
          id,
          content,
          created_at,
          author:users!author_id (
            id,
            name,
            username,
            avatar_url
          )
        )
      `)
      .eq('profile_id', user.id)
      .order('created_at', { ascending: false });

    if (error) {
      console.error('Fetch error details:', error);
      throw error;
    }

    console.log('Fetched messages:', data);
    user.messages = data || [];
  } catch (error) {
    console.error('Error fetching messages:', error);
    user.messages = [];
  }
};

    const fetchAnnouncements = async () => {
  try {
    const { data, error } = await supabase
      .from('announcements')
      .select('*')
      .eq('user_id', user.id)
      .order('created_at', { ascending: false });

    if (error) throw error;

    if (data) {
      user.announcements.pinned = data.find(a => a.is_pinned) || null;
      user.announcements.regular = data.filter(a => !a.is_pinned);
    }
  } catch (error) {
    console.error('Error fetching announcements:', error);
  }
};

    const fetchRecommendationLists = async () => {
  try {
    // Get owned lists
    const { data: ownedLists, error: ownedError } = await supabase
      .from('recommendation_lists')
      .select(`
        id,
        title,
        user_id,
        description,
        created_at
      `)
      .eq('user_id', user.id);

    if (ownedError) throw ownedError;

    // Get lists where user is a member through recommendation_list_items
    const { data: memberItems, error: memberError } = await supabase
      .from('recommendation_list_items')
      .select(`
        list_id,
        recommendation_lists!inner (
          id,
          title,
          description,
          created_at,
          user_id,
          users!inner (
            id,
            username,
            name,
            avatar_url
          )
        )
      `)
      .eq('user_id', user.id);

    if (memberError) throw memberError;

    // Process member lists to correct structure
    const memberLists = memberItems?.map(item => ({
      id: item.recommendation_lists.id,
      title: item.recommendation_lists.title,
      description: item.recommendation_lists.description,
      created_at: item.recommendation_lists.created_at,
      creator: {
        id: item.recommendation_lists.users.id,
        username: item.recommendation_lists.users.username,
        name: item.recommendation_lists.users.name,
        avatar_url: item.recommendation_lists.users.avatar_url
      }
    })) || [];

    // Update the reactive refs
    user.recommendationLists = [
      ...(ownedLists || []).map(list => ({
        ...list,
        isOwned: true
      })),
      ...memberLists.map(list => ({
        ...list,
        isOwned: false
      }))
    ];

    console.log('Updated recommendation lists:', user.recommendationLists);

  } catch (error) {
    console.error('Error fetching recommendation lists:', error);
  }
};

    const initQuill = () => {
  if (!quill) {
    quill = new Quill('#announcement-editor', {
      theme: 'snow',
      modules: {
        toolbar: [
          ['bold', 'italic', 'underline', 'strike'],
          ['blockquote'],
          [{ 'list': 'ordered'}, { 'list': 'bullet' }],
          [{ 'header': [1, 2, 3, false] }],
        ],
        clipboard: {
          matchVisual: false
        }
      },
      placeholder: 'Write your announcement here...',
    });
  }
};

    const destroyQuill = () => {
      if (quill) {
        quill = null;
      }
    };

    const toggleAboutMe = () => {
      showAboutMe.value = !showAboutMe.value;
    };

    const showReplyForm = (messageId) => {
      replyingToMessageId.value = messageId;
    };

    // Update addReply to handle announcement replies
const addReply = async (parentId, isAnnouncement = false) => {
  if (newReply.value.trim()) {
    try {
      const { data, error } = await supabase
        .from(isAnnouncement ? 'announcement_replies' : 'profile_message_replies')
        .insert({
          [isAnnouncement ? 'announcement_id' : 'message_id']: parentId,
          author_id: currentUser.value.id,
          content: newReply.value,
          parent_reply_id: replyingToMessageId.value !== parentId ? replyingToMessageId.value : null
        })
        .select(`
          id,
          content,
          created_at,
          parent_reply_id,
          author:users!author_id (
            id,
            name,
            username,
            avatar_url
          )
        `)
        .single();

      if (error) throw error;

      // Add reply to the correct announcement or message
      if (isAnnouncement) {
        const announcement = user.announcements.pinned?.id === parentId 
          ? user.announcements.pinned 
          : user.announcements.regular.find(a => a.id === parentId);
        
        if (announcement) {
          if (!announcement.replies) announcement.replies = [];
          announcement.replies.push(data);
        }
      } else {
        const message = user.messages.find(m => m.id === parentId);
        if (message) {
          message.replies.push(data);
        }
      }

      newReply.value = '';
      replyingToMessageId.value = null;
    } catch (error) {
      console.error('Error adding reply:', error);
    }
  }
};

// Add delete reply function
const deleteReply = async (replyId, parentId, isAnnouncement = false) => {
  try {
    const { error } = await supabase
      .from(isAnnouncement ? 'announcement_replies' : 'profile_message_replies')
      .delete()
      .eq('id', replyId);

    if (error) throw error;

    // Remove reply from local state
    if (isAnnouncement) {
      const announcement = user.announcements.pinned?.id === parentId 
        ? user.announcements.pinned 
        : user.announcements.regular.find(a => a.id === parentId);
      
      if (announcement) {
        announcement.replies = announcement.replies.filter(r => r.id !== replyId);
      }
    } else {
      const message = user.messages.find(m => m.id === parentId);
      if (message) {
        message.replies = message.replies.filter(r => r.id !== replyId);
      }
    }
  } catch (error) {
    console.error('Error deleting reply:', error);
  }
};

    const fetchFollowCounts = async () => {
  try {
    if (user && user.id) {
      const { count: followersCount, error: followersError } = await supabase
        .from('followers')
        .select('*', { count: 'exact' })
        .eq('followed_id', user.id);
      const { count: followingCount, error: followingError } = await supabase
        .from('followers')
        .select('*', { count: 'exact' })
        .eq('follower_id', user.id);
      if (followersError) throw followersError;
      if (followingError) throw followingError;
      user.followers = followersCount;
      user.following = followingCount;
    }
  } catch (error) {
    console.error('Error fetching follow counts:', error);
  }
};

    const deleteMessage = async (messageId) => {
      try {
        const { error } = await supabase
          .from('profile_messages')
          .delete()
          .eq('id', messageId);

        if (error) throw error;

        user.messages = user.messages.filter(m => m.id !== messageId);
      } catch (error) {
        console.error('Error deleting message:', error);
      }
    };

    const closeAnnouncementModal = () => {
      showAnnouncementModal.value = false;
    };

    const sendAnnouncement = async () => {
  if (quill) {
    const content = quill.root.innerHTML;
    if (content.trim()) {
      try {
        if (pinAnnouncement.value) {
          // First, unpin any existing pinned announcement
          if (user.announcements.pinned) {
            const { error: unpinError } = await supabase
              .from('announcements')
              .update({ is_pinned: false })
              .eq('id', user.announcements.pinned.id);

            if (unpinError) throw unpinError;
            
            // Move previously pinned announcement to regular
            user.announcements.regular.unshift({
              ...user.announcements.pinned,
              is_pinned: false
            });
            user.announcements.pinned = null;
          }
        }

        // Create the new announcement
        const { data, error } = await supabase
          .from('announcements')
          .insert({
            user_id: user.id,
            content: content,
            is_pinned: pinAnnouncement.value,
            created_at: new Date().toISOString()
          })
          .select()
          .single();

        if (error) throw error;

        if (pinAnnouncement.value) {
          user.announcements.pinned = data;
        } else {
          user.announcements.regular.unshift(data);
        }

        quill.setText('');
        pinAnnouncement.value = false;
        closeAnnouncementModal();
      } catch (error) {
        console.error('Error sending announcement:', error);
      }
    }
  }
};

    const deleteAnnouncement = async (id, isPinned) => {
  try {
    const { error } = await supabase
      .from('announcements')
      .delete()
      .eq('id', id);

    if (error) throw error;

    if (isPinned) {
      user.announcements.pinned = null;
    } else {
      user.announcements.regular = user.announcements.regular.filter(a => a.id !== id);
    }
  } catch (error) {
    console.error('Error deleting announcement:', error);
  }
};

    const formatDate = (dateString) => {
  if (!dateString) return '';
  
  try {
    const date = new Date(dateString);
    if (isNaN(date.getTime())) return 'Invalid date';
    
    return date.toLocaleDateString('en-US', { 
      year: 'numeric', 
      month: 'long', 
      day: 'numeric' 
    });
  } catch (err) {
    console.error('Date formatting error:', err);
    return '';
  }
}

    const unpinAnnouncement = async (id) => {
      try {
        const { error } = await supabase
          .from('announcements')
          .update({ is_pinned: false })
          .eq('id', id);

        if (error) throw error;

        user.pinnedAnnouncements = user.pinnedAnnouncements.filter(a => a.id !== id);
      } catch (error) {
        console.error('Error unpinning announcement:', error);
      }
    };

    const addMessage = async () => {
  if (newMessage.value.trim()) {
    try {
      // Create message object first
      const messageData = {
        profile_id: user.id,
        author_id: currentUser.value.id,
        content: newMessage.value,
        created_at: new Date().toISOString()
      };

      console.log('Sending message data:', messageData);

      const { data, error } = await supabase
        .from('profile_messages')
        .insert(messageData)
        .select(`
          id,
          content,
          created_at,
          author_id,
          profile_id,
          author:users!author_id (
            id,
            name,
            username,
            avatar_url
          )
        `)
        .single();

      if (error) {
        console.error('Insert error details:', error);
        throw error;
      }

      console.log('Received message data:', data);

      user.messages.unshift({
        ...data,
        replies: []
      });
      
      newMessage.value = '';
    } catch (error) {
      console.error('Error adding message:', error);
    }
  }
};

    const viewBook = (bookId) => {
      router.push({ name: 'StoryPage', params: { id: bookId } });
    };

    const createRecommendationList = async (listData) => {
      try {
        const { data, error } = await supabase
          .from('recommendation_lists')
          .insert({ ...listData, user_id: user.id })
          .select()
          .single();

        if (error) throw error;
        user.recommendationLists.push(data);
        newListTitle.value = '';
        newListDescription.value = '';
      } catch (error) {
        console.error('Error creating recommendation list:', error);
      }
    };

    const deleteRecommendationList = async (listId) => {
      if (confirm('Are you sure you want to delete this recommendation list?')) {
        try {
          const { error: itemsError } = await supabase
            .from('recommendation_list_items')
            .delete()
            .eq('list_id', listId);

          if (itemsError) throw itemsError;

          const { error: listError } = await supabase
            .from('recommendation_lists')
            .delete()
            .eq('id', listId);

          if (listError) throw listError;

          user.recommendationLists = user.recommendationLists.filter(list => list.id !== listId);

          console.log(`Recommendation list ${listId} deleted successfully`);
        } catch (error) {
          console.error(`Error deleting recommendation list ${listId}:`, error);
        }
      }
    };

    watch(showAnnouncementModal, (newValue) => {
      if (newValue) {
        setTimeout(() => {
          initQuill();
        }, 0);
      } else {
        destroyQuill();
      }
    });

    onMounted(async () => {
      await store.dispatch('auth/checkAuth');
      await fetchUserProfile();
      await fetchActivityFeed();
    });

    onUnmounted(() => {
      destroyQuill();
    });

    return {
      user,
      defaultAvatar,
      visibleTabs,
      currentTab,
      currentUser,
      showAboutMe,
      showAnnouncementModal,
      pinAnnouncement,
      replyingToMessageId,
      newReply,
      newMessage,
      newListTitle,
      newListDescription,
      toggleAboutMe,
      showReplyForm,
      addReply,
      deleteReply,
      deleteMessage,
      closeAnnouncementModal,
      sendAnnouncement,
      unpinAnnouncement,
      deleteAnnouncement,
      addMessage,
      viewBook,
      viewFollowers,
      viewFollowing,
      createRecommendationList,
      deleteRecommendationList,
      formatDate,
      showFollowersModal,
      showFollowingModal
    };
  }
};
</script>

<style scoped>
::v-deep .ql-editor {
  min-height: 120px;
  max-height: 240px;
  overflow-y: auto;
  background-color: white;
  border-radius: 0.75rem;
}

::v-deep .ql-toolbar {
  border-top-left-radius: 0.75rem;
  border-top-right-radius: 0.75rem;
  border-color: #e5e7eb;
  background-color: #f9fafb;
}

::v-deep .ql-container {
  border-bottom-left-radius: 0.75rem;
  border-bottom-right-radius: 0.75rem;
  border-color: #e5e7eb;
}

::v-deep .ql-toolbar button:hover {
  color: #7c3aed;
}

::v-deep .ql-toolbar button.ql-active {
  color: #7c3aed;
}
</style>