<template>
  <div class="create-story bg-gray-100 min-h-screen p-6">
    <div class="max-w-4xl mx-auto bg-white rounded-lg shadow-md p-8">
      <h1 class="text-3xl font-bold mb-8 text-center text-indigo-700">Create New Story</h1>
      <form @submit.prevent="createStory" class="space-y-6">
        <div class="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div>
            <label for="title" class="block text-sm font-medium text-gray-700 mb-1">Title</label>
            <input 
              type="text" 
              id="title" 
              v-model="story.title" 
              required 
              class="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
              :class="{ 'border-red-500': v$.title.$error }"
            >
            <p v-if="v$.title.$error" class="mt-1 text-sm text-red-600">{{ v$.title.$errors[0].$message }}</p>
          </div>



          <div>
            <label for="genre" class="block text-sm font-medium text-gray-700 mb-1">Genre</label>
            <select 
              id="genre" 
              v-model="story.genre" 
              required 
              class="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
              :class="{ 'border-red-500': v$.genre.$error }"
            >
              <option value="">Select a genre</option>
              <option v-for="genre in genres" :key="genre" :value="genre">{{ genre }}</option>
            </select>
            <p v-if="v$.genre.$error" class="mt-1 text-sm text-red-600">{{ v$.genre.$errors[0].$message }}</p>
          </div>

          <div>
            <label for="targetAudience" class="block text-sm font-medium text-gray-700 mb-1">Target Audience</label>
            <select 
              id="targetAudience" 
              v-model="story.targetAudience" 
              required 
              class="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
              :class="{ 'border-red-500': v$.targetAudience.$error }"
            >
              <option value="">Select target audience</option>
              <option value="Children">Children</option>
              <option value="Young Adult">Young Adult</option>
              <option value="Adult">Adult</option>
              <option value="All Ages">All Ages</option>
            </select>
            <p v-if="v$.targetAudience.$error" class="mt-1 text-sm text-red-600">{{ v$.targetAudience.$errors[0].$message }}</p>
          </div>

          <div>
            <label for="copyright" class="block text-sm font-medium text-gray-700 mb-1">Copyright</label>
            <select 
              id="copyright" 
              v-model="story.copyright" 
              class="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
              :class="{ 'border-red-500': v$.copyright.$error }"
            >
              <option value="">Select copyright status</option>
              <option value="All Rights Reserved">All Rights Reserved</option>
              <option value="Creative Commons">Creative Commons</option>
              <option value="Public Domain">Public Domain</option>
            </select>
            <p v-if="v$.copyright.$error" class="mt-1 text-sm text-red-600">{{ v$.copyright.$errors[0].$message }}</p>
          </div>
        </div>

        <div>
          <label for="tags" class="block text-sm font-medium text-gray-700 mb-1">Tags (comma-separated)</label>
          <input 
            type="text" 
            id="tags" 
            v-model="story.tags" 
            class="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
            :class="{ 'border-red-500': v$.tags.$error }"
          >
          <p v-if="v$.tags.$error" class="mt-1 text-sm text-red-600">{{ v$.tags.$errors[0].$message }}</p>
        </div>

        <div>
          <label for="blurb" class="block text-sm font-medium text-gray-700 mb-1">Blurb</label>
          <textarea 
            id="blurb" 
            v-model="story.blurb" 
            rows="3" 
            class="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
            :class="{ 'border-red-500': v$.blurb.$error }"
          ></textarea>
          <p v-if="v$.blurb.$error" class="mt-1 text-sm text-red-600">{{ v$.blurb.$errors[0].$message }}</p>
        </div>

        <div class="flex items-center space-x-4">
          <div class="flex-1">
            <label for="cover" class="block text-sm font-medium text-gray-700 mb-1">Cover Image</label>
            <input 
              type="file" 
              id="cover" 
              @change="handleCoverUpload" 
              accept="image/*" 
              class="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
            >
          </div>
          <div v-if="coverPreview" class="flex-shrink-0">
            <img :src="coverPreview" alt="Cover preview" class="w-32 h-48 object-cover rounded-md">
          </div>
        </div>

        <div>
    <label class="flex items-center">
      <input type="checkbox" v-model="story.isAdult" class="rounded border-gray-300 text-indigo-600 shadow-sm focus:border-indigo-300 focus:ring focus:ring-offset-0 focus:ring-indigo-200 focus:ring-opacity-50">
      <span class="ml-2 text-sm text-gray-600">18+ Content</span>
    </label>
  </div>

  <div>
    <label class="flex items-center">
      <input type="checkbox" v-model="story.isChallengeEntry" class="rounded border-gray-300 text-indigo-600 shadow-sm focus:border-indigo-300 focus:ring focus:ring-offset-0 focus:ring-indigo-200 focus:ring-opacity-50">
      <span class="ml-2 text-sm text-gray-600">Submit as Challenge Entry</span>
    </label>
  </div>

        <div class="flex justify-end">
          <button 
            type="submit" 
            class="px-6 py-3 bg-indigo-600 text-white rounded-md hover:bg-indigo-700 focus:outline-none focus:ring-
2 focus:ring-offset-2 focus:ring-indigo-500 transition duration-200"
            :disabled="isSubmitting"
          >
            {{ isSubmitting ? 'Creating...' : 'Create Story' }}
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { ref, reactive, computed } from 'vue'
import { useRouter } from 'vue-router'
import { useVuelidate } from '@vuelidate/core'
import { useToast } from "vue-toastification";
import { required, minLength, maxLength } from '@vuelidate/validators'
import { supabase } from '@/supabase'

export default {
  name: 'CreateStory',
  setup() {
    const router = useRouter()
    const toast = useToast()
    const story = reactive({
      title: '',
      cover: null,
      blurb: '',
      genre: '',
      tags: '',
      targetAudience: '',
      copyright: '',
      isAdult: false,
      isChallengeEntry: false,
    })

    const genres = [
      'Fantasy', 'Science Fiction', 'Romance', 'Mystery', 'Thriller',
      'Urban', 'Poetry', 'Horror', 'Historical Fiction', 'Non-fiction'
    ]

    const rules = computed(() => ({
      title: { required, minLength: minLength(3), maxLength: maxLength(100) },
      blurb: { required, minLength: minLength(100), maxLength: maxLength(1000) },
      genre: { required },
      tags: { required },
      targetAudience: { required },
      copyright: { required }
    }))

    const v$ = useVuelidate(rules, story)

    const coverPreview = ref(null)
    const isSubmitting = ref(false)

    const handleCoverUpload = (event) => {
      const file = event.target.files[0]
      story.cover = file
      if (file) {
        const reader = new FileReader()
        reader.onload = e => coverPreview.value = e.target.result
        reader.readAsDataURL(file)
      }
    }

    const createStory = async () => {
  const result = await v$.value.$validate()
  if (!result) {
    toast.error("Please correct the form errors before submitting.")
    return
  }
  isSubmitting.value = true
  try {
    const { data: { user } } = await supabase.auth.getUser()
    if (!user) {
      throw new Error('User not authenticated')
    }

    // Upload cover image
    let coverUrl = null
    if (story.cover) {
      try {
        const file = story.cover
        const fileExt = file.name.split('.').pop()
        const fileName = `${Math.random().toString(36).substring(2)}.${fileExt}`
        const filePath = `${user.id}/${fileName}`
        const { error: uploadError } = await supabase.storage
          .from('story-covers')
          .upload(filePath, file)
        if (uploadError) throw uploadError
        const { data: publicUrlData } = supabase.storage
          .from('story-covers')
          .getPublicUrl(filePath)
        coverUrl = publicUrlData.publicUrl
      } catch (uploadError) {
        console.error('Error uploading cover:', uploadError)
        toast.error('Failed to upload cover image. Please try again.')
        isSubmitting.value = false
        return
      }
    }

    // Fetch the current challenge
    const { data: currentChallenge, error: challengeError } = await supabase
      .from('challenges')
      .select('id')
      .order('created_at', { ascending: false })
      .limit(1)
      .single()

    if (challengeError) {
      console.error('Error fetching current challenge:', challengeError)
      throw new Error('Failed to fetch current challenge')
    }

    // Create story object
    const storyData = {
      title: story.title,
      blurb: story.blurb,
      genre: story.genre,
      tags: story.tags.split(',').map(tag => tag.trim()),
      target_audience: story.targetAudience,
      copyright: story.copyright,
      is_adult: story.isAdult,
      cover_image: coverUrl,
      author_id: user.id,
      status: 'draft',
      word_count: 0
    }

    let newStory
    if (story.isChallengeEntry) {
      // Insert into challenge_entries table
      const { data: challengeEntry, error: entryError } = await supabase
        .from('challenge_entries')
        .insert({
          challenge_id: currentChallenge.id,
          user_id: user.id,
          submitted_at: new Date().toISOString(),
          title: storyData.title,
          blurb: storyData.blurb,
          cover_image: storyData.cover_image,
          // Add any other relevant fields from storyData
        })
        .select()
        .single()

      if (entryError) throw entryError

      newStory = challengeEntry
      toast.success("Challenge entry submitted successfully!")
    } else {
      // If not a challenge entry, insert into stories table
      const { data: insertedStory, error: storyError } = await supabase
        .from('stories')
        .insert(storyData)
        .select()
        .single()

      if (storyError) throw storyError
      newStory = insertedStory
      toast.success("Story created successfully!")
    }

    // Redirect to appropriate page
    if (story.isChallengeEntry) {
      router.push(`/challenge/${currentChallenge.id}/entry/${newStory.id}`)
    } else {
      router.push(`/story/${newStory.id}/details`)
    }
  } catch (error) {
    console.error('Failed to create story:', error)
    toast.error(`Failed to create story: ${error.message}`)
  } finally {
    isSubmitting.value = false
  }
}

    return {
      story,
      genres,
      coverPreview,
      isSubmitting,
      v$,
      handleCoverUpload,
      createStory
    }
  }
}
</script>